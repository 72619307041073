//
// Apparence des boutons
//

#base_button(@color: @color_grisfonce, @active_text: @color_blanc) {
	#no-select;
	#base_input();
	#font-secondary();
	font-size: @size_button_conventionnel;
	background-color: @color;
	color: @active_text;
	border-width: 0;
	border-radius: @size_button_radius;
	border-color: @color;

	&:not(:disabled) {
		cursor: pointer;
	}

	padding: @size_button_padding_v @size_button_padding_h;
	// Léger ajustement (centrage vertical bizarre sur la police)
	padding-bottom: @size_button_padding_v - 1/14rem;

	transition: box-shadow 0.2s ease-in;
	box-shadow: 0 5px @size_focus_glow rgba(0,0,0,0);

	&:disabled {
		opacity: @disabled_opacity;
		transition: opacity @disabled_transition;
	}

	&:not(:disabled) {
		&:active,
		&:focus,
		&:hover,
		&.active {
			text-decoration: none;
			color: @active_text;
			background-color: darken(@color, 10%);
			border-color: darken(@color, 10%);
		}

		&:active {
			background-color: darken(@color, 15%);
			border-color: darken(@color, 15%);
		}
	}

	&.impact {
		font-size: @size_button_impact;
	}

	// On "triche" et on utilise la bordure comme un padding
	// supplémentaire pour ajuster le bouton aux dimensions verticales
	// d'un input, pour quand un bouton est placé à côté d'un input.
	div.input + & {
		border-width: @size_button_border;
		&.button-submit {
			font-size: @size_button_impact;
		}
	}
}

#button() {
	&:extend(a.button all);
}

// Oui, button.button est redondant, mais permet de plus facilement
// échapper aux règles spécifiques si nécessaire. ☹
a.button, button.button {
	#base_button();

	&.primary, &.button-submit {
		#base_button(@color_highlight);
	}

	&.button-go {
		padding-left: @size_button_padding_v * 2;
		padding-right: @size_button_padding_v * 2;
	}

	// Quand les éléments sont directements descendants du même parent,
	// on leur donne un peu d'espace pour respirer.
	&:not(:last-child) {
		margin-right: @gutter;
	}
}

// Utilisé par exemple pour les × permettant la fermeture.
button.button-unstyled {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
	padding: 0;
	border: 0;
	background: transparent;
	color: inherit;
}

#debuttonize() {
	&, &:hover, &:focus, &:active, &.active {
		color: inherit;
		background-color: transparent;
		background-position: center center;
		background-size: contain;
		box-shadow: 0 0 0 0/14rem transparent;
	}

	&:focus, &:active, &.active {
		box-shadow: 0 0 0 1/14rem @color_qidigo_bleu;
	}
}
