.form {
	.input, .input-select {
		margin-top: @gutter;
		margin-bottom: @gutter;
	}
}

.form--compact {
	@_internal: @gutter / 2;

	.form--row {
		margin: auto;
		margin-left: -@_internal * 2;
		margin-right: -@_internal * 2;
		padding: 0 @_internal;

		&>* {
			margin: @_internal;
		}

		&>* {
			width: 100%;
			#screen-sm-min({
				width: auto;
			});
		}

		&>.button {
			width: 100%;
			#screen-xs-max({
				margin-left: 0;
				margin-right: 0;
			});
			#screen-sm-min({
				width: auto;
			});
		}
	}
}

.form-helpers {
	.stuck-under {
		margin-top: -0.7rem;
	}
}

.form-buttons-bar,
.form--buttons {
	#screen-xs-min({
		& > .form-buttons-bar__before {
			float: left;
			text-align: left;
		}
	});
	.loading {
		vertical-align: middle;
		margin-right: @gutter;
	}
	text-align: right;
	& > * {
		text-align: center;
	}

	#screen-xxs-max({
		margin-bottom: @gutter;
		.loading {
			width: 100%;
			display: block;
		}
		text-align: right;
		.form-buttons-bar__before > *.button,
		& > *.button {
			display: block;
			width: 100%;
			&,
			&:not(:last-child) {
				margin-left: 0;
				margin-right: 0;
				margin-top: @gutter/2;
			}
		}
	});

	#screen-xs-min({
		.button {
			&,
			&:not(:last-child) {
				margin: 0;
			}
			// Les boutons sont alignés à droite.
			// Si on leur donne chacun la moitié de la distance entre les
			// boutons, si on a besoin d'une 2e ligne ça devient derp.
			&:not(:first-child) {
				margin-left: @gutter;
			}
		}
	});
}

.error-message {
	#box.error();
}
