// 
// Mixin pour éviter de copier toutes les règles partout.
//
#card() {
	&:extend(#_card all);
}

//
// Les règles. À extender comme plus haut.
//
#_card {
	position: relative;
	margin: @gutter 0;
	padding: @gutter;
	list-style-type: none;
	border: @size_input_border solid @color_container_border;
	border-radius: @size_input_radius;

	// Icônes des cartes
	nav {
		position: absolute;
		top:0;
		right:0;
	}
	nav>ul>li {
		list-style-type: none;
		display: inline;
		margin: 0;
	}
	nav .button {
		@_size: @size_button_height - @size_button_padding_h/2;
		overflow: hidden;
		text-indent: -999em;
		width:  @_size;
		height: @_size;
		border: 0;
		background-position: center center;

		#action-buttons();
	}
}
