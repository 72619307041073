@activity-text-large: 1.5rem;
@activity-text-medium: 1.25rem;
@activity-text-small: 1rem;
@activity-line-height-x-large: 1.875rem;
@activity-line-height-large: 1.5625rem;
@activity-line-height-medium: 1.25rem;
@activity-line-height-small: 1.125rem;
@activity-checkout-box-background: rgb(184 199 206);
@activity_color_container_border: @color_gris2;
@activity-xs: 375px;
@activity-sm: 650px;
@activity-md: 993px;
@activity-lg: 1024px;
@activity-xlg: 1200px;
@activity-primary-color: #27708E;
@activity-secondary-color: #FFF;
@activity-third-color: #626262;
@activity-fourth-color: #757575;
@activity-fifth-color: #194453;
@activity-sixth-color: #7a9dbd;
@activity-seventh-color: #9D4949FF;



.activity-child-page > header,
.page-activity > header {
  @_aspect-ratio: 300/1195*100%;
  position: relative;
  overflow: hidden;
  background: @color_blanc;
  border-radius: 0 0 @size_general_radius @size_general_radius;
  border: 1/14rem solid @color_gris1;
  border-top: 0;
  margin-bottom: @gutter;

  h1 {
    margin: 0;
    padding-top: @gutter/2;
  }

  .activity--cheap-preload {
    height: 0;
    position: absolute;
    visibility: hidden;

    // Images faussement cachées, permettant un preload.
    img {
      #no-select();
      position: absolute;
      width: 1px;
      height: 1px;
      visibility: hidden;
      opacity: 0.001;
    }
  }

  .activity-header--links {
    #header-links()
  }

  .activity-header--link-share {
    a::before {
      #__icon-self("share", "", @color_blanc);
    }
  }

  .activity-header--description {
    position: relative;
    padding: @gutter;
    padding-top: 0;

    .is-raw-text {
      p {
        white-space: pre-line;
      }
    }

    :last-child {
      margin-bottom: 0;
    }

    .share-menu {
      position: absolute;
      right: 0;
      bottom: 100%;
      //width: 100%;
      padding-top: @_aspect-ratio;
    }
  }

  .activity-logo {
    @_s: 132/1155 * 100%;
    // Aspect ratio hack
    padding-top: @_s;
    width: @_s;
    box-shadow: -2/14rem 0/14rem 7/14rem 0 rgba(0, 0, 0, 0.2);

    //#screen-sm-min({
    position: relative;
    float: left;
    z-index: 100;
    margin-right: @gutter;
    margin-top: -@_s / 2;
    //});
  }

  .activity-header--image {
    background-color: @color_bleu_fonce;

    .activity-header--image-image {
      display: block;
      width: 100%;
      // Le padding-top est utilisé pour simuler un aspect ratio.
      padding-top: @_aspect-ratio;
    }

    .fake-image,
    .fake-image--image-element {
      background-size: cover;
    }
  }
}

div.page-activity > section,
div.page-activity > *:not(section) > section {
  .section-box;
}

// Header des sous-pages.
.page-activity > header,
.activity-child-page > header {
  .activity-link {
    text-decoration: none;
    color: inherit;
  }

  .activity-logo {
    background: @color_blanc;
    border: 1/14rem solid @color_gris2;
    border-radius: @size_general_radius;

    &.is-error > .fake-image--image-element {
      #asset.search-noimage();
    }
  }
}

.activity-child-page > header {
  @_aspect-ratio: 300/1195*100%;

  .fake-image.activity-header--image-image {
    padding-top: @_aspect-ratio;
  }

  .activity-header--description {
    height: 60/14rem;
    padding-bottom: 0;

    & > h1 {
      line-height: 60/14rem;
      padding-top: 0;
    }
  }
}

.activity-page--layout {
  // Utilise un side-nav
  #side-nav();
  #side-nav.specialize(groups, soccer-inv);
}

//.page-activity section
.activity-page-child > section,
.activity-page-child > *:not(section) > section {
  .section-box;
}

.dashboard--child .page-activity, // don't ask... time is precious
.organization-page-child,
.activity-page-child {
  .qidigo-calendar {
    .rbc-month-view {
      min-height: 30rem;
      #screen-md-min({
        min-height: 50vw;
      });
    }
  }
}

.reservation-fragment--reservation {
  position: relative;
  margin: -@gutter;
  margin-bottom: @gutter;
  padding: @gutter;
  padding-bottom: 0;
  border-bottom: 1/14rem @color_container_border solid;
  background: @color_beige;

  .reservation-fragment--session-infos {
    h3 {
      margin: 0;
      text-decoration: underline;
      font-family: @font_primary;
      font-size: @size_categorie1;
    }

    ul li {
      margin: 0;
      padding: 0;
      list-style-type: none;

      &:not(:first-child) {
        font-weight: bold;

        .reservation-fragment--value {
          font-weight: normal;
        }
      }
    }
  }

  .reservation-fragment--warning {
    #box.warning();
  }

  .family-selector {
    max-width: 30rem;
    margin-right: 0;
    margin-left: 0;
  }

  .family-multi-selector {
		max-width: 30rem;
		margin-right: 0;
		margin-left: 0;
		margin-bottom: 1rem !important;
		display: flex !important;
	}

	#screen-xs-max({
		.reservation-fragment--passes:first-child {
			display: none;
		}
		.reservation-fragment--passes:not(:first-child) {
			margin-top: @gutter;
			margin-bottom: @gutter;
		}
	});
	#screen-sm-min({
		.reservation-fragment--passes:first-child {
			float: right;
			margin-left: @gutter/2;
			margin-bottom: @gutter/2;
		}
		.reservation-fragment--passes:not(:first-child) {
			display: none;
		}
	});
}

.restrictions-container {
  #box.warning();
  text-align: left;
  width: 100%;
}

.reservation-fragment {
  @cart_width: @gutter * 1.5;
  @price_width: 10rem;

  .passes-member-offers {
    & > h4 {
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }

  .passes-member-offers--restrictions {
    #box.warning();
  }

  .passes-member-offers--list {
    &:not(.empty-state) {
      margin-left: -@gutter;
      margin-right: -@gutter;
      margin-bottom: -@gutter;
    }

    & > li {
      position: relative;
      margin: 0;
      list-style-type: none;
      padding: @gutter/2 @gutter;
      #screen-sm-min({
        padding-left: @gutter * 2;
      });

      background: @color_beige;
      border-bottom: 1/14rem @color_container_border solid;

      &:first-child {
        border-top: 1/14rem @color_container_border solid;
      }

      h4, h5 {
        font-family: @font_primary;
        font-size: 1.1rem;
        font-weight: normal;
        font-size: @size_texto;
        margin-top: @gutter/2;
        margin-bottom: @gutter/2;
      }

      .group-offerform--sessions,
      .group-offerform--add-to-cart {
        text-align: right;
        margin-bottom: @gutter;
      }

      .group-offerform--add-to-cart button {
        margin-left: @gutter/2;
      }

      .group-offerform--sessions,
      .group-offerform--add-to-cart,
      .group-offerform--price {
        & > * {
          display: inline-block;
        }
      }

      .group-offerform--price {
        & > *:first-child::after {
          display: inline-block;
          content: "";
        }

        & > small::before {
          display: inline-block;
          content: " (";
        }

        & > small::after {
          display: inline-block;
          content: ") ";
        }
      }
    }
  }

  .reservation-fragment--guest {
    padding-bottom: @gutter;
  }
}

//
// Calendrier des passes
// =====================
//

.span--toolbar-date {
  padding: 0 1.5rem 0 1.5rem !important;
  align-self: center;
}

.chevron-right {
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  top: 0.1rem;
}

.chevron-right::after {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  width: 13px;
  height: 13px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  transform: rotate(-45deg);
  right: 6px;
  top: 4px
}

.chevron-left {
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  top: 0.1rem;
}

.chevron-left::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 13px;
  height: 13px;
  border-bottom: 4px solid;
  border-left: 4px solid;
  transform: rotate(45deg);
  left: 6px;
  top: 4px
}

.btn--toolbar-today {
  display: inline-flex;
  height: 2.1875rem;
  padding: 0.375rem 0.75rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.qidigo-sessions-calendar {
  .session-row {
    #no-select();
    cursor: pointer;

    &:not(.is-past, .is-full):hover {
      background-color: @color_background;
    }

    position: relative;

    h4 {
      margin-bottom: 0.4em;
    }

    h5 {
      margin-bottom: 2/14rem;
    }

    &.is-full .session-row__actions *,
    &.is-past .session-row__actions * {
      display: none;
    }

    & > ul {

      &, & > li {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }

      #screen-sm-max({
        h5 {
          margin-top: @gutter/2;
        }
        .session-row__actions {
          text-align: right;

          button {
          }
        }
      });

      #screen-md-min({
        & > li {
          display: inline-block;
          vertical-align: top;
          margin-bottom: @gutter/2;

          &:not(:last-child) {
            padding-right: @gutter/2;
          }

          &:not(:first-child) {
            padding-left: @gutter/2;
          }
        }
        .session-row__actions {
          text-align: right;

          button {
            width: 100%;
          }
        }
      });

      #screen-md-only({
        .session-row__main {
          width: 40%;
        }
        .session-row__restrictions {
          width: 26%;
        }
        .session-row__places {
          width: 18%;
        }
        .session-row__actions {
          width: 16%;
        }
      });
      #screen-lg-only({
        .session-row__main {
          width: 40%;
        }
        .session-row__restrictions {
          width: 28%;
        }
        .session-row__places {
          width: 20%;
        }
        .session-row__actions {
          width: 12%;
        }
      });
    }
  }
}

.page-activity {
  .activity-filter {
    position: relative;
    margin: -@gutter;
    margin-bottom: @gutter;
    padding: @gutter;
    padding-top: @gutter/2;
    padding-bottom: @gutter/2;
    border-bottom: 1/14rem @color_container_border solid;
    background: @color_beige;

    .input-select {
      margin-top: @gutter/2;
      margin-bottom: @gutter/2;
    }
  }

  .page-activity__calendar {
    position: relative;
  }
}

.scroll-disabled {
  overflow-y: hidden;
  overflow-x: hidden;
}

// PassSelectionModal.tsx
.pass-selection-modal {
  position: fixed;
  z-index: 100;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  font-family: @font_primary;
  font-style: normal;
  line-height: normal;

  .pass-selection-modal--error-box {
    #box.error();
  }

  .pass-selection-modal--header {
    margin: 0.2rem;

    .pass-selection-modal--x-button {
      position: absolute;
      right: 2.5rem;
      top: 1rem;
      width: 1rem;
      height: 1rem;
      opacity: 0.3;
    }

    .pass-selection-modal--activity-name {
      color: #193D4A;
      font-size: 1.5rem;
      font-weight: 400;
      margin: 0.75rem 4rem 0.75rem 0.75rem;
      overflow-wrap: break-word;
    }
  }

  .large-modal {
    width: 75% !important;

    @media (max-width: @md) {
      width: 95% !important;
    }
  }

  .pass-selection-modal--content {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50rem;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    border: 0.2rem solid @activity_color_container_border;
    animation: modalAnimation 0.5s;
    animation-direction: normal;
    background-color: @color_blanc;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 90%;

    @media (max-width: @md) {
      width: 95%;
    }
  }

  @keyframes modalAnimation {
    0% {
      left: 50%;
      top: 0;
    }
    50% {
      left: 50%;
      top: 50%;
    }
  }

  .pass-selection-modal--title {
    padding-left: 0.50rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.1px solid @activity_color_container_border;
    font-weight: 600;
    font-size: @text-medium;
    line-height: 23px;
  }

  .pass-selection-modal--x-button:hover {
    opacity: 1;
  }

  .pass-selection-modal--x-button:before, .pass-selection-modal--x-button:after {
    position: absolute;
    left: 1rem;
    content: ' ';
    height: 1rem;
    width: 0.15rem;
    background-color: @qidigo_dark_grey;
  }

  .pass-selection-modal--x-button:before {
    transform: rotate(45deg);
  }

  .pass-selection-modal--x-button:after {
    transform: rotate(-45deg);
  }

  .pass-selection-modal--row {
    display: grid;
    float: right;
    grid-auto-flow: row;
    margin-bottom: 1.75rem;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .pass-selection-modal--input-container {
    grid-column: span 12 / span 12;

    @media (min-width: @activity-md) {
      grid-column: span 7 / span 7;
    }
  }

  .pass-selection-modal--input {
    margin: 0;
  }

  .pass-selection-modal--button {
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
  }

  .pass-selection-modal--close-button {
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: @color_gris0;
    color: rgb(0, 0, 0);
    font-weight: 400;
    border: solid 0.10rem @color_grismoyen;
  }

  .pass-selection-modal--close-button:hover {
    background-color: @color_grismoyen;
  }

  .pass-selection-modal--primary-button {
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background: @activity-primary-color !important;
    color: @activity-secondary-color;
    float: right;
    width: fit-content;
    margin-right: 0 !important;
  }

  .pass-selection-modal--primary-button:hover, .pass-selection-modal--secondary-button:hover {
    background-color: @activity-third-color !important;
    color: @activity-secondary-color;
  }

  .pass-selection-modal--secondary-button {
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid @activity-primary-color;
    background: @activity-secondary-color;
    color: @activity-primary-color;;
    float: right;
    width: fit-content;
    margin-right: 0 !important;
  }

  .pass-selection-modal--max-button-container {
    display: inline-grid;
    align-items: end;
    grid-column: span 12 / span 12;

    @media (min-width: @activity-md) {
      grid-column: span 2 / span 2;
      margin-left: 0.50rem;;
    }

    @media (max-width: @activity-md) {
      margin-top: 0.50rem;
    }
  }

  .pass-selection-modal--remove-button-container {
    display: inline-grid;
    align-items: end;
    grid-column: span 12 / span 12;

    @media (min-width: @activity-md) {
      grid-column: span 3 / span 3;
      margin-left: 0.50rem;;
    }

    @media (max-width: @activity-md) {
      margin-top: 0.25rem;
    }
  }

  .pass-selection-modal--form {
    padding: 1.25rem;
  }

  .pass-selection-modal--submit-row {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
  }

  //ModalStepsHeader.tsx
  .modal-steps-header {
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
    justify-content: center;
    border-bottom: 0.1px solid @activity_color_container_border;
    padding: 2rem;

    .modal-steps-header--step-container {
      display: flex;
      align-items: center;
    }

    .modal-steps-header--step {
      display: flex;
      flex-direction: column;
      align-items: center;

      .modal-steps-header--step-text {
        position: relative;
        top: 0.5rem;
        width: 5rem;
        height: 3rem;
        margin-left: -100%;
        margin-right: -100%;
        text-align: center;
      }
    }

    .modal-steps-header--current-step {
      width: 49px;
      height: 49px;
      flex-shrink: 0;
      fill: @activity-secondary-color;
      stroke-width: 1px;
      stroke: @activity-primary-color;
      border-radius: 50%;
      border-color: @activity-primary-color;
      border-style: solid;
      border-width: thin;
      box-shadow: 0 0 0.57142857rem @activity-primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      color: @activity-primary-color;
      font-size: 18px;
      font-weight: bold;

    }

    .modal-steps-header--completed-step {
      width: 49px;
      height: 49px;
      border-radius: 50%;
      box-shadow: 0 0 0.57142857rem @activity-primary-color;
      border-color: @activity-secondary-color;
      background-color: @activity-primary-color;
      border-style: solid;
      border-width: thin;
      display: flex;
      align-items: center;
      justify-content: center;
      color: @activity-secondary-color;
      font-size: 18px;
      font-weight: bold;
    }

    .modal-steps-header--none-completed-step {
      width: 49px;
      height: 49px;
      flex-shrink: 0;
      fill: rgba(255, 255, 255, 0.50);
      stroke-width: 1px;
      stroke: rgba(39, 112, 142, 0.50);
      border-radius: 50%;
      box-shadow: 0 0 0.57142857rem @activity-primary-color;
      background-color: rgba(255, 255, 255, 0.50);
      border-color: rgba(39, 112, 142, 0.50);;
      border-style: solid;
      border-width: thin;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(39, 112, 142, 0.50);
      font-size: 18px;
      font-weight: bold;
    }

    .modal-steps-header--progress-line {
      width: 12rem;
      height: 0.1rem;
      background-color: @activity-primary-color;
      margin: 0.5rem;
      position: relative;
      bottom: 1.5rem;

      @media (max-width: @activity-md) {
        width: 7rem;
      }

      @media (max-width: @activity-sm) {
        width: 5rem;
      }

      @media (max-width: @activity-xs) {
        width: 3rem;
      }
    }

    .modal-steps-header--progress-line-disabled {
      width: 12rem;
      height: 0.1rem;
      margin: 0.5rem;
      background-color: rgba(39, 112, 142, 0.50);
      position: relative;
      bottom: 1.75rem;

      @media (max-width: @activity-md) {
        width: 7rem;
      }

      @media (max-width: @activity-sm) {
        width: 4.5rem;
      }

      @media (max-width: @activity-xs) {
        width: 2.5rem;
      }
    }


  }

  .pass-selection-modal--body-container {
    text-align: -webkit-center;

    //CourseDescription.tsx
    .pass-selection-description {
      margin: 1.25rem;

      .pass-selection-description--description-container {
        border-bottom: 1/14rem @activity_color_container_border solid;
        min-height: 8.25rem;

        .pass-selection-description--text-description {
          text-align: justify;
          color: #333;
          font-size: 16px;
          font-weight: 400;
          margin: 0.1rem !important;
        }

        .pass-selection-description--image {
          float: left;
          margin-right: 2rem;
          margin-bottom: 0.25rem;

          img {
            max-width: 100%;
            height: auto;
            display: block;
          }

          &, .fake-image {
            @_s: 100px;
            width: @_s;
            height: @_s;

            &.is-error {
              #asset.search-noimage();
            }
          }
        }
      }

      .pass-selection-description--details-container {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        column-gap: 0.25rem;
        margin-top: 2rem;
        margin-left: 1rem;
        text-align: left;

        p {
          margin: 0.1rem !important;
        }

        h3 {
          margin-bottom: 0.4rem !important;
          color: #333;
          font-size: 16px;
          font-weight: 750;
          width: 7rem;
          -webkit-text-stroke: thin;
        }

        .pass-selection-description--schedule-container {
          grid-column: span 6 / span 6;
          display: block;

          ul, li {
            list-style-type: none;
            margin: 0 !important;
            padding: 0 !important;
            color: #333;
            font-size: 16px;
            font-weight: 400;
          }

          @media (max-width: @activity-sm    ) {
            grid-column: span 12 / span 12;
          }

          p {
            color: #333;
            font-size: 16px;
            font-weight: 400;
            margin: 0.1rem !important;
          }
        }

        .pass-selection-description--restrictions-container {
          display: block;
          grid-column: span 6 / span 6;

          @media (max-width: @activity-sm) {
            grid-column: span 12 / span 12;
            margin-top: 2rem;
          }

          .pass-selection-description--detail-line {
            display: flex;
            gap: 1rem;
          }

          .pass-selection-description--detail-title {
            color: #333;
            font-size: 16px;
            font-weight: 700;
            width: 4rem;
          }

          .pass-selection-description--detail-text {
            color: #333;
            font-size: 16px;
            font-weight: 500;
            margin: 0 !important;
            overflow: auto;
            display: flex;
          }

          ul, li {
            list-style-type: none;
            margin: 0 !important;
            padding: 0 !important;
            font-size: 16px;
            font-weight: 400;
          }

          .pass-selection-description--restriction-status-unavailable {
            color: red !important;
          }
        }
      }

      .pass-selection-description---time-window-restriction-container {
        display: flex;
        margin-top: 2rem;

        ul, li {
          list-style-type: none;
          margin-left: 0.15rem !important;
          padding: 0 !important;
        }
      }
    }

    //Login.tsx and Register.tsx
    .pass-selection-login {
      width: 60%;
      margin-bottom: 2rem;

      @media (max-width: @activity-sm) {
        width: 90%;
      }

      .pass-selection-login--social-login {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
        align-items: center;
        row-gap: 1rem;

        .pass-selection-login--social-button-container {
          width: 100%;

          &:hover {
            transform: scale(1.05);
          }

          .pass-selection-login--social-button {
            border: 1px solid @activity-primary-color;
            background: @activity-secondary-color;
            color: @activity-primary-color;;
            float: right;
            width: 100%;
            margin-right: 0 !important;
            height: 4rem;
            text-align: center;
            display: flex;
            align-items: center;

            .pass-selection-login--social-button-icon-facebook {
              background-color: @activity-primary-color;
              #__icon-self('facebook', @activity-secondary-color, @activity-secondary-color);
              background-size: 1.5rem;
              width: 2rem;
              height: 2rem;
              border-radius: 50%;
              margin-right: 2rem;
              background-position: center;
            }

            .pass-selection-login--social-button-icon-google {
              background-color: @activity-secondary-color;
              #__icon-self('google', @activity-primary-color, @activity-primary-color);
              background-size: 1.5rem;
              width: 2rem;
              height: 2rem;
              margin-right: 2rem;
              background-position: center;
            }
          }
        }
      }

      .pass-selection-login--form-container {
        display: flex;
        flex-direction: column;
        text-align: start;

        .pass-selection-login--forgot-password-container {
          float: left;
        }

        .pass-selection-login--remember-checkbox {
          float: left;
        }
      }

      .pass-selection-login--buttons-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    //Consent.tsx
    .pass-selection-consent {
      width: 80%;
      display: flex;
      flex-direction: column;
      margin: 2rem;

      .pass-selection-consent--text-container {
        text-align: justify;

        .pass-selection-consent--title {
          color: @activity-primary-color;
          font-size: 16px;
          font-weight: 700;
        }

        .pass-selection-consent--text {
          .pass-selection-description--detail-text {
            color: #333;
            font-size: 16px;
            font-weight: 500;
            margin: 0 !important;
            overflow: auto;
          }
        }
      }

      .pass-selection-consent--checkbox {
        font-weight: 600 !important;
        text-align: left;
        display: flex;
      }
    }

    .pass-selection-subscription--reservation-confirmation {
      margin: 1rem 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }

    //Subscription.tsx
    .pass-selection-subscription {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      width: 90%;
      padding: 2rem;
      text-align: center;

      @media (max-width: @activity-sm) {
        flex-direction: column;
        align-items: center;
      }

      .pass-selection-subscription--list-title {
        border-bottom: 0.2rem solid @activity-primary-color;
        height: 2.5rem;
        color: #333;
        font-size: 16px;
        font-weight: 700;
        width: 100%
      }

      .pass-selection-subscription--user-pass-list-container {
        display: flex;
        flex-direction: column;
        row-gap: 0.1rem;
        width: 90%;

        .pass-selection-subscription--user-pass-boxes-container {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          align-items: center;
          overflow-y: auto;
          max-height: 18rem;
          width: 100%;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          padding-top: 1rem;
          padding-bottom: 1rem;

          .pass-selection-subscription--user-pass-box {
            width: 95%;
            padding: 1.25rem;
            border: 1px solid @activity-primary-color;
            border-radius: 0.5rem;
            box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
            background-color: @activity-secondary-color;
            transition: transform 0.3s ease-in-out;
            margin-bottom: 1rem;
            text-align: center;

            &:hover {
              transform: scale(1.05);
            }

            .pass-selection-subscription--user-pass-name {
              font-size: 1rem;
              font-weight: bold;
              margin-bottom: 0.625rem;
            }

            .pass-selection-subscription--user-pass-quantity {
              font-size: 1rem;
              color: #555;
            }

            .pass-selection-subscription--user-pass-reservation-button {
              margin-top: 0.9rem;
              padding: 0.6rem 0.9rem;
              background-color: @activity-primary-color;
              color: @activity-secondary-color;
              text-align: center;
              text-decoration: none;
              border-radius: 0.3rem;
              transition: background-color 0.3s ease-in-out;

              &:hover {
                background-color: #626262;
              }
            }
          }
        }
      }

      .pass-selection-subscription--organization-pass-list-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;

        .pass-selection-subscription--organization-pass-boxes-container {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          align-items: center;
          overflow-y: auto;
          max-height: 18rem;
          width: 100%;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          padding-top: 1rem;
          padding-bottom: 1rem;
          margin-bottom: 1rem;

          .pass-selection-subscription--organization-pass-box {
            width: 90%;
            padding: 1rem;
            border: 1px solid @activity-primary-color;
            border-radius: 0.3125rem;
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
            background-color: @activity-secondary-color;
            transition: transform 0.3s ease-in-out;
            text-align: start;

            &:hover {
              transform: scale(1.05);
            }

            .pass-selection-subscription--organization-pass-checkbox-wrapper {
              display: flex;
              align-items: center;
              margin-bottom: 0.625rem;

              .pass-selection-subscription--organization-pass-checkbox {
                width: 1rem;
                height: 1rem;
                border: 1px solid #8AA4AF;
                border-radius: 50%;
                margin-right: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .pass-selection-subscription--organization-pass-checkbox-checked {
                content: '';
                display: block;
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 50%;
                background-color: #8AA4AF;
              }

              .pass-selection-subscription--organization-pass-checkbox-text {
                font-size: 0.875rem;
                color: #555;
              }
            }

            .pass-selection-subscription--organization-pass-price {
              font-size: 1rem;
              font-weight: bold;
              color: @activity-primary-color;
              margin-top: 0.6rem;
              margin-bottom: 0.6rem
            }
          }
        }

        .pass-selection-subscription--add-to-cart-button {
          align-items: center;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          background-color: @activity-primary-color !important;
          color: @activity-secondary-color;
          width: fit-content;
          margin-right: 0 !important;
          margin-top: 1rem;

          &::before {
            content: "";
            background-image: url("~design/assets/menu/cart.svg");
            display: inline-block;
            width: 20 / 14rem;
            height: 20 / 14rem;
            margin-right: 0.2rem
          }

          span {
            position: relative;
            bottom: 0.25rem;
          }
        }

        .pass-selection-subscription--already-present-container {
          text-align: left !important;
        }
      }
    }

    //SeparationLine.tsx
    .pass-selection-separation-container {
      display: flex;
      flex-direction: row;
      margin-top: 1.5rem;
      width: 100%;
      justify-content: center;

      .pass-selection-separation-line {
        width: 12rem;
        height: 0.15rem;
        background-color: @activity-primary-color;
        margin: 0.5rem;

        @media (max-width: @activity-sm) {
          width: 100%;
        }
      }

      .pass-selection-modal--or {
        color: @activity-primary-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .pass-selection-separation-container-vertical {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
      margin-right: 1rem;
      width: 2rem;
      align-items: center;

      .pass-selection-separation-line {
        height: 90%;
        width: 0.15rem;
        background-color: @activity-primary-color;
        margin: 0.5rem;
      }

      .pass-selection-modal--or {
        color: @activity-primary-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0
      }
    }
  }
}

.calendar-days--en {
  padding-left: 5.25rem !important;
}

.calendar-days {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 8.25rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.40rem @activity-third-color solid;
  text-align: -webkit-center;

  .calendar-days--day {
    flex: 1;
  }

  .calendar-days--day-of-week {
    margin-bottom: 0.75rem;
    font-weight: bold;
  }

  .calendar-days--day-of-month {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    fill: @activity-secondary-color;
    stroke-width: 1px;
    border-radius: 50%;
    border-color: @activity-primary-color;
    border-style: solid;
    border-width: thin;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @activity-primary-color;
    font-size: 0.75rem;
    font-weight: bold;
  }

  .calendar-days--day-of-month-today {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    background-color: @activity-primary-color;
    border-radius: 50%;
    border-color: @activity-primary-color;
    border-style: solid;
    border-width: thin;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @activity-secondary-color;
    font-size: 0.75rem;
    font-weight: bold;
  }
}

.activity-filters-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  //   max-width: 16rem; // FIXME: does no work on wide screen

  @media (max-width: @xlg) {
    flex-direction: column;
    // max-width: unset; // FIXME: does no work on wide screen
    gap: 0
  }

  .activity-filters--filter {
    width: 100%;
    max-width: 16rem;

    @media (max-width: @xlg) {
      width: 100%;
      max-width: unset;
    }
  }
}

.calendar-toolbar {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 0.2rem @activity-fourth-color solid;

  @media (max-width: @md) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .calendar-toolbar--invisible-section {
    width: 10%;

    @media (max-width: @md) {
      width: 0;
    }
  }

  .calendar-toolbar--day-container {
    display: flex;
    align-items: center;

    .calendar-toolbar--btn-today {
      display: inline-flex;
      height: 2.1875rem;
      padding: 0.375rem 0.75rem;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      margin-right: 1rem;
    }

    .calendar-toolbar--btn-next {
      display: flex !important;
    }
  }

  .calendar-toolbar--view-btn-group-container {
    text-align: left;
    display: block;
    align-self: center;

    .calendar-toolbar--view-btn-selected {
      background-color: @activity-fifth-color;
    }
  }
}

.calendar-toolbar--legend-box {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
  background-color: @activity-secondary-color;
  padding: 0.5rem 2rem;

  div {
    display: flex;
    align-items: center;
  }

  @media(max-width: @lg) {
    flex-direction: column;
  }

  .calendar-toolbar--legend-available {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: @activity-primary-color;
    margin-right: 0.5rem;
  }

  .calendar-toolbar--legend-available-soon {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: @activity-sixth-color;
    margin-right: 0.5rem;
  }

  .calendar-toolbar--legend-not-available {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: @activity-fourth-color;
    opacity: 0.5;
    margin-right: 0.5rem;
  }

  .calendar-toolbar--legend-full {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-image: repeating-linear-gradient(45deg, transparent, #BE7D7DFF 0.125rem, #BE7D7DFF 0.125rem, @activity-seventh-color 0.25rem);
    margin-right: 0.5rem;
  }
}

.rbc-event-label {
  display: none;
}

.ul--restrictions-list {
    margin-bottom: 0.2rem;
}

.div--subscription-restrictions-container {
    margin-bottom: 0.1rem;
}
