//
// Fragments d'offre
// =================
//
// Une "ligne" d'offre.
//
// Inclut un contrôle pour sélectionner un membre.
//
//

.offer-fragments {
	@price_width: 10rem;
	@cart_width: @gutter * 1.5;

	&>ul, &>ul>li {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	h3 {
		margin-bottom: 0;
	}

	&>ul>li {
		margin-left: -@gutter;
		margin-right: -@gutter;
		padding-left: @gutter;
		padding-right: @gutter;
		&:not(:last-child) {
			padding-bottom: @gutter;
			margin-bottom: @gutter;
			border-bottom: 1/14rem solid @color_gris1;
		}
	}

	p {
		font-size: @size_courant;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.offer-fragment--end-date {
		font-size: @size_mini;
	}
	.offer-fragmentform--price {
		color: @color_qidigo_rouge;
		#font-secondary;
		&>* {
			display: block;
		}
	}

	.offer-fragment--restrictions {
		#box.warning();
	}

	.offer-fragment--subscribers {
		width: 100%;
	}

	.offer-fragment > ul {
		//margin: 0;
		//padding: 0;

		& > li {
			list-style-type: none;
			margin: 0;
		}

		// côte-à-côte
		#screen-md-min({
			margin-left: -@gutter/2;
			margin-right: -@gutter/2;
			& > li {
				vertical-align: top;
				display: inline-block;
				padding: @gutter/2;
			}
			.offer-fragment--description {
				width: 45%;
			}
			.offer-fragment--form {
				width: 55%;
			}
		});

		.offer-fragment--form {
			button.offer-fragment--add-to-cart-button {
				border: 0;
				overflow: hidden;
				height: @cart_width;
				float: right;
				margin-left: @gutter/2;
			}

				.offer-fragment--sessions,
				.offer-fragment--add-to-cart {
						text-align: right;
						margin-bottom: @gutter;
				}
				.offer-fragment--sessions,
				.offer-fragment--add-to-cart,
				.offer-fragment--price {
					& > * {
						display: inline-block;
						vertical-align: sub;
					}
				}
				.offer-fragment--price {
					& > *:first-child::after {
						display: inline-block;
						content: "";
					}
					& > small::before {
						display: inline-block;
						content: " (";
					}
					& > small::after {
						display: inline-block;
						content: ") ";
					}
				}
		}

		&:not(.with-waiting-list) {
			.offer-fragment--form {
				#screen-sm-min({
					.offer-fragment--subscribers {
						margin-right: @cart_width + @price_width + @gutter * 3;
					}
				});
			}
		}
	}
}
