.carousel {
	@_tr: 0.3s ease-out;
	@_color: #ffffff;
	@_diam: 10 / 14rem;

	overflow: hidden;
	position: relative;
	// Donne un index au container, mais l'index "zéro".
	// Ça permet au -1 donné aux images de fonctionner
	// correctement, sans être supplanté par un background
	// d'un parent.
	z-index: 0;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(to bottom, transparent 65%, rgba(0,0,0,0.4));
	}

	.carousel--selector {
		position: absolute;
		bottom: @_diam;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
		color: #ffffff;
	}

	.carousel--selector-dot {
		display: inline-block;
		color: @_color;
		border: 1px solid @_color;
		border-radius: @_diam;
		width: @_diam;
		height: @_diam;
		margin: @_diam/4;

		text-decoration: none;
		text-indent: -1000rem;
		overflow: hidden;

		cursor: pointer;

		&.selected {
			background-color: @_color;
		}
		transition:
			background @_tr,
			border @_tr,
		;
	}

	.carousel--containers {
		z-index: -1;
		position: relative;

		&>* {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.carousel-enter {
			z-index: 1;
		}

		.carousel-leave {
			z-index: 2;
			opacity: 1;
		}

		.carousel-leave.carousel-leave-active {
			opacity: 0.001;
			transition: opacity .5s ease-in;
		}
	}
}
