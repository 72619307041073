.page-organization > header {
	// Aspect ratio utilisé pour le height du carousel.
	@_carousel-aspect-ratio: 300/1195*100%;
	position: relative;
	overflow: hidden;
	background: @color_blanc;
	border-radius:
	0
	0
	@size_general_radius
	@size_general_radius
	;
	border: 1/14rem solid @color_gris1;
	border-top: 0;
	margin-bottom: @gutter;

	h1 {
		margin: 0;
		margin-bottom: @gutter;
		padding-top: @gutter/2;
		#self-clear();
	}
	ul {
		#screen-xs-only({
		margin-bottom: 0;	
		})
	}
	.organization--cheap-preload {
		height: 0;
		position: absolute;
		visibility: hidden;

		// Images faussement cachées, permettant un preload.
		img {
			#no-select();
			position: absolute;
			width: 1px;
			height: 1px;
			visibility: hidden;
			opacity: 0.001;
		}
	}
	.organization-header--links {
		#header-links();
	}
	.organization-header--link-message     { a::before { #__icon-self("compose",    "", @color_blanc); } }
	.organization-header--link-website     { a::before { #__icon-self("website",    "", @color_blanc); } }
	.organization-header--link-subscribe   { a::before { #__icon-self("star-empty", "", @color_blanc); } }
	.organization-header--link-unsubscribe { a::before { #__icon-self("star-full",  "", @color_blanc); } }
	.organization-header--link-share       { a::before { #__icon-self("share",      "", @color_blanc); } }

	.organization-header--description {
		position: relative;
		padding: @gutter;
		padding-top: 0;

		// En attendant qu'on reçoive du HTML.
		p {
			white-space: pre-line;
		}
		p:last-child {
			margin-bottom: 0;
		}
	}
	.organization-logo {
		@_s: 132/1155 * 100%;
		@_logo_size: 3rem;
		// Aspect ratio hack
		box-shadow:  -2/14rem 0/14rem 7/14rem 0 rgba(0, 0, 0, 0.2);
		position: relative;
		z-index: 100;
		
		#screen-sm-min({
			margin-right: @gutter;
			margin-top: -@_s / 2;
			padding-top: @_s;
			width: @_s;
		});
		
		#screen-xs-max({
			padding-top: @_logo_size + 2;
			width: @_logo_size + 2;
			margin-right: @gutter / 2;
			vertical-align: baseline;
			margin-top: -0.22em;
		});

		#screen-xxs-max({
			padding-top: @_logo_size;
			width: @_logo_size;
			vertical-align: middle;
		});
	}

	.organization--carousel {
		background-color: @color_bleu_fonce;
		.carousel--containers {
			display: block;
			width: 100%;
			// Le padding-top est utilisé pour simuler un aspect ratio.
			padding-top: @_carousel-aspect-ratio;
		}

		.fake-image,
		.fake-image--image-element {
			background-size: cover;
		}
	}

	.organization-header--description {
		position: relative;

		.share-menu {
			position: absolute;
			right: 0;
			bottom: 100%;
			//width: 100%;
			padding-top: @_carousel-aspect-ratio;
		}
	}
}

.organization-child-page {
	position: relative;
}

// Header des sous-pages.
.page-organization > header,
.organization-child-page > header {
	.organization-link {
		text-decoration: none;
		color: inherit;
	}
	.organization-logo {
		background: @color_blanc;
		border: 1/14rem solid @color_gris2;
		border-radius: @size_general_radius;
		&.is-error>.fake-image--image-element {
			#asset.search-noimage();
		}
	}
}

// Layout des sous-pages.
.organization-child-page > header {
	#screen-sm-min({
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		z-index: 1;
	});

	@_size: @size_mega * 1.2;
	padding: @gutter/2 0;
	margin: 0;
	#translucent(background-color, @color_blanc, 0.8);
	border: 1/14rem @color_gris1 solid;
	border-top: 0;

	h1 {
		margin: 0;
		color: @color_grisfonce;
		font-size: @size_categorie0;
	}
	h1 * {
		vertical-align: middle;
	}
	.organization-logo {
		width:  @_size;
		height: @_size;
		margin-right: @gutter;
		margin-left: @gutter;
	}
}

//
// Layout des pages attachées à l'organisme.
//
.organization-page--layout {
	// Utilise un side-nav
	#side-nav();
}

//.page-organization section
.organization-page-child > section,
.organization-page-child > *:not(section) > section {
	.section-box;
}
