//
// Component utilisé pour présenter une boîte "dashboard-like".
// Titre + bouton + boîte.
//

.with-navigation {
	position: relative;
	border-radius: @size_general_radius;
	margin-bottom: @gutter;
	& > h2 {
		font-size: @size_categorie2;
	}

	.with-navigation--navigation {
		text-align: right;
		position: absolute;
		top: 0;
		right: 0;
	}

	.with-navigation--contents {
		border: 1/14rem solid @color_gris0;
		padding: @gutter;
	}
}

