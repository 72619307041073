@text-large: 1.5rem;
@text-medium: 1.25rem;
@text-small: 1rem;
@line-height-x-large: 1.875rem;
@line-height-large: 1.5625rem;
@line-height-medium: 1.25rem;
@line-height-small: 1.125rem;
@checkout-box-background: rgb(184 199 206);
@xs: 375px;
@sm: 600px;
@md: 993px;
@lg: 1024px;
@xlg: 1200px;

#billing-page .hidden {
    display: none;
}

#billing-page .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#billing-page {
    .billing-view--container {
        padding: 3rem 5rem 2rem 2rem;
        background-color: @color_blanc;

        .billing-view--payment-errors-container {
            #box.error();
        }

        .billing-view--installment-warning-container {
            #box.warning();
        }

        .billing-view--remaining-installments {
            text-decoration: underline;
            margin-top: 0.25rem;
            margin-bottom: 0.15rem;
        }

        .billing-view--confirm-button-container,
        .billing-view--continue-shopping-button-container {
            text-align: right;
        }

        .billing-view--back-to-last-location-container {
            padding-bottom: 1rem;
            border-bottom: solid 2px @color_container_border;

            .return-fragment.return-fragment {
                border-left: none;
                border-right: none;

                a {
                    padding-left: 0;
                }
            }
        }

        .billing-view-summary-container {
            border-bottom: solid 2px @color_container_border;
            padding-bottom: 2rem;

            &.empty {
                border-bottom: none;
                padding-bottom: 1rem;
            }

            .billing-view--title {
                line-height: 1.48;
                margin: 0;
                margin-left: -1.42857143rem;
                margin-right: -1.42857143rem;
                margin-top: -1.42857143rem;
                margin-bottom: 1.42857143rem;
                padding: 0.71428571rem 1.42857143rem;
                font-size: 1.71428571rem;
                color: #194453;
                border-bottom: 0.07142857rem solid #e9e9e9;
            }

            // summary.tsx
            .summary {

                .billing-summary {
                    color: @color_qidigo_bleu;
                    padding-top: 10px;
                    padding-bottom: 30px;
                    font-size: @text-large;
                    line-height: @line-height-x-large;
                }

                .summary-lines {
                    margin-bottom: 0.5rem; // TODO USE VARIABLE
                    border-style: solid;
                    border-bottom-width: 2px; //TODO CHANGE ME FOR LESS VARIABLE IN REM
                    border-color: @color_container_border;
                }

                .billing-total {
                    font-size: @text-medium;
                    line-height: @line-height-large;
                    display: flex;
                    justify-content: space-between;
                    margin-left: 0;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                }

                //userLine.tsx
                .user-line {
                    .user-line--header {
                        align-items: center;
                        background-color: @checkout-box-background;
                        display: flex;
                        justify-content: space-between;
                        margin-left: 0;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;

                        @media (max-width: @sm) {
                            display:grid;
                            grid-template-columns: repeat(1, minmax(0, 1fr));
                        }

                        & > .user-line--triangle {
                            position: relative;
                            right: @gutter;
                            height: @collapse_triangle_size;
                            #collapse-triangle(@color_police);

                            @media (max-width: @sm) {
                                margin-top: 1.25rem;
                                grid-column: span 2 / span 2;
                            }
                        }

                        cursor: pointer;
                        padding: @gutter/2 @gutter;
                        padding-left: @gutter * 2.5;

                        h3 {
                            width: 100%;
                            margin-bottom: 0.1em;
                            line-height: 1.1;
                            #screen-xs-max({
                                line-height: 1.5;
                            });
                            overflow-wrap: anywhere;

                            @media (max-width: @sm) {
                                grid-column: span 10 / span 10;
                            }
                        }

                        .user-line--product-price {
                            font-weight: 700;
                            font-size: @text-small;
                            line-height: @line-height-medium;
                            width: 100%;
                            text-align: right;

                            @media (max-width: @sm) {
                                margin-top: 0.75rem;
                                grid-column: span 12 / span 12;
                            }
                        }
                    }

                    .user-line--lines-container {
                        margin-top: 1rem;
                        margin-left: 1rem;
                        padding-bottom: 0.25rem;
                    }
                }

                // line.tsx

                .line-contract-line {
                    line-height: @line-height-small;
                    display: flex !important;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-left: 0 !important;
                    padding-top: 0.5rem !important;
                    padding-bottom: 0.5rem !important;
                    margin-bottom: 2.5rem !important;
                }

                .line--line {
                    display: grid;
                    grid-template-columns: repeat(12, minmax(0, 1fr));
                    column-gap: 0.25rem;
                    margin-left: 0;
                    margin-bottom: 1rem;
                    @media (min-width: @md) {
                        column-gap: 1.5rem;
                        grid-template-columns: repeat(24, minmax(0, 1fr));
                    }

                    .line--product-name {
                        font-size: @text-medium;
                        line-height: @line-height-large;
                        overflow-wrap: break-word;
                        margin-right: 0.75rem;
                    }

                    .line--remove-line-button {
                        line-height: @line-height-small;
                        padding-left: 0;
                    }
                    .line--subscriber-reservation,
                    .line--subscriber-name {
                        font-weight: 600;
                        font-size: @text-small;
                        line-height: @line-height-medium;
                        margin-top: 7.69px;
                    }

                    .line--product-duration {
                        font-weight: 600;
                        font-size: @text-small;
                        line-height: @line-height-medium;
                        margin-top: 7.69px;

                        p {
                            display: inline
                        }
                    }

                    .line--subscriber-reservation--name-date {
                        font-size: @text-small;
                        line-height: @line-height-large;
                        overflow-wrap: break-word;
                        margin-right: 0.75rem;
                    }


                    .line--product-description {
                        margin-top: 11.72px ;
                    }

                    .line--product-description,
                    .line--see-more,
                    .line--see-hide {
                        line-height: @line-height-small;
                    }

                    .line--product-price-container {
                        grid-column: span 3 / span 3;
                        display: flex;
                        align-items: flex-end;

                        @media (min-width: @md) {
                            grid-column: span 6 / span 6;
                        }

                        @media (min-width: 1200px) {
                            grid-column: span 7 / span 7;
                        }

                        @media (max-width: @xs) {
                            grid-column: span 12 / span 12;
                        }
                    }

                    .line--product-price {
                        font-weight: 700;
                        font-size: @text-small;
                        line-height: @line-height-medium;
                        width: 100%;
                        text-align: right;

                        @media (max-width: @xs) {
                            text-align: left;
                            margin-top: 0.75rem;
                        }
                    }

                    .removed-line-reload {
                        float: right;
                        @media (min-width: 1200px) {
                            margin-bottom: 35%;
                        }

                        @media (max-width: 1200px) {
                            margin-bottom: 65%;
                        }

                        @media (max-width: 486px) {
                            margin-bottom: 210%;
                        }
                    }

                    .line--truncated-text {
                        overflow-wrap: break-word;
                    }

                    .line--product-text-container {
                        grid-column: span 9 / span 9;
                        @media (min-width: @md) {
                            grid-column: span 14 / span 14;
                        }

                        @media (min-width: 1200px) {
                            grid-column: span 14 / span 14;
                        }
                    }

                    .line--product-image-container {
                        display: block;
                        grid-column: span 6 / span 6;

                        @media (min-width: @md) {
                            grid-column: span 4 / span 4;
                        }

                        @media (min-width: 1200px) {
                            grid-column: span 3 / span 3;
                        }
                    }

                    .line--product-image {
                        &, .fake-image {
                            @_s: 100px;
                            width: @_s;
                            height: @_s;

                            &.is-error {
                                #asset.search-noimage();
                            }
                        }
                    }
                }

                // couponAdjustment.tsx
                .coupon-adjustment {
                    margin-bottom: 1.5rem;


                    .coupon-adjustment-button,
                    .coupon-adjustment-title {
                        font-size: @text-small;
                        line-height: @line-height-medium;
                        margin-top: 1rem;
                        padding-left: 0;
                    }

                    .coupon-adjustment--container {
                        margin-top: 2rem;
                    }

                    .coupon-adjustment--input {
                        margin: 0;
                        display: inline-block;
                        @media (min-width: @md) {
                            width: 33.333333%;
                        }
                    }

                    .coupon-adjustment--button {
                        @media (min-width: @md) {
                            margin-left: 34px;
                        }

                        @media (max-width: @md) {
                            margin-top: 10px;
                        }
                    }
                }

                // contactCredit.tsx
                .contact-credit--box {
                    min-height: 3.125rem;
                    font-size: @text-small;
                    line-height: @line-height-medium;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.75rem;
                    background-color: @checkout-box-background;

                    @media (max-width: @xlg) {
                        display:grid;
                        grid-template-columns: repeat(1, minmax(0, 1fr));
                    }

                    .contact-credit--text {
                        @media (max-width: @xlg) {
                            text-align: center;
                            margin-bottom: 1rem;
                            grid-column: span 12 / span 12;
                        }
                    }

                    .contact-credit--money {
                        font-weight: bold;

                        @media (max-width: @xlg) {
                            text-align: center;
                            margin: 0.75rem;
                            grid-column: span 12 / span 12;
                        }
                    }

                    .contact-credit--button {
                        margin-left: 1.5rem;
                        @media (max-width: @xlg) {
                            grid-column: span 12 / span 12;
                            margin-left: 0rem;
                        }
                    }
                }

                // priceBreakDown.tsx
                .price-breakdown {
                    margin-bottom: 1rem;
                    .price-breakdown--li {
                        list-style-type: none;
                        margin-left: 0;
                    }

                    .price-breakdown--line {
                        line-height: @line-height-small;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-left: 0;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;

                        @media (max-width: @sm) {
                            display:grid;
                            grid-template-columns: repeat(1, minmax(0, 1fr));
                        }

                        .relative {
                            position: relative;
                        }
                    }

                    .price-breakdown--text {
                        @media (max-width: @sm) {
                            grid-column: span 12 / span 12;
                        }
                    }

                    .price-breakdown--money {
                        font-weight: bold;

                        @media (max-width: @sm) {
                            margin-top: 0.5rem ;
                            grid-column: span 12 / span 12;
                        }
                    }

                    .price-breakdown--credit-adjust,
                    .price-breakdown--coupon-remove-button {
                        font-style: italic;
                    }

                    .price-breakdown--coupon-remove-button,
                    .price-breakdown--credit-adjust {
                        margin-right: @gutter;
                        position: relative;
                        left: 100%;
                        margin-left: 0.25rem;
                        grid-auto-columns: span 12 / span 12;
                        color: @color_qidigo_bleu;
                        @media (min-width: @md) {
                            bottom: 2rem;
                            margin-left: 1rem;
                        }
                        @media (max-width: @md) {
                            top: 100%;
                            margin-left: -5.25rem;
                            bottom: 0;
                            margin-bottom: 1rem;
                        }

                        @media (max-width: @sm) {
                            bottom: 2rem
                        }
                    }
                }

                // contactCreditPopup.tsx
                .contact-credit-popup {
                    position: fixed;
                    z-index: 1;
                    padding-top: 100px;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    overflow: auto;
                    background-color: rgb(0,0,0);
                    background-color: rgba(0,0,0,0.4);

                    .contact-credit-popup--content {
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        width: 100%;
                        transform: translate(-50%, -50%);
                        border-radius: 5px;
                        border: 0.5px solid @color_container_border;
                        animation: popupAnimation 0.5s;
                        animation-direction: normal;
                        background-color: @color_blanc;

                        @media (min-width: @md) {
                            width: -moz-max-content;
                            width: max-content;
                        }
                    }

                    @keyframes popupAnimation {
                        0%   {left: 50%; top: 0;}
                        50%  {left: 50%; top: 50%;}
                    }

                    .contact-credit-popup--title {
                        padding-left: 0.50rem;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        border-bottom: 0.1px solid @color_container_border;
                        font-weight: 600;
                        font-size: @text-medium;
                        line-height: 23px;
                    }

                    .contact-credit-popup--x-button {
                        position: absolute;
                        right: 2.5rem;
                        top: 1rem;
                        width: 1rem;
                        height: 1rem;
                        opacity: 0.3;
                    }
                    .contact-credit-popup--x-button:hover {
                        opacity: 1;
                    }
                    .contact-credit-popup--x-button:before, .contact-credit-popup--x-button:after {
                        position: absolute;
                        left: 1rem;
                        content: ' ';
                        height: 1rem;
                        width: 0.15rem;
                        background-color: @qidigo_dark_grey;
                    }
                    .contact-credit-popup--x-button:before {
                        transform: rotate(45deg);
                    }
                    .contact-credit-popup--x-button:after {
                        transform: rotate(-45deg);
                    }

                    .contact-credit-popup--row {
                        display: grid;
                        grid-auto-flow: row;
                        margin-bottom: 1.75rem;
                        grid-template-columns: repeat(12, minmax(0, 1fr));
                    }

                    .contact-credit-popup--input-container {
                        grid-column: span 12 / span 12;

                        @media (min-width: @md) {
                            grid-column: span 7 / span 7;
                        }
                    }

                    .contact-credit-popup--input {
                        margin: 0;
                    }

                    .contact-credit-popup--button {
                        align-items: center;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                        width: 100%;
                    }

                    .contact-credit-popup--close-button {
                        align-items: center;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                        background-color: @color_gris0;
                        color: rgb(0, 0, 0);
                        font-weight: 400;
                        border: solid 0.10rem @color_grismoyen;
                    }

                    .contact-credit-popup--close-button:hover {
                        background-color: @color_grismoyen;
                    }

                    .contact-credit-popup--adjust-button {
                        align-items: center;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                    }

                    .contact-credit-popup--max-button-container {
                        display: inline-grid;
                        align-items: end;
                        grid-column: span 12 / span 12;

                        @media (min-width: @md) {
                            grid-column: span 2 / span 2;
                            margin-left: 0.50rem;;
                        }

                        @media (max-width: @md) {
                            margin-top: 0.50rem;
                        }
                    }

                    .contact-credit-popup--remove-button-container {
                        display: inline-grid;
                        align-items: end;
                        grid-column: span 12 / span 12;

                        @media (min-width: @md) {
                            grid-column: span 3 / span 3;
                            margin-left: 0.50rem;;
                        }

                        @media (max-width: @md) {
                            margin-top: 0.25rem;
                        }
                    }

                    .contact-credit-popup--form {
                        padding: 1.25rem;
                    }

                    .contact-credit-popup--submit-row {
                        display: flex;
                        justify-content: space-between;
                    }

                    .contact-credit-popup--warning-div {
                        height: 0;

                        @media (max-width: @md) {
                            margin-bottom: 1.5rem
                        }
                    }

                    .contact-credit-popup--warning {
                        font-size: @text-small;
                        line-height: 1.25rem;
                        color: @color_error;
                    }
                }
            }
        }

        //payment.tsx
        .payment-container {
            .payment-title {
                font-size: @text-large;
                line-height: @line-height-x-large;
                color: @color_qidigo_bleu;
                padding-top: 1.8rem;
                padding-bottom: 1.8rem;
            }

            .payment-section {
                margin-bottom: 2rem;
            }

            .payment--choose-an-option-title {
                padding-top: 10px;
                padding-bottom: 30px;
                font-size: @text-large;
                line-height: @line-height-x-large;
                color: @color_qidigo_bleu;
            }

            //paymentTimeList.tsx
            .payment-time-list {
                display: flex;

                @media(max-width: @sm) {
                    flex-direction: column;
                }

                .payment-time-list--items {
                    display: flex;
                    align-items: center;
                    margin-left: 0;
                    margin-right: 2.2rem;

                    @media(max-width: @sm) {
                        margin-bottom: 1.5rem;
                    }

                    .payment-time--radio-button {
                        width: 1.5rem;
                        height: 1.5rem;
                        margin-right: 0.25rem;
                        accent-color: @color_qidigo_bleu;

                        @media(max-width: @sm) {
                            margin-right: 1rem;
                        }
                    }
                }
            }

            //paymentMethodList.tsx
            .payment-methods--list {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                column-gap: clamp(1vw, 3vw, 5vw);
                row-gap: @gutter / 2;
                justify-content: flex-start;
                list-style-type: none;

                .payment-methods-list--item {
                    margin-left: 0;

                    .payment-methods-list--icon {
                        margin-right: 0.5rem;
                        height: 1rem;
                    }

                    .payment-methods-list--item-button {
                        display:flex;
                        width: 100%;
                        align-items: center;
                        padding: 0.5rem 1.5rem;
                        @media (min-width: @md) {
                            width: max-content;
                        }

                        &.selected {
                            color: @color_blanc;
                            background-color: @color_qidigo_bleu;

                        }

                        &.unselected {
                            background-color: @color_blanc;
                            border: 1px solid @color_qidigo_bleu;
                            color: @color_police;
                        }
                    }
                }
            }

            //paymentAddressList.tsx
            .payment-addresses-list {

                .payment-addresses-list--title {
                    font-weight: bold;
                    font-size: @text-large;
                    line-height: @line-height-large;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }

                .payment-addresses-list--list {
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    gap: 2rem;
                    list-style-type: none;

                    @media (max-width: @sm) {
                        grid-template-columns: repeat(1, minmax(0, 1fr));
                    }

                    @media (min-width: @md) {
                        -moz-column-gap: 4.5rem;
                        column-gap: 4.5rem;
                        grid-template-columns: repeat(4, minmax(0, 1fr));
                    }

                    @media (min-width: 1280px) {
                        -moz-column-gap: 6rem;
                        column-gap: 6rem;
                    }
                }

                .payment-addresses-list--other-address-button {
                    text-decoration-line: underline;
                    color: @color_qidigo_bleu;
                }

                .payment-addresses-list--existing-address-button {
                    text-decoration-line: underline;
                    color: @color_qidigo_bleu;
                    margin-bottom: 1rem;
                }

                //paymentAddress.tsx
                .payment-address {
                    margin-left: 0;
                    cursor: pointer;

                    .payment-address--container {
                        display: inline-block;
                        height: 100%;
                        width: 100%;
                    }

                    .payment-address--box {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                        border: 1px solid;
                        border-radius: 0.5rem;
                    }

                    .payment-address--border-selected {
                        border-color: @color_qidigo_bleu;
                    }

                    .payment-address--border-not-selected {
                        border-color: @checkout-box-background;
                    }

                    .payment-address-address-box-title {
                        padding-top: 0.25rem;
                        padding-bottom: 0.25rem;
                        border-top-left-radius: 0.25rem;
                        border-top-right-radius: 0.25rem;
                        text-align: center;
                    }

                    .payment-address--address-box-selected {
                        background-color: @color_qidigo_bleu;
                        color: @color_blanc
                    }

                    .payment-address--address-box-not-selected {
                        background-color: @checkout-box-background
                    }

                    .payment-address--formatted-address {
                        padding: 0.5rem;
                    }

                    .payment-address--radio-button-container {
                        display: flex;
                        justify-content: center;
                        padding-top: 0.25rem;
                        padding-bottom: 0.25rem;
                        border-bottom-left-radius: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                        text-align: center;
                    }

                    .payment-address--radio-button {
                        accent-color: @color_qidigo_bleu;
                        align-items: center;
                        padding-top: 0.25rem;
                        padding-bottom: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                        border-bottom-left-radius: 0.25rem;
                        margin-right: 0.25rem;
                    }
                }

                //paymentCustomAddressForm.tsx
                .payment-address--custom-form {

                    .payment-address--custom-form-container {
                        display: grid;
                        grid-template-columns: repeat(6, minmax(0, 1fr));
                        row-gap: 0.75rem;
                        column-gap: 1rem;
                        margin-bottom: 1rem;
                    }

                    .payment-address--custom-form-title {
                        grid-column: span 6 / span 6;
                        padding-top: 1rem;
                        font-size: 1.5rem;
                        line-height: 2rem;
                        font-weight: 700;
                    }

                    .payment-address--custom-form-address-input-container {
                        grid-column: span 5 / span 5;

                        @media (max-width: @md) {
                            grid-column: span 6 / span 6;
                            height: 3.75rem;
                        }
                    }

                    .payment-address--custom-form-app-input-container {
                        grid-column: span 1 / span 1;

                        @media (max-width: @md) {
                            grid-column: span 6 / span 6;  }
                    }

                    .payment-address--custom-form-country-input-container {
                        grid-column: span 3 / span 3;

                        @media (max-width: @md) {
                            grid-column: span 6 / span 6;
                        }
                    }

                    .payment-address--custom-form-state-input-container {
                        grid-column: span 3 / span 3;

                        @media (max-width: @md) {
                            grid-column: span 6 / span 6;
                            margin-top: 1.25rem;
                            margin-bottom: 1.25rem;
                        }
                    }

                    .payment-address--custom-form-postal-input-container {
                        grid-column: span 3 / span 3;

                        @media (max-width: @md) {
                            grid-column: span 6 / span 6;
                        }
                    }

                    .payment-address--custom-form-city-input-container {
                        grid-column: span 3 / span 3;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        @media (max-width: @md) {
                            grid-column: span 6 / span 6;
                        }
                    }

                    .payment-address--custom-form-postal-input {
                        justify-content: start;
                    }

                    .payment-address--custom-form-warning-border {
                        border-color: @color_qidigo_rouge;
                    }

                    .payment-address--custom-form-border {
                        border-color: @color_container_border;
                    }

                    .payment-address--custom-form-save-address-container {
                        margin-bottom: 1rem;
                        display: flex;
                        align-items: center;
                    }
                }
            }

            //paymentInstallmentList.tsx
            .payment-installments--list {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                grid-template-rows: minmax(15, 25rem);
                gap: 2rem;
                list-style-type: none;

                @media (max-width: @sm) {
                    -moz-column-gap: 4.5rem;
                    column-gap: 4.5rem;
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                }

                @media (min-width: @md) {
                    -moz-column-gap: 4.5rem;
                    column-gap: 4.5rem;
                    grid-template-columns: repeat(4, minmax(0, 1fr));
                }

                @media (min-width: 1280px) {
                    -moz-column-gap: 6rem;
                    column-gap: 6rem;
                }

                //paymentInstallment.tsx
                .payment-installments {
                    margin-left: 0;

                    .payment-installments--box {
                        height: 100%;
                        width: 100%;
                        position: relative;
                        cursor: pointer;
                    }

                    .payment-installments--selected-container {
                        display: flex;
                        flex-direction: column;
                        align-content: initial;
                        height: 100%;
                        border: 1px solid @color_qidigo_bleu;
                        border-radius: 0.5rem;
                    }

                    .payment-installments--not-selected-container {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        border: 1px solid @checkout-box-background;
                        border-radius: 0.5rem;
                    }

                    .payment-installments--selected-text {
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        border-top-left-radius: 0.25rem;
                        border-top-right-radius: 0.25rem;
                        text-align: center;
                        background-color: @color_qidigo_bleu;
                        color: @color_blanc;
                    }

                    .payment-installments--not-selected-text {
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        border-top-left-radius: 0.25rem;
                        border-top-right-radius: 0.25rem;
                        text-align: center;
                        justify-content: end;
                        background-color: @checkout-box-background
                    }

                    .payment-installment--option-list {
                        padding-left: 1rem;
                        padding-right: 1rem;
                        text-align: center;
                    }

                    .payment-installment--instalment-payment {
                        list-style-type: none;
                        padding: 0.25rem;
                        margin-left: 0;
                        gap: 0.5rem;
                        border-width: 1px;
                        border-bottom-width: 2px;
                        border-color: @color_container_border;
                        font-size: @text-small;
                        line-height: 1.25rem;
                    }

                    .payment-installment--initial-payment{
                        list-style-type: none;
                        margin-left: 0;
                        font-size: @text-small;
                        line-height: 1.25rem;
                    }

                    .payment-installment--initial-payment-underline {
                        border-style: solid;
                        border-bottom-width: 2px;
                        border-color: @color_container_border;
                    }

                    .payment-installments--initial-payment-text {
                        margin-top: 0.5rem;
                        margin-bottom: 0.5rem;
                        font-weight: 700;
                    }

                    .payment-installments--rest-payment-text {
                        margin-bottom: 0.5rem;
                        font-weight: 700;
                    }

                    .payment-installments--initial-payment-amount {
                        padding-bottom: 0.75rem;
                    }

                    .payment-installment--rest-payment{
                        list-style-type: none;
                        margin-left: 0;
                        margin-top: 0.75rem;
                        margin-bottom: 0.75rem;
                        gap: 0.5rem;
                        border-width: 1px;
                        border-bottom-width: 2px;
                        border-color: @color_container_border;
                        font-size: @text-small;
                        line-height: 1.25rem;
                    }

                    .payment-installments--over-limit-container {
                        margin-bottom: 0.5rem;
                    }

                    .payment-installments--over-limit-button-container {
                        text-align: center;
                    }

                    .payment-installments--over-limit-button {
                        text-align: center;
                        text-decoration-line: underline;
                        color: @color_qidigo_bleu;
                        font-size: @text-small;
                        line-height: 1.25rem;
                    }

                    .payment-installments--radio-button-container{
                        display: flex;
                        margin-block-start: auto;
                        width: 100%;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                        border-bottom-right-radius: 0.25rem;
                        border-bottom-left-radius: 0.25rem;
                        align-items: center;
                        justify-content: center;
                    }

                    .payment-installments--radio-button-selected {
                        background-color: @color_qidigo_bleu;
                        color: @color_blanc;
                    }

                    .payment-installments--radio-button-not-selected {
                        background-color: @checkout-box-background;
                    }

                    .payment-installments--radio-button {
                        margin-right: 0.25rem;
                        accent-color: @color_qidigo_bleu;
                    }

                    //installmentPaymentsPopup.tsx
                    .installment-payments-popup {
                        cursor: default;
                        position: fixed;
                        z-index: 1;
                        padding-top: 100px;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        overflow: auto;
                        background-color: rgb(0,0,0);
                        background-color: rgba(0,0,0,0.4);

                        .installment-payments-popup--container {
                            position: fixed;
                            top: 50%;
                            left: 50%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                            border-radius: 5px;
                            border: 0.5px solid @color_container_border;
                            animation: popupAnimation 0.5s;
                            animation-direction: normal;
                            background-color: @color_blanc;
                            padding: 1rem;

                            @media (min-width: @md) {
                                width: -moz-max-content;
                                width: max-content;
                            }

                            .installment-payments-popup--name {
                                margin-bottom: 1rem;
                                padding-bottom: 1rem;
                                border-style: solid;
                                border-bottom-width: 2px;
                                border-color: @color_container_border;
                                font-size: 1.125rem;
                                line-height: 1.75rem;
                                font-weight: 700;
                            }

                            .installment-payments-popup--x-button {
                                position: absolute;
                                right: 2.5rem;
                                top: 1rem;
                                width: 1rem;
                                height: 1rem;
                                opacity: 0.3;
                            }
                            .installment-payments-popup--x-button:hover {
                                opacity: 1;
                            }
                            .installment-payments-popup--x-button:before, .installment-payments-popup--x-button:after {
                                position: absolute;
                                left: 1rem;
                                content: ' ';
                                height: 1rem;
                                width: 0.15rem;
                                background-color: @qidigo_dark_grey;
                            }
                            .installment-payments-popup--x-button:before {
                                transform: rotate(45deg);
                            }
                            .installment-payments-popup--x-button:after {
                                transform: rotate(-45deg);
                            }

                            .installment-payments-popup--initial-payment-title {
                                margin-bottom: 1rem;
                            }

                            .installment-payments-popup--upcoming-payments-title {
                                font-weight: bold;
                            }

                            .installment-payments-popup--upcoming-payments-grid {
                                padding-top: 1rem;
                                padding-bottom: 1rem;
                                display: grid;
                                grid-template-columns: repeat(4, minmax(0, 1fr));
                                grid-auto-flow: row;
                                gap: 1rem;
                                @media(max-width: @md) {
                                    grid-template-columns: repeat(2, minmax(0, 1fr));
                                }

                                .installment-payments-popup--upcoming-payments-item {
                                    grid-column: span 1 / span 1;
                                    display: flex;
                                    margin-left: 0;

                                    .installment-payments-popup--upcoming-payments-item-date {
                                        padding: 0.5rem;
                                        border: 1px solid @color_container_border;
                                        border-top-left-radius: 0.25rem;
                                        border-bottom-left-radius: 0.25rem;
                                    }

                                    .installment-payments-popup--upcoming-payments-item-amount {
                                        padding: 0.5rem;
                                        border: 1px solid @color_container_border;
                                        background-color: @checkout-box-background;
                                        border-top-right-radius: 0.25rem;
                                        border-bottom-right-radius: 0.25rem;
                                    }
                                }
                            }

                            .installment-payments-popup--close-button {
                                text-align: right;
                            }
                        }

                        @keyframes popupAnimation {
                            0%   {left: 50%; top: 0;}
                            50%  {left: 50%; top: 50%;}
                        }
                    }
                }
            }

            //payButton.tsx
            .pay-button--container {
                text-align: right;
                .pay-button--button {
                    padding: 0.5rem 1rem;
                    border-radius: 0.25rem;
                    color: @color_blanc;
                }
            }

            //customForm.tsx
            .custom-form--button-container {
                text-align: right;
            }

            .direct-debit-form--title,
            .credit-card-form--title {
                padding-top: 1rem;
                font-size: 1.5rem;
                line-height: 2rem;
                font-weight: 700;
            }

            .credit-card-form--info-button-container,
            .direct-debit-form--info-button-container {
                display: flex;
                align-items: center;
                margin-top:  1rem;
                margin-bottom: 1rem;
            }

            .credit-card-form--info-button-image,
            .direct-debit-form--info-button-image {
                margin-right: 0.25rem;
                height: 1.5rem;
            }

            .credit-card-form--info-button,
            .direct-debit-form--info-button {
                text-decoration-line: underline;
                color: @color_qidigo_bleu;
            }

            .credit-card-form--info,
            .direct-debit-form--info {
                margin-bottom: 1rem;
            }

            .credit-card-inputs {
                .credit-card-inputs--warning {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    color: @color_error;
                }

                .credit-card-inputs--inputs {
                    display: grid;
                    grid-template-columns: repeat(12, minmax(0, 1fr));
                    gap: 0.5rem;
                    margin-bottom: 2.5rem;
                    @media (max-width: @md) {
                        -moz-column-gap: 1.25rem;
                        column-gap: 1.25rem;
                    }

                    .credit-card-inputs--inputs-card-number-container {
                        grid-column: span 12 / span 12;
                        @media (min-width: @md) {
                            grid-column: span 6 / span 6;
                        }

                        .credit-card-inputs--inputs-card-number-input {
                            border: 1px solid @color_container_border;
                            border-radius: 0.25rem;
                            height: 2.5rem;
                            width: 100%;
                            padding: 0.5rem 1rem;
                        }

                        .StripeElement--focus {
                            border: 1px solid #27708e;
                            box-shadow: 0 0 0.57142857rem #27708e;
                        }
                    }

                    .credit-card-inputs--inputs-icons-container {
                        grid-column: span 12 / span 12;
                        display: none;

                        @media (min-width: @lg) {
                            grid-column: span 2 / span 2;
                            display: block;
                        }

                        .credit-card-inputs--inputs-icons-container-container {
                            display: flex;

                            .credit-card-inputs--inputs-icons-icon {
                                margin-right: 0.25rem;
                                height: 2.5rem;
                            }
                        }
                    }

                    .credit-card-inputs--inputs-expiration-date-container {
                        grid-column: span 12 / span 12;

                        @media (min-width: @md) {
                            grid-column: span 4 / span 4;
                        }

                        @media (min-width: @lg) {
                            grid-column: span 2 / span 2;
                        }

                        .credit-card-inputs--inputs-expiration-date-input {
                            padding: 0.5rem 1rem;
                            width: 100%;
                            height: 2.5rem;
                            border: 1px solid @color_container_border;
                            border-radius: 0.25rem;
                        }

                        .StripeElement--focus {
                            border: 1px solid #27708e;
                            box-shadow: 0 0 0.57142857rem #27708e;
                        }
                    }

                    .credit-card-inputs--inputs-cvv-container {
                        grid-column: span 12 / span 12;
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        @media (min-width: @md) {
                            grid-column: span 2 / span 2;
                        }

                        .credit-card-inputs--input-cvv-info-image-container {
                            display: flex;
                            flex-direction: row-reverse;
                        }

                        .credit-card-inputs--input-cvv-info-label {
                            position: relative;
                        }

                        .credit-card-inputs--cvv-info-image {
                            position: relative;
                            right: 0.75rem;
                            margin-top: -1.25rem;
                            height: 1rem;
                        }

                        .credit-card-inputs--cvv-info-image:hover {
                            cursor: pointer;
                        }

                        .credit-card-inputs--cvv-info-popup {
                            position: relative;
                            display: contents;
                            cursor: pointer;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                        }

                        /* The actual popup */
                        .credit-card-inputs--cvv-info-popup .credit-card-inputs--cvv-info-popup-text {
                            width: 100%;
                            background-color: @color_blanc;
                            text-align: center;
                            border: 1px solid @color_gris2;
                            border-radius: 0.5rem;
                            padding: 1rem;
                            position: absolute;
                            z-index: 1;
                            bottom: 125%;
                        }

                        /* Popup arrow */
                        .credit-card-inputs--cvv-info-popup .credit-card-inputs--cvv-info-popup-text::after {
                            content: "";
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            z-index: 1;
                            margin-left: -0.5rem;
                            border: 0.5rem solid @color_gris2 transparent transparent transparent;
                        }

                        .credit-card-inputs--inputs-cvv-input {
                            padding: 0.5rem 1rem;
                            width: 100%;
                            height: 2.5rem;
                            border: 1px solid @color_container_border;
                            border-radius: 0.25rem;
                        }

                        .StripeElement--focus {
                            border: 1px solid #27708e;
                            box-shadow: 0 0 0.57142857rem #27708e;
                        }
                    }
                }
            }

            //paysafeDirectDebitForm.tsx
            .paysafe-direct-debit-form {

                .paysafe-direct-debit-form--warning-text {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    color: @color_error;
                }

                .paysafe-direct-debit-form--container {
                    display: grid;
                    grid-template-columns: repeat(12, minmax(0, 1fr));
                    grid-auto-flow: row;
                    gap: 0.75rem;
                    margin-bottom: 2.5rem;

                    @media (max-width: @md) {
                        -moz-column-gap: 1rem;
                        column-gap: 1rem;
                    }
                }

                .paysafe-direct-debit-form--transit-number-container {
                    grid-column: span 12 / span 12;

                    @media (min-width: @md) {
                        grid-column: span 3 / span 3;
                    }
                }

                .paysafe-direct-debit-form--transit-number-label {
                    position: relative;
                    top: 1.40rem;
                }

                .paysafe-direct-debit-form--institution-number-container {
                    grid-column: span 12 / span 12;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 0.80rem;

                    @media (min-width: @md) {
                        grid-column: span 4 / span 4;
                    }
                }

                .paysafe-direct-debit-form--institution-number-container-with-errors {
                    grid-column: span 12 / span 12;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-top: 1.50rem;

                    @media (min-width: @md) {
                        grid-column: span 4 / span 4;
                    }
                }

                .paysafe-direct-debit-form--institution-number-error-container {
                    height: 0.75rem;
                }

                .paysafe-direct-debit-form--institution-number-error-text {
                    height: 0.75rem;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    color: @color_error;
                }

                .paysafe-direct-debit-form--institution-number_menus {
                    z-index: 9999 !important;
                }

                .paysafe-direct-debit-form--account-container {
                    grid-column: span 12 / span 12;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    @media (min-width: @md) {
                        grid-column: span 3 / span 3;
                    }
                }

                .paysafe-direct-debit-form--account-number-label {
                    position: relative;
                    top: 1.40rem;
                }

                .paysafe-direct-debit-form--account-info-image-container {
                    display: flex;
                    flex-direction: row-reverse;
                }

                .paysafe-direct-debit-form--account-info-image {
                    position: relative;
                    right: 0.75rem;
                    margin-top: -1.25rem;
                    height: 1rem;
                }

                .paysafe-direct-debit-form--account-info-image:hover {
                    cursor: pointer;
                }

                .paysafe-direct-debit-form--account-info-popup {
                    position: relative;
                    display: contents;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                /* The actual popup */
                .paysafe-direct-debit-form--account-info-popup .paysafe-direct-debit-form--account-info-popup-text {
                    width: 25rem;
                    background-color: @color_blanc;
                    text-align: center;
                    border: 1px solid @color_gris2;
                    border-radius: 0.5rem;
                    padding: 1rem;
                    position: absolute;
                    z-index: 1;
                    bottom: 125%;
                    left: 35%;
                    margin-left: -80px;

                    @media(max-width: @sm) {
                        width: 15rem;
                    }
                }

                /* Popup arrow */
                .paysafe-direct-debit-form--account-info-popup .paysafe-direct-debit-form--account-info-popup-text::after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    z-index: 1;
                    margin-left: -0.5rem;
                    border: 0.5rem solid @color_gris2 transparent transparent transparent;
                }

                .paysafe-direct-debit-form--account-type-container {
                    grid-column: span 12 / span 12;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 0.80rem;

                    @media (min-width: @md) {
                        grid-column: span 2 / span 2;
                    }
                }

                .paysafe-direct-debit-form--account-type-container-with-errors {
                    grid-column: span 12 / span 12;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-top: 1.50rem;

                    @media (min-width: @md) {
                        grid-column: span 2 / span 2;
                    }
                }
            }
        }

        .div--checkbox-contact-mailing-list {
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            float: left;
            @media (min-width: @md) {
                margin-top: -4%;
            }

            @media (max-width: @md) {
                margin-top: -10%;
            }
        }
    }

    .caret {
        border-radius: 1px;
        border: solid @color_qidigo_bleu;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        margin-left: 7px;
    }

    .up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-bottom: 3px;
    }

    .money {
        font-weight: bold;
    }

    .button-link {
        border: 0;
        #debuttonize();
        color: @color_qidigo_bleu !important;
        text-decoration-line: underline;
    }

    .span--coupon-error {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: @color_error;
    }
}
