.dashboard-rl24 {
	.recipient-card {
		#card();

		&.with-errors {
			border-color: @color_qidigo_rouge;
			h3::before {
				#__icon-self(warning, "red", @color_qidigo_rouge);
				display: inline-block;
				content: "";
				height: 1rem;
				width: 1rem;
				margin-right: 0.5rem;
				background-size: contain;
				vertical-align: middle;
			}
		}
	}

	.eligible-items {
		ul, ul > li {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}

		h3 {
			margin-top: @gutter;
			margin-bottom: @gutter/2;
		}

		.checkbox {
			label {
				margin: 0;
				padding: 0;
				margin-top: 1/14rem;
			}
			float: left;
		}

		h4 {
			margin-bottom: 0.2em;
		}

		h4, .item-details, .item-splits {
			margin-left: @gutter/2 + @qidigo_checkbox_diameter;
		}

		.items-list {
			clear: both;

			& > li {
				padding-top: @gutter/2;
				padding-bottom: @gutter/2;

				&:not(:first-child) {
					border-top: 1/14rem solid @color_gris2;
				}
			}

			.item-details {
				display: flex;
				flex-wrap: wrap;

				.detail-total {
					text-align: right;
				}

				li:not(:last-child) {
					padding-right: @gutter;
				}

				#screen-md-min({
					.detail-organization { width: 30%; }
					.detail-invoice      { width: 20%; }
					.detail-name         { width: 35%; }
					.detail-total        { width: 15%; }
				});
			}
		}
	}

	.eligible-item-link {
		display: flex;
		flex-direction: column;
		margin-top: @gutter;
		margin-bottom: @gutter;

		#screen-sm-max({
			> * {
				margin: 0;
				&:not(:first-child) {
					margin-top: @gutter/2;
				}

				&:not(:last-child) {
					margin-bottom: @gutter/2;
				}
			}
		});

		#screen-md-min({
			flex-direction: row;
			> * {
				margin: 0;

				&:not(:first-child) {
					margin-left: @gutter/2;
				}

				&:not(:last-child) {
					margin-right: @gutter/2;
				}
			}
		});

		.input--percent-of {
			min-width: 20rem;
		}
	}
}
.dashboard-recipient {
	form {
		.form-row {
			#screen-xs-min({
				display: flex;
				margin-top: @gutter;
				margin-bottom: @gutter;
				& > * {
					margin-top: 0;
					margin-bottom: 0;
					&:not(:last-child) {
						margin-right: @gutter/2;
					}
					&:not(:first-child) {
						margin-left: @gutter/2;
					}
				}
			});
		}
	}
}
