section.page-messages {
	h1 { #no-select(); }
	
	// Utilise un side-nav
	#side-nav();
	#side-nav.specialize(new, compose);
	#side-nav.specialize(inbox, inbox);
	#side-nav.specialize(trash, delete);
	#side-nav.specialize(sent, sent);
	#side-nav.specialize(drafts, drafts);

	@media print {
		&>.side-nav {
			display: none;
		}
		margin-left: 0;
	}

	// Retire le padding de la page... (migrer dans side-nav?)
	padding: 0;

	&>nav {
		// Assure que l'option de menu n'est pas collée sur le badge.
		// (Assum un span react, nav>ul>li>a>span
		li>a>:first-child::after {
			content: " ";
			display: inline;
		}
		// Assure un badge blanc sur rouge
		.badge {
			background-color: @color_qidigo_rouge;
			color: @color_blanc;
		}
	}

	// La "page"
	&>section {
		.section-box;
	}

	// On veut un minimum height agréable
	.messages--child>.loading {
		padding-top:    4rem;
		padding-bottom: 4rem;
	}
}

//
// Messages listing
// ================
//

.messages-list {
	// Présente la la liste comme une listtable
	#listtable();

	// La liste doit se coller aux bords de l'écran.
	#screen-xs-max({
		&.is-not-empty {
			margin-left:  -@gutter/2;
			margin-right: -@gutter/2;
		}
	});

	.message-item {
		//clear: both;
		position: relative;
	}
	.message-item {
		#screen-sm-max({
			@_image_size: @base_line_height * 2.5;
			.fake-image {
				background: #fff;
				border: 1/14rem solid @color_grismoyen;
				border-radius: @size_general_radius;
				display: block;
				float: left;
				// Retire le border vu que fake-image est en box-sizing content-box.
				width:  @_image_size - 2/14rem;
				height: @_image_size - 2/14rem;
				//position: absolute;
				//vertical-align: text-bottom;
				margin-right: @gutter/2;
			}
			// &.with-avatar {
				&>* {
					padding-left: @_image_size + @gutter/2 + @gutter/2;
				}
			// }

			.message-item--author, .message-item--recipients {
				padding-left: @gutter/2;
				font-size: @size_courant;
				#font-secondary(400);
			}
			.message-item--date {
				display: none;
			}
			#self-clear();
			padding-bottom: @gutter/2;
		});
		#screen-md-min({
			.fake-image {
				display: inline-block;
				vertical-align: text-bottom;
				width:  @base_line_height;
				height: @base_line_height;
				margin-right: @gutter/4;
			}
		});
	}
	.message-item.is-not-read {
		.message-item--subject {
			font-weight: bold;
		}
		#screen-md-min({
			font-weight: bold;
		});
	}
	.message-item.has-attachment {
		.message-item--subject a {
			&::before {
				#__icon-self(attachment, "black", @color_police);
				background-position: center center;
				background-size: contain;
				display: inline-block;
				content: "";
				width:  @base_line_height * 0.7;
				height: @base_line_height * 0.7;
				vertical-align: text-bottom;
				margin-right: 0.5ex;
			}
		}
	}

	//
	// Spécialisations de colonnes
	//
	ul>li>ul {
		&>li {
			vertical-align: top;
		}
		&>li.message-item--checkbox {
			#screen-md-min({
				width: 0%;
			});
		}
		&>li.message-item--date {
			white-space: nowrap;
			#screen-md-min({
				text-align: right;
			});
		}
		&>li.message-item--author, &>li.message-item--recipients {
			#screen-md-min({
				// Child items are nowrapped.
				&>* {
					white-space: nowrap;
				}
			});
		}
		&>li .message-item--plus {
			#badge();
			background-color: @color_gris1;
			color: @color_grisfonce;
			margin-left: 1em;
			// Remove some padding because of the weight of the +
			padding-left: 0.3em;
		}
		&>li.message-item--subject {
			width: 100%;
			#screen-sm-max({
				a {
					width: 100%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					// Remove when/if we add a body text preview.
					// This makes the subject line's link expand
					// to have a bigger hit target.
					padding-bottom: 1rem;
				}
			});
			#screen-md-min({
				max-width: 0;
				width: 100%;
				&>* {
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 100%;
					width: 100%;
					white-space: nowrap;
				}
			});
		}
		&>li.message-item--date {
			#screen-md-min({
				width: 0%;
				white-space: nowrap;
			});
		}
	}

	//
	// Checkboxes
	// ----------
	//
	&>ul>li {
		// Les checkboxes sont plus grandes qu'apparentes...
		&>ul>li> .checkbox {
			// PREND POUR ACQUIS UNE STRUCTURE SPÉCIFIQUE.
			&>label {
				#screen-md-min({
					margin:  -@gutter/2;
					padding:  @gutter/2;
				});
			}
			label {
				position: relative;
			}
			input {
				position: absolute;
				width: 1px;
				height: 1px;
			}
			.checkbox--fake {
				margin-right: 0;
			}
		}
	}

	// Lorsqu'étroit, la Checkbox couvre tout l'avatar.
	#screen-sm-max({
		.message-item--author, .message-item--recipients {
			padding-top: @gutter/2;
		}
		ul>li>ul>li.message-item--checkbox {
			&:first-child, &:not(:first-child) {
				padding:0;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 10;
				label {
					// Cover the image
					@_image_size: @base_line_height * 2.5 - @gutter / 3;
					margin: 0;
					padding: @gutter / 3;
					padding-right: @_image_size;
					padding-bottom: @_image_size;
				}
			}
		}
	});

	.form-buttons-bar {
		margin-top: @gutter;
	}
}

//
// Quand il n'y a rien...
//

.messages-list .messages-list--nothing {
	#empty_box();
	// Si on veut ajouter de quoi après le texte.
	// &::after {
	// 	content: " :) ";
	// 	display: block;
	// 	padding: 1em;
	// 	padding-bottom: 0;
	// }
}

//
// Vue d'*un* message.
//
.messages-view {
	.message-view--metadata {
		padding-bottom: @gutter/2;
		margin-bottom: @gutter/2;

		// Assure que le clear suivant ne clear pas le layout autour.
		overflow: auto;
		#self-clear();

		@_image_size: 3.5rem;

		&>* {
			padding-left: @_image_size + @gutter/2;
		}

		.avatar-image {
			padding: 0;
			background: #fff;
			border: 1/14rem solid @color_grismoyen;
			border-radius: @size_general_radius;
			display: block;
			float: left;
			width:  @_image_size;
			height: @_image_size;
			//position: absolute;
			//vertical-align: text-bottom;
			margin-right: @gutter/2;
		}
	}
	.message-view--date {
		font-size: @size_courant;
		color: @color_grisfonce;
		margin-left: 1ex;
	}
	.message-contents {
		border: 1/14rem solid @color_grismoyen;
		border-radius: @size_general_radius;
		padding: @gutter/2;
		&>*>*:last-child {
			margin-bottom: 0;
		}
		margin-bottom: @gutter;
	}

	@media print {
		.form-buttons-bar {
			display: none;
		}
	}

	.attachment-item {
		display: inline;
		form, input, button { display: inline; }
		button, input {
			#base_focus();
			-webkit-appearance: none;
			-moz-appearance: none;
			text-align: inherit;
			appearance: none;
			background: transparent;
			border: 0;
			padding: 0;
			margin: 0;
			line-height: inherit;
			color: @color_qidigo_bleu;
		}
	}
}

//
// Styles communs pour l'affichage d'un message.
//
.message-contents>.message-contents--inner {
	h1 { font-size: 2.0rem; }
	h2 { font-size: 1.6rem; }
	h3 { font-size: 1.3rem; }
	h4 { font-size: 1.2rem; }
	h5 { font-size: 1.1rem; }
	h6 { font-size: 1.1rem; }

	a { text-decoration: underline; }

	blockquote {
		margin: @gutter/2 0;
		border-left: @gutter/2 solid @color_gris1;
		padding: @gutter/2;
		padding-right: 0;
	}
}
