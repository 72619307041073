//
// List Table
// ==========
//
// Component responsive de table qui devient "list-like" lorsqu'étroit.
//
// Par exemple : Liste de messages.
//
// Structure:
//
//     <ul>              <-- table
//         <li>          <-- tbody
//             <ul>      <-- tr
//                 <li>  <-- td
//             <ul>      <-- tr
//                 <li>  <-- td
//

#listtable() {
	&:extend(#_listtable-base all);
	&:extend(#_listtable-presentation all);
}

// Structure

#listtable-base {
	&:extend(#_listtable-base all);
}

#_listtable-base {
	// ATTENTION, tout le DOM doit être table-like sinon ça brise.

	#screen-md-min({
		&>ul { display: table; }
		&>ul>li { display: table-row-group; }
		&>ul>li>ul { display: table-row; }
		&>ul>li>ul>li { display: table-cell; }
		&>ul>li.header { display: table-header-group }
		&>ul>li.footer { display: table-footer-group }
		&>ul {
			width: 100%;
		}
	});

	&>ul>li,
	&>ul>li>ul>li {
		list-style-type: none;
	}
	&>ul,
	&>ul>li,
	&>ul>li>ul,
	&>ul>li>ul>li {
		margin: 0;
		padding: 0;
	}

	#screen-sm-max({
		&>ul>li.header {
			display:none;
		}
	});
	
	&.is-sortable {
		&>ul>li.header {
			&>ul>li {
				cursor: pointer;
			}
		}
	}
	&>ul>li.header {
		&>ul>li {
			#no-select();
		}
	}
	&>ul>li {
		&>ul>li {
			padding: 0 @gutter/2;

			&:first-child{ padding-top:   @gutter/2; }
			&:last-child{ padding-bottom: @gutter/2; }

			#screen-md-min({
				padding: @gutter/2;
			});

			&>* {
				display: block;
			}
			&>a {
				#no-select();
				cursor: pointer;
				color: inherit;
				display: block;
				#screen-md-min({
					margin:  -@gutter/2;
					padding:  @gutter/2;
				});
				&:hover, &:focus, &:active, &.active {
					text-decoration: none;
				}
			}
		}

    }
}

// Presentation part

#listtable-presentation() {
	&:extend(#_listtable-presentation all);
}

#_listtable-presentation {
	
	&>ul>li:not(.header) {
		&>ul {
			// Alternating row colors
			&:nth-child(odd) {
				background-color: @color_background;
				&:hover {
					background: darken(@color_background, 3%);
				}
			}
			&:nth-child(even) {
				background: @color_blanc;
				&:hover {
					background: darken(@color_blanc, 3%);
				}
			}
			// Border *only* between
			&:not(:first-child) {
				border-top:1/14rem solid darken(@color_background, 6%);
				#screen-md-min({
					&>li {
						border-top:1/14rem solid darken(@color_background, 6%);
					}
				});
			}
		}
	}
	&>ul>li.header {
		&>ul>li {
			border-bottom:2/14rem solid darken(@color_background, 6%);
			font-weight: bold;
		}
	}
}
