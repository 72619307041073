#header-links() {
	&:extend(#_header-links all);
}
#_header-links {
	#screen-sm-min({
		float: right;
	});
	text-align: right;
	margin-right: -@gutter;

	& > li {
		#no-select();
		display: inline-block;
		margin: 0;
		padding: 0;
		list-style-type: none;
		margin-left: 3/14rem;
		vertical-align: top;

		a {
			position: relative;
			display: block;
			text-align: center;
			background: @color_gris2;
			width: 68/14rem;
			height: 60/14rem;
			padding-top: 40/14rem;
			color: @color_blanc;
			#font-secondary;
			font-size: 0.8em;
			letter-spacing: -1/14rem;
			text-decoration: none;
			&:hover, &:active, &:focus, &.active {
				background: @color_qidigo_bleu;
			}
			&::before {
				display: block;
				content: "";
				position: absolute;
				top: 6/14rem;
				left: 0;
				right: 0;
				width: 60%;
				height: 53%;
				margin: auto;
				background-size: contain;
				background-position: center center;
			}
		}
	}
}
