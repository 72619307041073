body.with-overlay {
	width: 100%;
	position: fixed;
	overflow: hidden;
}

// Backdrop to catch events.
.overlay-layout__background {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1001;
	background: rgba(100, 100, 100, 0.2);
}

.overlay-wrapper {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	max-height: 100vh;
	z-index: 1002;
	margin: auto;
	overflow-y: scroll;

	// The actual layout thing (white box)
	.overlay-layout {
		position: relative;
		border: 1/14rem solid @color_container_border;
		background-color: @color_blanc;
		padding: @gutter;
		margin-top: @gutter;
		margin-bottom: @gutter;

		&, & > .overlay-contents {
			border-radius: @size_general_radius;
			margin-left: auto;
			margin-right: auto;
			.subscription-confirmation-modal-body {
				text-align: left;
			}
			.subscription-confirmation-modal-buttons-bar {
				text-align: right;
			}
		}

		#screen-xs-only({
			max-width: ~"calc(100vw - @{gutter} * 2)";
		});
		#screen-sm-only({
			max-width: @container-sm;
		});
		#screen-md-only({
			max-width: @container-md;
		});
		#screen-lg-only({
			max-width: @container-lg;
		});

		& > .overlay-close.button {
			width: 1.5rem;
			height: 1.3rem;
			line-height: 1rem;
			font-size: 1.5rem;
			position: absolute;
			top: @gutter / 2;
			right: @gutter / 2;
			margin-right: 0;

			&:focus {
				outline: 2px solid @color_secondary;
				color: #ffffff;
			}
		}
	}
}
