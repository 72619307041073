// Permet à l'overlay de s'afficher correctement
.activitieslist {
	position: relative;
}
.activitieslist--list {
	margin: 0;
}

.activities.is-empty {
	#empty_box();
	margin: @gutter;
}
