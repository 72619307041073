img,
object,
embed,
video {
  max-width: 100%;
}
img,
video {
  height: auto;
}
.form .form--row {
  margin-bottom: 1.42857143rem;
  margin-top: 1.42857143rem;
}
@media screen and (min-width: 480px) {
  .form .form--row > *.input,
  .form .form--row > *.input-select {
    float: left;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form .form--row > *.input:first-child:nth-last-child(1),
  .form .form--row > *.input-select:first-child:nth-last-child(1) {
    width: 100%;
  }
  .form .form--row > *.input:first-child:nth-last-child(2),
  .form .form--row > *.input-select:first-child:nth-last-child(2),
  .form .form--row > *.input:first-child:nth-last-child(2) ~ div,
  .form .form--row > *.input-select:first-child:nth-last-child(2) ~ div {
    width: 50%;
  }
  .form .form--row > *.input:first-child:nth-last-child(3),
  .form .form--row > *.input-select:first-child:nth-last-child(3),
  .form .form--row > *.input:first-child:nth-last-child(3) ~ div,
  .form .form--row > *.input-select:first-child:nth-last-child(3) ~ div {
    width: 33.3333%;
  }
  .form .form--row > *.input:first-child:nth-last-child(4),
  .form .form--row > *.input-select:first-child:nth-last-child(4),
  .form .form--row > *.input:first-child:nth-last-child(4) ~ div,
  .form .form--row > *.input-select:first-child:nth-last-child(4) ~ div {
    width: 25%;
  }
  .form .form--row::after {
    display: block;
    content: "";
    clear: both;
  }
  .form .form--row > .input-select:not(:first-child),
  .form .form--row > .input:not(:first-child) {
    padding-left: 0.71428571rem;
  }
  .form .form--row > .input:not(:first-child) label span {
    left: 0.71428571rem;
  }
  .form .form--row > .input-select:not(:last-child),
  .form .form--row > .input:not(:last-child) {
    padding-right: 0.71428571rem;
  }
}
