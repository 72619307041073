//
// Styles de base pour une "modale locale".
//
// C'est le seul type de modale existant. Ce type de modale couvre une partie
// locale pour confirmer une action, mais n'empêche pas l'utilisation du reste
// du site. Généralement, attachée à quelque chose qui ressemble à une "carte".
//

.local-modal {
	#no-select();
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0, 0.4);
	z-index: 1000;

	&>.local-modal--outer {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		margin: auto;
	}
	.local-modal--inner {
		border: @size_input_border solid @color_container_border;
		border-radius: @size_input_radius;
		background: #fff;
		margin: @gutter;
		padding: @gutter;
	}
}
