//
// Styles généraux de liste.
//

// FIXME : Move
#list-item-box() {
	&:extend(._list-item-box all);
}
._list-item-box {
	@_border: 1/14rem solid @color_gris1;
	list-style-type: none;
	margin: 0;
	border-top: @_border;

	&:last-child {
		border-bottom: @_border;
	}

	// Pour utilisation dans une box section avec marges.
	// On retire les marges.
	margin-left: -@gutter;
	margin-right: -@gutter;

	// Clear les éléments à l'intérieur
	#self-clear();

	// Donne un layout
	position: relative;

	li {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	// Assume un enfant principal.
	// Styles de base pour la boîte d'infos
	& > * {
		// Un lien n'est pas block par défaut.
		// On le met en block.
		display: block;
		// On ne veut pas les styles par défaut du lien.
		color: inherit;
		// Padding appliqué au lien.
		// Permet de visuellement bien gérer la navigation par clavier.
		padding: @gutter;
	}

	// Seulement si c'est un lien
	& > a {
		// Ajoute un élément pour faciliter la navigation.
		#left-focus-guide(@gutter);

		&:hover, &:active, &:focus, &.active, &.focus {
			text-decoration: initial;
			background: @color_beige;
		}
	}
}

//
// Spécialisations dans un fragment d'activité.
//
.activityfragment {
	#list-item-box();
	// Puisque tout est un lien.
	cursor: pointer;

	@_chevron_size: 20/14rem;
	& > * {
		// Correction du padding droit pour le chevron.
		// Visuellement, ça marche même sans compenser pour la rotation.
		padding-right: @gutter*2 + @_chevron_size;
	}
	// Chevron en fin de ligne.
	.activityfragment--view {
		#right-chevron(@_chevron_size);
	}
	& > a {
		&:hover, &:active, &:focus, &.active, &.focus {
			.activityfragment--view {
				&>*:first-child {
					// Couleur du chevron.
					border-color: @color_bleu_fonce;
				}
			}
		}
	}

	// La description textuelle...
	.activityfragment--description {
		// On retire les styles des <hx> et les autres
		// trucs différents.
		*:not(.activityfragment--title) * {
			font-family: @font_primary;
			font-size: 1rem;
			font-weight: normal;
		}
	}

	.activityfragment--title {
		margin-bottom: 0.9em;
	}
	// Avec organisme, le titre n'a pas son padding,
	// c'est l'organisme qui en a un!
	&:not(.without-organization) {
		.activityfragment--title {
			margin-bottom: 1/14rem;
		}

		#screen-md-min({
			.activityfragment--organization {
				display: inline-block;
				margin-bottom: 0.9em;
			}
		});
	}

	.activityfragment--infos {
		@_image_size: 4rem;
		@_price_width: 120/14rem;
		// Espacement pour le float de l'image.
		// L'image devra défaire l'espacement.
		padding-left: @_image_size + @gutter * 2;
		overflow: auto;
		// L'image
		.activityfragment--image {
			border: 1px solid @color_container_border;
			border-radius: @size_button_radius;
			overflow: hidden;
			float: left;
			margin-right: @gutter;
			margin-left: -@_image_size - @gutter;

			// Dimensions de l'image
			&, .fake-image {
				width: @_image_size;
				height: @_image_size;
				.fake-image--image-element {
					background-size: cover;
				}
			}

			// Image en cas d'erreur.
			.fake-image.is-error {
				#asset.search-noimage();
				background: @color_qidigo_bleu;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
			}
		}

		// Sur certains types d'affichage, le nom de l'entreprise
		.activityfragment--organization {
			display: block;
			margin-bottom: 1em;
		}

		// Triche pour le data existant dans le DOM.
		// C'est à revoir comment les résultats de recherche seront présentés.
		// (distance)
		.activityfragment--data {
			& > strong:first-child {
				&::after {
					display: inline-block;
					white-space: pre;
				}
			}
		}

		// Gestion de l'affichage du prix en -md-
		#screen-md-min({
			padding-right: @gutter * 2 + @_chevron_size;
			.activityfragment--price {
				position: absolute;
				right: @gutter * 2 + @_chevron_size;
				top: 50%;
				transform: translateY(-50%);
				text-align: center;
				width: @_price_width;
				white-space: nowrap;

				& > strong:first-child {
					font-weight: inherit;
					&::after {
						content: "";
					}
					display: block;
				}
			}
		});
	}
}

.activityfragment {
	em {
		background-color: rgba(255,255,0,0.3);
		color: #000000;
		// Si on garde l'italique, ça fait un peu wonky parce que
		// le texte se déplace selon ce qui est sélectionné.
		font-style: normal;
		// Un peu de dépassement
		margin-left: -2px;
		padding-left: 2px;
		margin-right: -2px;
		padding-right: 2px;
	}

}
.offer-pending-fragment,
.is-preview.offer-pending-fragment {
	background: #bcbec0;
}
