section.page-dashboard {
	// Utilise un side-nav
	.dashboard--contents {
		#side-nav();
		#side-nav.specialize(dashboard, home);
		#side-nav.specialize(informations, user);
		#side-nav.specialize(invoices, invoices);
		#side-nav.specialize(forms, forms);
	}

	// FIXME : c'est le border le layouting dans le dashboard.
	.dashboard--dashboard-box > .dashboard--child > :not(section) > section,
	.dashboard--dashboard-box > :not(section) > section,
	.dashboard--dashboard-box > section {
		.section-box;
	}

	padding: 0 0;

	&>section {
		padding: 0 @gutter;
		padding-bottom: @gutter;
	}
}
section.page-dashboard > header {
	@media print {
		display: none;
	}
	@_size: @size_mega * 1.2;
	position: relative;
	padding-left: @gutter;
	background: @color_blanc;
	border: 1/14rem @color_gris1 solid;
	padding-top: @gutter/2;
	padding-bottom: @gutter/2;
	margin-bottom: @gutter;

	// Caché en trop petit.
	#screen-xs-max({
		display: none;
	});
	#screen-xs-max({
		.dashboard--user-fragment {
			width: 100%;
		}
	});

	h1 {
		margin: 0;
		font-size: @size_categorie0;
		line-height: 1em;
	}

	&>* {
		#souplesse.grow(0);
		#no-select();
	}

	.dashboard--user-fragment {
		overflow: hidden;
		white-space: nowrap;

		a {
			color: inherit;
			cursor: pointer;
		}
	}

	.dashboard--user-avatar {
		display: inline-block;
		position: relative;
		top: 2/14rem;
		background-color: @color_blanc;
		height: @_size;
		width: @_size;
		margin-right: @gutter;
		border: 1/14rem solid @color_grismoyen;
	}

	.dashboard--user-name {
		display: inline-block;
		position: relative;
	}

	.dashboard--name {
		#font-secondary();
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	}

	ul {
		margin: 0;
		#screen-sm-min({
			height: 100%;
		})
	}

	li {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: inline-block;
		height: 100%;
	}

	nav {
		a {
			color: @color_bleu_fonce;
		}

		a:active, a:focus, a:hover, a.active {
			text-decoration: none;
			color: @color_blanc;
			background-color: @color_bleu_fonce;
		}

		a {
			display: block;
			height: 100%;
			position: relative;
			padding: @gutter/4 @gutter/3;
			padding-left: @gutter*1.2;
		}

		a {
			background-position: @gutter/3 50%;
			background-size: @gutter/1.7;
		}
		#screen-sm-min({
			font-size: @size_mini;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			a {
				text-align: center;
				background-position: 50% 30%;
				background-size: auto 50%;
				min-width: @_size * 2.0;
				padding: 0;
			}
			a span {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}
		});
	}
} // Fin du header du dashboard

//
// Sous-sections du dashboard
// ==========================
//

//
// Carnet d'adresses
// -----------------
//
.dashboard-addressbook {
	.address-card {
		#card();

		// Prevents a long address or locality name from breaking the visual
		.p-street-address, .p-locality {
			overflow: auto;
			word-break: break-word;
		}
	}

	// Retire les marges par défaut de <address>.
	address { margin: 0; }

	// Customise le tag.
	.address--tag {
		&:extend(.tag);
		background-color: @color_bleu_fonce;
		border-color: @color_bleu_fonce;
	}
}
//
// Famille
// -------
//
.dashboard-family {
	@_avatar_size: 7rem;
	.family-card {
		#card();
	}

	// L'avatar à gauche
	.family-card>.avatar-image {
		display: block;
		background-color: @color_blanc;
		height: @_avatar_size;
		width: @_avatar_size;
		margin-right: @gutter;
		border: 1/14rem solid @color_grismoyen;
		float: left;
	}

	// Avec les infos en retrait
	.family-member--infos {
		margin-left: @_avatar_size + @gutter;
		min-height: @_avatar_size;
		li {
			list-style-type: none;
			margin: 0;
		}
	}
}

.dashboard-family,
.dashboard-userinfos
{
	.dashboard-userinfos--forms-link,
	.dashboard-family--forms-link {
		h3 {
			margin-bottom: @gutter/2;
		}
		margin-bottom: @gutter;
	}
}

//
// Carnet téléphonique
// -------------------
//
.dashboard-phonebook {
	// Le formulaire est une phone-card
	.phone-card {
		#card();
	}

	#striped-list();

	.phone--tag {
		&:extend(.tag);
		background-color: @color_bleu_fonce;
		border-color: @color_bleu_fonce;
	}
}

.dashboard-settings {
	@_offset: @gutter * 2;
	// FIXME : Revoir le layouting au complet dans le dashboard, c'est le bordel.
	section.page-dashboard .dashboard--dashboard-box > .dashboard--child & * > h2:first-child {
		margin-bottom: 0;
		border-bottom: 0;
	}
	section.page-dashboard .dashboard--dashboard-box > .dashboard--child & > section {
		padding-bottom: 0;
	}
	& > section > ul {
		&, li {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}

		.dashboard-settings--box {
			margin: 0 -@gutter;
			#fancy_collapse();

			& > .collapse > * {
				padding-top: @gutter / 2;
				padding-bottom: @gutter;
				padding-left: @_offset + @gutter;
				padding-right: @gutter;

				#screen-sm-max({
					padding-left: @gutter;
				});
			}

			.collapse {
				border: 0;
			}

			& > .collapse-link {
				border-left: 0;
				border-right: 0;
				border-bottom: 0;
			}
		}
	}
}
.dashboard-warning {
	#box.warning();

	& + form {
		// top-heavy labels makes this feel cramped.
		margin-top: @gutter * 1.5;
	}
}

.dashboard-success {
	#box.success();
}
