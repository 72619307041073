.dashboard-schedule .qidigo-calendar {
	// react-big-calendar n'a pas de hauteur.
	// Il faut lui en donner une.
	min-height: 60rem;
}

.dashboard-schedule .qidigo-schedule.qidigo-calendar {
	.dashboard-schedule--agenda-row {
		#no-select();
		@_image_size: @gutter * 2.5;
		overflow: auto;
		padding-left: @_image_size + @gutter*2;

		& > li:not(.dashboard-schedule--avatar) {
			#screen-sm-min({
				padding-top: @gutter/4;
				display: inline-block;
				width: 33%;
				vertical-align: top;
			});
		}

		.dashboard-schedule--avatar {
			.avatar-image {
				width: @_image_size;
				height: @_image_size;
			}
			margin-left: -@_image_size - @gutter;
			display: block;
			float: left;
			margin-right: @gutter;
		}

		* {
			font-size: @size_courant;
			line-height: 1.5;
		}

		h4, h5 {
			margin: 0;
			padding: 0;
		}
		.dashboard-schedule--session-times {
			.is-not-same-day > span {
				display: block;
			}
		}
	}
}
