
// 
// "Header" utilisateur.
//
.dashboard-forms {
	@_default_color: @color_grisfonce;
	& > section > header {
		@avatar_size: @size_mega;
		#screen-sm-min({
			@avatar_size: @gutter * 2.3;
		});
		position: relative;
		overflow: auto; // Clearons le float.
		background: @_default_color;
		color: @color_blanc;
		#screen-sm-min({
			border-radius: @size_general_radius @size_general_radius 0 0;
		});

		.avatar-image {
			float: left;
			width: @avatar_size - 2/14rem;
			height: @avatar_size - 2/14rem;
			margin: @avatar_size / 5 @avatar_size / 2;
			border: 1/14rem solid rgba(255, 255, 255, 0.5);
		}

		h2 {
			#no-select();
			height: @avatar_size;
			line-height: @avatar_size;
			margin: 0;
			margin-top: @avatar_size / 5;
			#screen-xs-max({
				font-size: @avatar_size * 0.4;		
				margin-bottom: @avatar_size / 5;
			});
			#screen-sm-min({
				font-size: 0.38 * @avatar_size;
			});
		}
	}
}

//
// Listing des groupes.
//
.dashboard-forms {
	.dashboard-forms--forms-list {
		@_border: 1/14rem solid @color_gris1;
		margin-bottom: @gutter;

		& > ul {
			&, & > li {
				list-style-type: none;
				margin: 0;
				padding: 0;
			}
		}
		& > ul > li {
			border: @_border;
			#screen-xs-max({
				border-left: 0;
				border-right: 0;
			});
			&:first-child {
				border-top: 0;
			}
			&:not(:last-child).is-collapsed { border-bottom: 0; }
		}
		// Bordure top de la section collapsée
		// Doit être appliquée au premier enfant et non au div collapsé sinon ça fait un effet weird
		// quand le collapse finit de fermer (calculer un height est dur en js/css)
		.dashboard-forms--group-sessions {
			& > *:first-child {
				border-top: @_border;
			}
		}

		& > ul > li.dashboard-forms--empty {
			#no-select();
			padding: @gutter;
			font-style: italic;
		}
	}

	// La boîte des infos
	.dashboard-forms--forms-list .dashboard-forms--organization {
		position: relative;
		padding: @gutter/2 @gutter;
		padding-left: @gutter;

		h3 {
			margin-bottom: 0.1em;
			line-height: 1.1;
			#screen-xs-max({
				line-height: 1.5;
			});
		}

	}
}

//
// Listing des sessions
//
.dashboard-forms { 
	.dashboard-forms--form {
		@_icon_size: 1.2em;
		@_border: 1/14rem solid @color_gris1;
		line-height: 1.2;
		background: @color_background;
		ol {
			overflow: auto;
			margin: 0;
			padding: 0;
			list-style-type: none;
		}
		ol > li {
			margin: 0;
			padding: @gutter / 2;
			&:not(:last-child) {
				border-bottom: @_border;
			}
			* {
				font-size: @size_courant;
			}
			#self-clear();
		}

		ul {
			&, & > li {
				margin: 0;
				padding: 0;
				list-style-type: none;
			}
		}
		h4, h5 {
			margin: 0;
			line-height: 1.3;
		}
		h4 {
			margin-bottom: 0.4em;
		}
	}

	.button {
		text-align: center;
		display: block;
		width: 100%;
		padding-left: @gutter/2;
		padding-right: @gutter/2;
	}

	.dashboard-forms--form-informations {	
		overflow:auto;
		li {
			display: inline-block;
			#screen-sm-max({
				display: block;
			})
		}
		
	}
	#screen-md-min({
		.dashboard-forms--form-title{
			width:50%;			
		}
		.dashboard-forms--form-completion{
			width:30%;
		}
		.dashboard-forms--form-actions{
			width:20%;
		}
	})
}
