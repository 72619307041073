#action-buttons() {
	&:extend(#_action-buttons all);
}

#_action-buttons {
	&, &.button, &.button-button {
		#debuttonize();
		margin-top: @gutter/2;
	}
	&.action-delete { &:extend(.icon-action.icon-delete); }
	&.action-edit   { &:extend(.icon-action.icon-edit); }
	&.action-set-default { &:extend(.icon-action.icon-home); }
	&:hover, &:active, &:focus {
		&.action-delete { &:extend(.icon-action.active.icon-delete); }
		&.action-edit   { &:extend(.icon-action.active.icon-edit); }
		&.action-set-default { &:extend(.icon-action.active.icon-home); }
	}
}
