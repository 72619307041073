@media print {
	.return-fragment {
		display : none;
	}
}

.return-fragment {
	border-width: 1/14rem;
	border-style: solid;
	background: @color_blanc;
	border: 1/14rem @color_gris1 solid;
	border-top: 0;
	border-bottom-width: 0;
	font-size: @size_categorie1;

	a {
		#font-secondary();
		display: block;
		padding: @gutter/2;
		padding-top: @gutter/2 + 1/14rem; // font is top heavy

		&:hover, &:active {
			background: @color_gris0;
			text-decoration: none;
		}

		&::before {
			content: "";
			@_w: 0.5em;
			@_b: 2/14rem solid @color_qidigo_bleu;
			display: inline-block;
			height: @_w;
			width: @_w;
			transform: rotate(-45deg);
			border-left: @_b;
			border-top: @_b;
			margin-right: 0.6em;
			margin-left: 0.6em;
			position: relative;
		}
	}
}
