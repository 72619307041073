section.page section.memberships-page {
}

.memberships-list {
	&:not(.is-empty) {
		margin-bottom: -@gutter;
		margin-top: -@gutter;
	}

	& > li {
		margin: 0;
		padding: 0;
	}
	& > li {
		#list-item-box();

		// Uses the h2's border.
		&:first-child {
			border-top: 0;
		}
	}
}

.membership-informations {
	@_actions_size: 130/14rem;
	@_image_size: 4rem;
	
	#left-focus-guide(@gutter);
	cursor: pointer;
	&:hover, &:active, &:focus, &.is-selected {
		background: @color_beige;
	}

	h3 {
		margin-bottom: @gutter/2;
	}

	.is-formatted-text {
		p {
			margin-bottom: 0.6em;
		}
	}

	.membership__properties {
		&, & > li {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}
		.property-key {
			font-weight: bold;
		}
	}

	.membership__details {
		margin-top: @gutter;
		#screen-md-min({
			margin-right: @_actions_size + @gutter;
		});
	}

	.membership__actions {
		#screen-sm-max({
			margin-top: @gutter;
		});
		#screen-md-min({
			position: absolute;
			right: @gutter;
			top: 50%;
			transform: translateY(-50%);
			width: @_actions_size;
			& > * {
				display: block;
				padding-left: 0;
				padding-right: 0;
				text-align: center;
			}
		});
	}

	// L'image
	.membership__image {
		border: 1px solid @color_container_border;
		border-radius: @size_button_radius;
		overflow: hidden;
		float: left;
		margin-right: @gutter;

		// Dimensions de l'image
		&, .fake-image {
			width: @_image_size;
			height: @_image_size;
			.fake-image--image-element {
				background-size: cover;
			}
		}

		// Image en cas d'erreur.
		&.fake-image.is-error>.fake-image--image-element {
			#asset.search-noimage();
			background: @color_qidigo_bleu;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
		}
	}
}
