img,
object,
embed,
video {
  max-width: 100%;
}
img,
video {
  height: auto;
}
.imagepicker {
  position: relative;
}
.imagepicker .imagepicker--wrapper {
  position: relative;
  display: inline-block;
  width: auto;
}
.imagepicker .imagepicker--wrapper > * {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.imagepicker .imagepicker--wrapper .button {
  margin-top: 0.71428571rem;
}
.imagepicker.is-drop-target::after {
  content: "";
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.21428571rem;
  z-index: 10;
  text-align: center;
  line-height: 100%;
  vertical-align: middle;
}
.imagepicker input[type=file] {
  display: none;
}
.imagepicker .imagepicker--preview {
  padding: 0;
  overflow: hidden;
  display: block;
  position: relative;
  width: 10rem;
  height: 10rem;
  cursor: pointer;
}
.imagepicker .imagepicker--preview .fake-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.imagepicker--preview {
  border: 1px solid #cccccc;
}
