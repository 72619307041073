//
// Fancy collapse
// ==============
//
// À appliquer sur l'élément parent qui contient
// le a.collapse-link et le *.collapse.
//
// S'assurer que le *.collapse et le a.collapse-link ont
// tous deux la classe is-opened ou is-not-opened.
//
// Ne se préoccupe pas du contenu du collapse, donc c'est
// à l'utilisateur du collapse de gérer le padding ou le fond.
//
#fancy_collapse {
	&:extend(#_fancy_collapse all);
}
#_fancy_collapse {
	@_border: 1/14rem;
	&>h3>.collapse-link,
	&>.collapse-link,
	&>.collapse {
		border: @_border solid @color_gris1;
		//border-radius: @size_button_radius;
	}

	& > h1,
	& > h2,
	& > h3,
	& > h4 {
		margin: 0;
	}

	&>h3>.collapse-link,
	&>.collapse-link {
		display: block;
		padding: @gutter/2;
		color: @color_police;
		text-shadow: 1/14rem 1/14rem 1/14rem @color_blanc;
		#font-secondary();
		#no-select();
		cursor: pointer;

		&::before {
			border: @color_police;
		}

		&.is-opened {
			border-radius: @size_button_radius @size_button_radius 0 0;
		}
	}

	&>.collapse {
		border-radius: 0 0 @size_button_radius @size_button_radius;
		margin-top: -1 * @_border;
		border-top: 0;
	}

	& > h3.collapse-link {
		#collapse-chevron(@color_police);
		padding-left: @gutter + 2rem;

		& > a {
			color: @color_police;
			display: block;
		}
	}
}

//
// Lien pour collapse
// ==================
//
// Styles par défaut pour le lien.
//
a.collapse-link {
	// More button-like, non-selectable.
	// Makes it spammable without losing selection.
	#no-select();
	cursor: pointer;

	#collapse-triangle();
}

//
// Règles pour les triangles de collapse
// =====================================
//

//
// Grandeur à utiliser pour un élément contenant uniquement le triangle.
// À utiliser pour centrer verticalement de manière assurée.
//
@collapse_triangle_size: 22/14rem;

//
// Mixin à utiliser
//
//   @color: Couleur du triangle. Bleu lien par défaut.
//
#collapse-triangle(@color: @color_qidigo_bleu, @rules: {}) {
	&:extend(#_collapse-triangle all);
	&::before {
		border-left-color: @color;
		@rules();
	}
}
#_collapse-triangle {
	// Triangle
	@_dim: 8/14em;
	@_factor: 0.64; // Facteur de 1.00 donnerait un triangle rectangle.
	&::before {
		content: "";
		border-top: @_dim * @_factor solid transparent;
		border-left: @_dim solid @color_qidigo_bleu;
		border-bottom: @_dim * @_factor solid transparent;
		display: inline-block;
		position: relative;
		margin-right: 5/14rem;
		transition: transform 0.4s linear;
		transform: rotate(0deg);
	}

	&.is-not-collapsed::before,
	&.is-opened::before {
		// Légère translation pour re-balancer visuellement le triangle.
		transform:
			translateX(-1/14rem)
			translateY(1/14rem)
			rotate(90deg)
		;
		text-shadow: none;
	}
}

#collapse-chevron(@color: @color_qidigo_bleu, @rules: {}) {
	&:extend(#_collapse-chevron all);

	&::before {
		border-right-color: @color !important;
		border-bottom-color: @color !important;
		@rules();
	}
}
#_collapse-chevron {
	// Triangle
	@_dim: 2/14rem;
	@_size: 8/14rem;

	& {
		position: relative;
	}

	&::before {
		content: "";
		border-right: @_dim solid @color_qidigo_bleu;
		border-bottom: @_dim solid @color_qidigo_bleu;
		width: @_size;
		height: @_size;
		position: absolute;
		left: @gutter;
		top: 0;
		bottom: 0;
		margin: auto;
		margin-right: 5/14rem;
		transition: transform 0.4s linear;
		transform:
			translateY(0)
			rotate(-45 + 0deg)
		;
	}

	&.is-not-collapsed::before,
	&.is-opened::before {
		transform:
			translateY(-@_size/4)
			rotate(-45 + 90deg)
		;
		text-shadow: none;
	}
}
