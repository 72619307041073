//
// Marqueur et texte associé au marqueur
//
.mapmarker {
	@_text-border: @color_blanc;
	@_s: 20/14rem;
	#no-select();

	line-height: 1em;
	margin-top: -1em;
	margin-left: -0.5em;

	padding-left: @_s * 0.5;
	position: relative;
	white-space: nowrap;
	font-size: @size_courant * 1.2;
	font-weight: bold;

	// Assure un "contour" autour du texte.
	text-shadow:
		0px 1px 0 @_text-border,
		0px -1px 0 @_text-border,
		-1px 0px 0 @_text-border,
		1px 0px 0 @_text-border,
		1px 1px 0 @_text-border,
		1px -1px 0 @_text-border,
		-1px 1px 0 @_text-border,
		1px 1px 0 @_text-border,
	;

	& > .mapmarker--pin {
		position: absolute;
		bottom: 50%;
		left: 0;

		width: @_s;
		height: @_s;
		border: @_s*0.28 solid @color_qidigo_rouge;

		background: transparent;
		display: inline-block;
		border-radius: @_s @_s @_s 0;
		transform:
			scaleY(1.05)
			scaleX(0.95)
			translateX(-50%)
			rotate(-45deg)
		;
		box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.3);
	}

	//
	// Configure la couleur du marqueur de manière abstraite.
	//
	// Utilisation:
	//
	//     .some--selector--for--map {
	//         .mapmarker.pin-color(@color_bleu_fonce);
	//     }
	//
	.pin-color(@color) {
		.mapmarker .mapmarker--pin {
			border-color: @color;
		}
	}
}
