.qidigo-wysiwyg {
	margin: @gutter/2 0;
	.qidigo-wysiwyg--rte {
		display: block;

		// CAUTION : Changes in the upstream DOM of react-rte will break this.
		// They use the kind of react component with no proper classes... ergh.

		// Toolbar
		&>div:first-child {
			margin-left:  -@gutter/2;
			margin-right: -@gutter/2;
			padding: 0 @gutter/2;
			padding-top: @size_input_border;
			border-bottom: @size_input_border solid @color_gris2;
		}
		// RTE
		&>div:last-child {
			margin-left:  -@gutter/2;
			margin-right: -@gutter/2;
			padding: 0;
		}
		// RTE "area"
		// Putting min-height higher in the hierarchy does not allow focusing
		// the box properly.
		.public-DraftEditor-content {
			min-height: 12rem;
		}
	}
}
