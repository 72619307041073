.social-login {
	#souplesse.vertical();

	&, li {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	li.social-login--item {
		margin: @gutter/2 0;
	}

	.social--button {
		@_icon: 1.3em;
		@_padding: 0.4em;
		#base_button();
		display: block;
		border-width: 1px;
		border-radius: @size_general_radius;
		color: #ffffff;
		text-decoration: none;
		position: relative;
		// Pourquoi padding*2 + padding*2 ???
		// Parce que autrement le texte est collé sur la bordure!
		padding-left: @_padding*2 + @_icon + @_padding*2;

		&::before {
			content: "";
			display: block;
			margin: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			padding: 0 @_padding;
			width: @_icon;
			background-size: auto @_icon;
			background-repeat: no-repeat;
			background-position: center 54%;
			border-right: 1px solid #000000;
			line-height: 2em;
		}
	}

	// On ne peut pas métaprogrammer l'utilisation d'une classe.
	// http://lesscss.org/features/#extend-feature-selector-interpolation-with-extend
	// C'est pourquoi on n'utilise pas extend() ou un mixin pour les icones.
	// (Les icones sont gérées par socialicon.less, et c'est une classe spéciale.)

	.specialize(@provider, @color) {
		@_mult: #dadada;

		.social-login--@{provider} a {
			background-color: @color;
			border-color: multiply(@color, @_mult);

			&::before {
				#__icon-self(@provider, qidigo-blanc, @color_blanc);
				border-color: multiply(@color, @_mult);
			}

			// stylelint-disable max-nesting-depth
			// Meta-programming much.
			&:not(:disabled) {
				&:hover, &:focus, &:active, &.active {
					background-color: multiply(@color, @_mult);

					&::before {
						border-color: multiply(multiply(@color, @_mult), @_mult);
					}
				}
			}
			// stylelint-enable
		}
	}

	.social-login.specialize(facebook, @color_facebook);
	.social-login.specialize(google,   @color_google  );
}

