@import "../icomoon.less";
#footer {
	@media print {
		display: none;
	}
	@_color_footer: #081E23;
	#no-select;

	&>.container {
		#screen-sm-min({
			#souplesse.horizontal();
			#souplesse.wrap(wrap);
		});
	}

	@_border: 0.4rem;
	@_line_height: 1.3em;
	padding-top: @_border * 2;
	border-top: @_border solid @color_qidigo_bleu;
	background: @_color_footer;
	&, a, a:hover, a:focus, a:active, a.active {
		color: @color_blanc;
	}

	font-size: @size_courant;
	#screen-sm-min({
		font-size: @size_mini;
		line-height: @_line_height;
	});

	h1 {
		line-height: inherit;
		font-size: inherit;
		margin-bottom: 0;
	}

	nav {
		width: 100%;
		#screen-sm-min({
			width: 100%/5;
			padding-right: 1.5rem;
		});
		position: relative;
	}

	nav > ul > li {
		margin: 0;
		list-style-type: none;
	}

	.footer--a-propos {
	}

	li a {
		color: inherit;
		text-decoration: none;
		cursor: pointer;

		&:hover, &:active, &:focus, &.active {
			text-decoration: underline;
		}
	}

	.footer--copyright {
		width: 100%;
		text-align: center;
		color: @color_grisfonce;
		p {
			margin: 0;
			padding: 0.1rem 1rem;
		}
	}

	// FIXME : Move into a js require for the debug page.
	.footer--debug {
		text-align: center;
		background: #ff0000;
		color: #000000;

		a {
			color: inherit;
		}
	}

	.footer--social li {
		padding-right: 0.5rem;
		margin-right: 0;
	}

	.footer--contactez-nous p {
		margin-bottom: 0.3em;
	}

	.footer--social {
		margin-bottom: 1em;
	}

	.footer--social .sociallinks--item {
		padding-right: 0.2em;
	}
	.footer--social .sociallinks--item a {
		font-size: 1em;
		color: @_color_footer;
		border: 0;
		height: 2.0em;
		width:  2.0em;
		background-color: @color_police;

		&:hover {
			background-color: #ffffff;
		}
	}

	.icon {
		#__icon(facebook,   qidigo-bleu, @_color_footer, provider);
		#__icon(x,    qidigo-bleu, @_color_footer, provider);
		#__icon(googleplus, qidigo-bleu, @_color_footer, provider);
		#__icon(instagram,  qidigo-bleu, @_color_footer, provider);
		#__icon(youtube,    qidigo-bleu, @_color_footer, provider);
	}

	.input-select.footer--locale-select {
		.input {
			margin: 0;
			margin-bottom: 1em;
			line-height: @base_line_height;
		}
		#input-select.color(
			@text: @color_blanc,
			@border: @color_blanc,
			@background: @_color_footer,
		);
	}

	.footer--section-localization {
		h1 {
			position: relative;
			&::before {
				content: "";
				position: absolute;
				display: block;
				height: 1.8em;
				width: 1.8em;
				left: -1.8em - 0.2em;
				top: 0;
				bottom: 0;
				margin: auto;
				#__icon-self(globe,   "bleu", @color_qidigo_bleu);
				background-position: center right;
				background-size: contain;
			}
		}
	}
	.footer--section-powered-by {
		.logo-container {
			width: @_line_height * 6;
			height: @_line_height * 3;
			margin-left: @gutter;
			padding: 0;
			line-height: 0;
		}
		a.logo {
			margin-top: 0.2em;
			padding: 0;
			display: block;
			height: 100%;
			width: 100%;
			background-image: url("~design/assets/logo-highlighted.svg");
			background-size: contain;
			background-position: left center;
			background-repeat: no-repeat;
			text-indent: -300vw;
		}
	}

	#screen-sm-min({
		.footer--section-localization:not(:first-child) {
			display: none;
		}
	});

	#screen-xs-max({
		padding-left: @gutter;
		padding-right: @gutter;
		padding-top: 0;
		.footer--section-menu {
			h1 { display: none; }

			ul {
				margin-left: -@gutter;
				margin-right: -@gutter;
			}

			li {
				margin: 0;
				padding: 0;
			}

			a {
				display: block;
				text-align: center;
				border-bottom: 1px solid @color_blanc;
				line-height: @base_line_height * 2;
			}
		}
		.footer--section-localization {
			h1, .input {
				z-index: 2;
				display: inline-block;
			}
			h1 {
				width: 40%;
			}
			.input {
				width: 60%;
			}
		}
		.footer--section-social {
			z-index: 1;
			h1, p {
				display: none;
			}
			text-align: center;
		}

		.footer--section-about {
			clear: both;
		}

		.footer--section-powered-by {
			h1 {
				display: none;
			}
		}

		.footer--section-localization:first-child {
			display: none;
		}
	});
	#screen-xs-only({
		.footer--section-menu {
			.footer--section-localization {
				width: 50%;
				float: left;
				padding-right: @gutter/2;
			}
			.footer--section-social {
				text-align: right;
				padding-left: 50%;
			}
		}
	});
}
