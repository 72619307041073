@text-large: 1.5rem;
@text-medium: 1.25rem;
@text-small: 1rem;
@contact-box-background: rgb(255, 255, 255);
@contact-box-border: rgb(105, 113, 117);

.app--view > .page-contact-confirmation {
  .anticontainer();
  .layout.fill-vertical();
  .hitting.fullpage-background();

  display: flex;
  justify-content: center;
  align-items: center;

  .contact-confirmation-box {
    max-width: 30rem;
    padding: 1.5rem;
    background-color: @contact-box-background;
    border: 0.15rem solid @contact-box-border;
    border-radius: 0.40rem;
    box-shadow: 0 1.25rem 2rem rgba(0, 0, 0, 0.5);
    text-align: center;

    h1 {
      font-size: @text-large;
      font-weight: bold;
      margin-bottom: 2rem;
    }

    p {
      font-size: @text-small;
      text-align: center;
    }
  }
}

.contact-confirmation-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
