.sociallinks {

	@_color: @color_gris2;
	@_hover: @color_grisfonce;
	@_size: @gutter * 1.3;

	&, li {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	li {
		display: inline-block;
	}

	li.sociallinks--item:not(:last-child) {
		margin-right: @gutter * 0.6;
	}

	.sociallinks--item a {
		#base_button();
		font-size: 1.03em;
		cursor: pointer;

		text-indent: 0;

		width: @_size;
		height: @_size;
		margin: 0.35em;

		line-height: 0;
		min-height: 0;
		min-width: 0;
		padding: 0;

		// L'icône provient de sociallinks.less
		background-size: 75%;
		background-repeat: no-repeat;
		background-position: center center;
		background-color: @_color;
		border-radius: 0.36em;
		border-color: @_color;
		border-width: 1px;
		color: #ffffff;
		display: block;
		overflow: hidden;
		text-decoration: none !important;

		span {
			font-size: 1px;
			position: absolute;
			visibility: hidden;
			text-indent: -999rem;
		}

		&:hover, &:focus, &:active {
			border-color: @_hover;
			background-color: @_hover;
		}
	}
}

.icon {
	#__icon(website,    qidigo-blanc, @color_blanc, provider);
	#__icon(facebook,   qidigo-blanc, @color_blanc, provider);
	#__icon(x,    qidigo-blanc, @color_blanc, provider);
	#__icon(googleplus, qidigo-blanc, @color_blanc, provider);
	#__icon(youtube,    qidigo-blanc, @color_blanc, provider);
	#__icon(instagram,  qidigo-blanc, @color_blanc, provider);
}
