.dashboard-invoices {
	font-size: 0.9em;
	* {
		vertical-align: top;
	}
	ul {
		& > li {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}
	}

	& > ul > li {
		padding-top: @gutter/2;
		padding-bottom: @gutter/2;
		border-top: 1/14rem solid @color_gris1;
	}
	& > ul > li > ul > li {
	}

	.dashboard-invoices--container {
		padding: 1rem;
		border: 0.10rem solid @color_gris1;
	}

	.dashboard-invoices--balance {
		display: block;
	}

	.dashboard-invoices--collapse-header {
		align-items: center;
		border: 1px solid #e9e9e9;
		display: flex;
		padding: @gutter;
		color: #FFFFFFFF;
		background-color: @qidigo_main_dark;

		h2 {
			margin-bottom: 0 !important;
			font-size: medium
		}

		& > .dashboard-invoices--collapse-header-triangle {
			position: relative;
			margin-left: @gutter;
			color: #FFFFFFFF;
			right: @gutter;
			height: @collapse_triangle_size;
			#collapse-triangle(#FFFFFFFF);

			@media (max-width: @sm) {
				margin-top: 1.25rem;
				grid-column: span 2 / span 2;
			}
		}
	}

	.button {
		text-align: center;
		display: block;
		width: 100%;
		padding-left: @gutter/2;
		padding-right: @gutter/2;
		&:not(:first-child) {
			margin-left: 0;
		}
		&:not(:last-child) {
			margin-bottom: @gutter/2;
		}
	}

	.dashboard-invoices--description {
		font-weight: bold;
	}

	.dashboard-invoices--id {
		font-weight: bold;
	}
	.dashboard-invoices--amounts {
		display: inline-block;
		text-align: center;
	}

	.dashboard-invoices--balance {
		font-style: italic;
	}

	#screen-md-min({
		& > ul > li > ul > li {
			&:not(:last-child) {
				padding-right: @gutter;
			}
			display: inline-block;
		}
		.dashboard-invoices--id {
			text-align: right;
			width: 6%;
		}
		.dashboard-invoices--desc {
			width: 34%;
		}
		.dashboard-invoices--dates {
			width: 32%;
		}
		.dashboard-invoices--dates--installment {
			color: @color_qidigo_bleu;
		}
		.dashboard-invoices--amounts {
			width: 19%;
		}
		.dashboard-invoices--actions {
			width: 9%;
		}
	});
}
