// Les styles des pags d'enregistrement sont communs à ces pages.
@registration-box-background: rgb(184 199 206);


.app--view > .page-registration {
  .anticontainer();
  .layout.fill-vertical();
  .hitting.fullpage-background();
  //#souplesse.vertical();
  //#souplesse.middle-one();

  section.registration {
    .container();
    max-width: @container-xs;

    .buttons-channel {
      p {
        text-align: left;
        margin-top: @gutter;
        margin-bottom: -@gutter / 2;
        font-size: @size_mini;
        line-height: 1.5;
      }
    }

    & > div {
      .hitting.widebox();
    }

    h1 {
      margin-bottom: @gutter;
      color: @color_qidigo_rouge;
      text-align: center;
    }

    header.steps {
      margin: -@gutter;
      margin-bottom: @gutter;

      ul,
      ul li {
        margin: 0;
        padding: 0;
      }

      ul {
        #souplesse.horizontal();
        #souplesse.wrap(wrap);
      }

      ul li {
        #souplesse.grow(1);
        min-width: 8rem;
        list-style-type: none;
        text-align: center;
        //#font-secondary;
        color: @color_blanc;
        border-bottom: 1px solid @color_blanc;
      }

      ul li:not(:last-child) {
        border-right: 1px solid @color_blanc;
      }

      ul li.is-done {
        background: @color_qidigo_bleu;
      }
    }

    .registration--child {
      background: rgba(255, 255, 255, 0.8);
      margin: -@gutter;
      padding: @gutter;

      & > .loading {
        margin-top: 8 * @gutter;
        margin-bottom: 8 * @gutter;
      }
    }

    .registration-avatar--form--avatar {
      text-align: center;
    }
  }
}

.registration-family {
  form {
    margin-bottom: @gutter;
  }
  // FIXME : de-dupe
  .registration-family--family-members {
    & > li {
      list-style-type: none;
      margin: 0;
      padding: @gutter / 2 @gutter;
      margin-left: -@gutter;
      margin-right: -@gutter;
      &:nth-child(odd) {
        background-color: @color_blanc;
      }
      &:nth-child(even) {
        background-color: @color_background;
      }
    }
  }

  .registration-family--family-members {
    nav,
    nav > ul,
    nav > ul > li {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: inline-block;
    }
    & > li:hover {
      background-color: @color_gris0;
    }
    nav {
      margin-left: @gutter / 2;
      float: right;
    }
    nav .button {
      @_size: @size_button_height - @size_button_padding_h / 2;
      #action-buttons();
      &.button.button-button {
        margin-top: 0;
      }
      overflow: hidden;
      text-indent: -999em;
      width: @_size;
      height: @_size;
      border: 0;
      background-position: center center;
      &.action-delete {
        &:extend(.icon-action.icon-delete);
      }
      &:hover,
      &:active,
      &:focus {
        &.action-delete {
          &:extend(.icon-action.active.icon-delete);
        }
      }
    }
  }
}

.ButtonsBar-left {
  float: left;
}

.activity-groups-list {
  .activity-groups-list--subscriber-header {
    align-items: center;
    border: 1px solid #e9e9e9;
    display: flex;
    padding: @gutter;
    color: #FFFFFFFF;
    background-color: @qidigo_main_dark;

    h2 {
      margin-bottom: 0 !important;
      font-size: medium
    }

    & > .activity-groups-list--subscriber-header-triangle {
      position: relative;
      margin-left: @gutter;
      color: #FFFFFFFF;
      right: @gutter;
      height: @collapse_triangle_size;
      #collapse-triangle(#FFFFFFFF);

      @media (max-width: @sm) {
        margin-top: 1.25rem;
        grid-column: span 2 / span 2;
      }
    }
  }

  .activity-groups-list--container {
    background-color: white;
    border: 1px solid #e9e9e9;
    border-bottom: none !important;
    border-top: none !important;
    margin-top: 0 !important;
    padding: @gutter;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .activity-groups-list--group-list {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.activity-groups-list-top-buttons-bar:after {
  content:"";
  display:block;
  clear:both;
}

.activity-groups-list--summary-header {
    margin-bottom: @gutter;
    align-items: center;
    background-color: @registration-box-background;
    display: flex;
    margin-left: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: white;

  & > .activity-groups-list--summary-header-triangle{
    position: relative;
    right: @gutter;
    height: @collapse_triangle_size;
    #collapse-triangle(@color_police);

    @media (max-width: @sm) {
      margin-top: 1.25rem;
      grid-column: span 2 / span 2;
    }
  }
}

.registration-info .registration-warning {
  #box.warning();
  font-size: 0.9em;
}

@media screen and (max-width: 479.9999px) {
  .form-ButtonsBar-sm.ButtonsBar-left {
    margin-bottom: 0.71428571rem;
  }
}

.member-offers-selection {

  .member-offers-selection--buttons-bar {
    .member-offers-selection--buttons-bar--button-add-to-cart {
      background-color: @color_qidigo_rouge;
    }
  }

  .member-offers-selection--action-bar-container {
    margin-bottom: 0 !important;
  }
}
