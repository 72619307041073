//
// Définition de styles réutilisables
// ==================================
//

//
// Containers
// ----------
//
// Styles généraux pour créer des containers suivant les *breakpoints
// responsive*, et permettant de spécialiser un container et réinitialiser
// les règles qui ont définit une largeur sur un container.
//

// Style spécial.
// Permet de centrer.
// Peut être utilisé directement ou en mixin.
// C'est surtout pour permettre une étendue infinie.
.container {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	#screen-sm-min({
		width: @container-sm;
	});
	#screen-md-min({
		width: @container-md;
	});
	#screen-lg-min({
		width: @container-lg;
	});
}

// Défait les règles de container.
// Utile pour définit un comportement par défaut et le briser uniquement
// lorsque certaines conditions sont remplies.
.anticontainer() {
	margin-left: 0;
	margin-right: 0;
	width: auto;
	#screen-sm-min({
		width: auto;
	});
}

