.input-city {
	position: relative;

	.input-city--suggestions {
		&:not(.is-open) {
			display: none;
		}
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
	}

	.react-autosuggest__suggestions-container {
		position: absolute;
		top: @size_input_border + @size_input_height;
		left: 0;
		right: 0;
		z-index: 100;
		transform: translateY(-1/14rem);
		background: @color_blanc;
		border: @size_input_border @color_gris2 solid;
		border-top: 0;
		border-radius: 0 0 @size_input_radius @size_input_radius;
		box-shadow: 3/14rem 3/14rem @size_focus_glow @color_grisfonce;

		ul {
			margin: 0;
			padding: 0;
			width: 100%;
		}

		ul li {
			list-style-type: none;
			margin: 0;
			padding: @gutter/4 @gutter/2;
			cursor: pointer;
		}

		.is-highlighted {
			background: @color_qidigo_bleu;
			color: @color_blanc;
		}

		.input-city--cantfind {
			padding: @gutter/4 @gutter/2;
			a {
				display: block;
				text-align: center;
			}
		}
	}

	// Fait partie du hack pour afficher un état vide.
	.react-autosuggest__suggestion--first {
		display: none;
	}

	.react-autosuggest__suggestion--focused {
		background: @color_qidigo_bleu;
		color: @color_blanc;
	}

	.input-city--reset-curated {
		text-decoration: none;
		color: inherit;
		position: absolute;
		margin: auto;
		top: @size_input_border;
		right: @gutter/2;
		text-align: center;
		width: @gutter;
		height: @size_input_height;
		line-height: @size_input_height;
		font-size: @gutter;
	}
}
