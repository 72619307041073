
.dashboard-form {
	@_default_color: @color_grisfonce;

	h2 {
		#screen-xs-max({ font-size: @aal_font_size; });
		#screen-sm-min({ font-size: @aal_font_size; });
	}

	.form-section > div {
		padding: @gutter/2;
	}

	.form-section {
		border: 1 / 14rem solid @color_gris2;
		border-radius: @size_general_radius;
		margin-bottom: @gutter;
	}

	.question {
		margin-bottom: @gutter;
	}

	.form-section-title {
		background-color: @color_grismoyen;

		h4 {
			margin: 0;
			padding-top: 1rem;
		}
	}

	.form-section-description {
		outline: 1 / 14rem solid @color_gris2;;
	}

	.question-title {
		margin: 0;

		> span {
			color: @color_qidigo_bleu;
		}

		> div {
			display: inline-block;
		}
		
		p:last-child{
			margin: 0;
		}
	}

	.dashboard-form--back-to-last-location-container {
		padding-bottom: 1rem;
		border-bottom: solid 2px @color_container_border;

		.return-fragment.return-fragment {
			border-left: none;
			border-right: none;

			a {
				padding-left: 0;
			}
		}
	}

	.dashboard-form--form-title {
		padding-top: 1rem;
	}


}
