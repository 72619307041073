.empty-state {
	#empty_box();
}
#empty_box {
	&:extend(#_empty_box all);
}
#_empty_box {
	#no-select();
	#font-secondary;
	background: @color_background;
	border-radius: @size_general_radius;
	font-size: @size_mega;
	color: @color_gris2;
	text-align: center;
	padding: 1.5em 1em;
	line-height: @base_line_height * 1.8;
	margin-bottom: @gutter;
}
