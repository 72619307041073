.cart-formList--header {
    @_size: @size_mega * 1.2;
    position: relative;
    background: @color_blanc;
    padding-top: @gutter;
    border-top: 1rem @color_gris0 solid;
    padding-bottom: @gutter / 2;
    margin-bottom: @gutter;
}

.cart-formList {
    @_size: @size_mega * 1.2;
    position: relative;
    padding-left: @gutter;
    padding-right: @gutter;
    background: @color_blanc;
    padding-top: @gutter;
    padding-bottom: @gutter / 2;
    margin-bottom: @gutter;

    h1 {
        margin: 0;
        font-size: @size_categorie0;
        line-height: 1em;
    }

    * {
        vertical-align: top;
    }

    ul {
        & > li {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }
    }

    & > ul > li {
        padding-top: @gutter / 2;
        padding-bottom: @gutter / 2;
        border-top: 1/14rem solid @color_gris1;
    }

    .cart-formList--labels {
        font-weight: bold;
        padding-top: @gutter;
        border-style: none;
    }

    .cart-formList--status-to-complete {
        font-weight: bold;
        color: red;
        display: inline-block;
    }

    .cart-formList--status-complete {
        font-weight: bold;
        color: green;
        display: inline-block;
    }

    .cart-formList--answer-button {
        text-align: center;
        display: block;
        width: 100%;
        padding-left: @gutter / 2;
        padding-right: @gutter / 2;

        &:not(:first-child) {
            margin-left: 0;
        }

        &:not(:last-child) {
            margin-bottom: @gutter / 2;
        }
    }

    & > ul > li > ul > li {
        &:not(:last-child) {
            padding-right: @gutter;
        }

        display: inline-block;
    }

    .cart-formList--row-info {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 25%;
    }
}

.cart-formList--validation-box {
    padding-top: @gutter * 3;
}

.cart-formList--next-step-button {
    padding-top: @gutter / 2;
    padding-bottom: @gutter / 2;
}
