@_size: 3rem;
@_color: @color_secondary;
.loading--wrapper {
	text-align: center;
	margin: auto;
	padding: @gutter 0;
}
.loading--message {
	margin-bottom: @gutter/2;
}
.loading--spinner {
	margin: 0 auto;
	font-size: 10px;
	overflow: hidden;
	position: relative;
	text-indent: -9999em;
	border: @_size/9 solid @_color;
	#translucent(border-color, @_color, 0.2);
	border-left-color: @_color;
	transform: translateZ(0);
	#animation.spinner();
}
.loading--spinner,
.loading--spinner:after {
	border-radius: 50%;
	width: @_size;
	height: @_size;
}

.loading {
	#no-select();
	#font-secondary(400);
}

//
// Compact
// =======
//
// S'utilise généralement inline.
.loading.is-compact {
	display: inline-block;

	.loading--wrapper {
		padding: 0;
	}

	.loading--spinner {
		width: 2.3rem;
		height: 2.3rem;
		border-width: 3/14rem;
	}
}

// 
// Overlay
// =======
//
// S'utilise dans un élément qui lui a une position, permettant
// à l'élément de loading de prendre toute la place et faire une
// couverture pendant le chargement.
//

.loading.style-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;

	.loading--wrapper {
		position: absolute;
		top: 1rem;
		left: 0;
		right: 0;
		margin: auto;
		width: 33%;
		min-width: 20rem;
		border: 1px solid @color_container_border;
		background: @color_blanc;
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		#translucent(background-color, #fff, 0.8);
	}
}
