@gender-restriction-sm: 500px;

.gender-restrictions {
  display: inline-flex;

  .gender-restrictions--tooltip-text {
    width: fit-content;
    max-width: 25rem;
    background-color: #27708EFF;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem;
    z-index: 999;
    position: absolute;
    top: 50%;

    @media (max-width: @gender-restriction-sm) {
      top: 10% !important;
    }
  }

  .for-pass-modal {
    top: 60%;
    @media (max-width: @gender-restriction-sm) {
      top: 65% !important;
    }
  }
}
