//
// Assets
// ======
//
// Classes à utiliser pour faire appel aux assets, pour éviter de multiples
// embeds.
//

#asset {
	.search-noimage() {
		&:extend(#__asset .search-noimage all);
	}
	.avatar-default() {
		&:extend(#__asset .avatar-default all);
	}
}

#__asset {
	.search-noimage {
		background-image: url("~design/assets/placeholders/search-noimage.png");
	}
	.avatar-default {
		background-image: url("~design/assets/placeholders/avatar-default.png");
	}
}
