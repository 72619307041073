//
// Icônes génériques d'actions
// ===========================
//
// (Pour ne pas dédoubler les assets dans le css)
//

.icon-action {
	&.icon-edit  { #__icon-self(edit,   "bleu", @color_bleu_fonce); }
	&.active.icon-edit  { #__icon-self(edit,   "blanc", @color_qidigo_bleu); }

	&.icon-home  { #__icon-self(home,   "bleu", @color_bleu_fonce); }
	&.active.icon-home  { #__icon-self(home,   "blanc", @color_qidigo_bleu); }

	&.icon-user  { #__icon-self(user,   "bleu", @color_bleu_fonce); }
	&.active.icon-user  { #__icon-self(user,   "blanc", @color_qidigo_bleu); }

	&.icon-phone  { #__icon-self(phone,   "bleu", @color_bleu_fonce); }
	&.active.icon-phone  { #__icon-self(phone,   "blanc", @color_qidigo_bleu); }

	&.icon-delete  { #__icon-self(delete,   "bleu", @color_bleu_fonce); }
	&.active.icon-delete  { #__icon-self(delete,   "blanc", @color_qidigo_bleu); }
}

