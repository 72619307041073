//
// Styles de base pour les inputs
// ==============================
//
// Incluant aussi les types bouton.
//

#base_focus_rule(@color: @color_secondary) {
	@_focus_glow: 0 0 @size_focus_glow @color;
	box-shadow: @_focus_glow;
	outline: 0;
}

#base_focus(@shadow: 0 0) {
	&:not(:disabled) {
		&.has-focus,
		&:active,
		&:focus,
		&.active {
			#base_focus_rule();
		}
	}
}

#base_input() {
	#base_focus();
	// Reset l'apparence
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	// Metrics
	display: inline-block;
	line-height: inherit;
	border: @size_input_border solid @color_gris2;
	text-decoration: none;
	padding: @size_input_padding_v @size_input_padding_h;
	vertical-align: middle;
	border-radius: @size_input_radius;
	color: @color_police;
	font-family: inherit;

	&::placeholder {
		#no-select;
		color: @color_grismoyen;
	}
}

#input() {
	&:extend(#_input all);
}

#_input {
	@_inside_shadow: inset 0px 1px 1px rgba(0,0,0,0.1);
	#base_input();
	#base_focus(@_inside_shadow);

	transition: box-shadow 0.2s ease-in;
	box-shadow: 0 5px @size_focus_glow rgba(0,0,0,0);
	font-size: @size_texto;
	background-color: @color_blanc;

	&:disabled {
		//The disabled opacity will come from the wrapper...
		//opacity: @disabled_opacity;
		background: @color_gris0;
	}

	&:not(:disabled) {
		&.has-focus,
		&:active,
		&:focus,
		&.active {
			border-color: @color_secondary;
			color: #000000;
		}
	}
}

.input .input--input,
input.input--input,
textarea {
	#input();
}

//
// Styles du *component* input.
// ============================
//

.input {
	// Pour les float labels.
	@_sz: @size_mini + .15rem;
	// Doit être en px pour éviter du fuzziness
	@__off: -@_sz - @size_input_padding_v*2.5;
	@_off: floor(unit(@__off) * @aal_font_size);

	position: relative;
	margin: @gutter auto;

	&, .input--input {
		width: 100%;
	}

	&.is-disabled {
		opacity: @disabled_opacity;
		transition: opacity @disabled_transition;
	}

	.input--input {
		position: relative;
		display: block;
	}

	&.is-invalid {
		.input--input, .input-select--fake {
			#base_focus_rule(@color_highlight);
			border-color: @color_highlight;
		}
	}

	// Pseudo-placeholder
	label>.input--label-text {
		z-index: 1;
		text-align: left;
		color: @color_grismoyen;
		#no-select;
		cursor: text;
		pointer-events: none;
		border: @size_input_border solid transparent;
		padding: @size_input_padding_v @size_input_padding_h;
		position: absolute;
		top: 0;
		left: 0;
		right: @size_input_padding_h;
		//bottom: 0;

		transform-origin: 0 0;
		transition:
			color     0.2s ease-out,
			top       0.2s ease-out,
			font-size 0.2s ease-out,
			transform 0.2s ease-out,
		;
		transform: translateZ(0);

		white-space: pre;
		overflow: hidden;
	}

	// On ré-invente le placeholder, yay!
	&.input-simple {
		&.is-not-empty .input--label-text {
			transition: opacity   0.1s ease-out;
			opacity: 0.001;
			pointer-events: none;
		}
	}

	&.input-imagepicker .input--label-text ,
	&.input-dateinput .input--label-text ,
	&.input-complex.is-not-empty .input--label-text {
		transform:
			translate(0, @_off)
			scale(unit(@_sz / @size_texto))
		;
		color: @color_police;
		cursor: pointer;
		pointer-events: initial;
	}

	html.no-csstransforms &.input-complex {
		&.is-not-empty .input--label-text {
			// Fallbacks...
			font-size: @_sz;
			top: @_off;
			// Au cas où...
			transform: translate(0,0) scale(1);
		}
	}
}

//
// Input icons
// ===========
//

.input.with-icon {
	// (/1.5 parce que visuellement ça sembe inégal à cause de la bordure)
	@with_icon_padding_left: @size_input_padding_h + @size_input_icon + @size_input_padding_h/1.5;

	label>.input--label-text, .input--input {
		padding-left: @with_icon_padding_left;
	}

	label::after {
		#no-select();
		pointer-events: none;
		display: block;
		content: "";
		position: absolute;
		top: @size_input_border;
		left: @size_input_padding_h;
		margin: auto;
		width: @size_input_icon;
		height: @size_input_height;
		background-image: none;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
		//outline: 1px solid #ccc;
	}

	// Icons definitions.
	.__icon(@name) {
		&.icon-@{name} label::after {
			background-image: url("~design/assets/forms/@{name}.svg");
		}
	}

	.__icon(search);
	.__icon(navigation);
	.__icon(calendar);
	.__icon(email);
	.__icon(password);
}

//
// Message d'erreur lié à un champ
// ===============================
//

.input--error-message {
	color: darken(@color_error, 33%);
	font-size: @size_mini;
	margin-left: @gutter/2;
}

//
// Date input
// ==========
//
// (Voir `&.input-dateinput .input--label-text`)
//
.input-dateinput {
	// Les margins à 0 c'est pour contrer l'effet provenant
	// de inputs imbriqués.
	.dateinput--selects {
		&>:first-child {
			margin-top: 0;
		}

		&>:last-child {
			margin-bottom: 0;
		}

		#screen-xs-min({
			#souplesse.horizontal();
			&>* {
				@_month: 45%;
				margin-top: 0;
				margin-bottom: 0;
				#souplesse.basis((100%-@_month)/2);
				&.dateinput--month {
					#souplesse.basis(@_month);
				}
				&:not(:first-child) {
					margin-left: @gutter / 2;
				}
				&:not(:last-child) {
					margin-right: @gutter / 2;
				}
			}
		});
	}
}

//
// File pickers
// ============
//

//
// Address partial
// ===============
//

.form-partial-address {
	// FIXME : Define maximum width ?
	// max-width: @screen-sm;

	&>.form--row {
	}
	.form--row.form-partial-address--first_row {
		.form-partial-address--address {
			width: 70% !important;
		}
		.form-partial-address--civic_number_ext {
			width: 30% !important;
		}
		#screen-sm-min({
			.form-partial-address--address {
				width: 80% !important;
			}
			.form-partial-address--civic_number_ext {
				width: 20% !important;
			}
		});
	}
	.form-partial-address--second_row {
		&>* {
			width: 50%;
		}
	}
	.form-partial-address--last_row {
		.input-city {
			width: 67%;
		}
		.form-partial-address--postal_code {
			width: 33%;
		}
	}
}
