.app--view>.page-index {
	.anticontainer();
	.layout.fill-vertical();
	.hitting.fullpage-background();

	&>div {
		text-align: center;
	}

	&>.index--searchbox {
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		.searchbox {
			#souplesse.horizontal();
			position: absolute;
			margin: auto;
			top: 40%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
		}
	}
}

.page-index>.index--searchbox {
	.container();
	// Allow typeahead to cover footer.
	z-index: 10;
	position: relative;
	max-width: @container-sm;

	// Assure un peu d'espace en bas.
	margin-bottom: @gutter * 2;

	.searchbox>h2 { display: none; }

	form {
		.form--compact;
		.form--compact.form--row;
		.hitting.mainbox();

		// Override le padding de mainbox.
		// Le formulaire gère la moitié du padding.
		padding: @gutter/2;

		.searchbox--q {
			#souplesse.grow(12);
		}

		.searchbox--localisationAlg {
			#souplesse.grow(4);
		}

		button {
			#souplesse.grow(0);
		}
	}
}

