//
// Mixin pour éviter de copier les règles partout.
//
#striped-list() {
	&:extend(#_striped-list all);
}

//
// Implémentation des listes.
//
#_striped-list {
	//
	// Lists
	//
	& > ul > li {
		position: relative;
		list-style-type: none;
		margin:0;
		padding: @gutter/2 @gutter;
		&:nth-child(odd) {
			background: @color_blanc;
			&:hover {
				background: darken(@color_blanc, 3%);
			}
		}
		&:nth-child(even) {
			background-color: @color_background;
			&:hover {
				background: darken(@color_background, 3%);
			}
		}
	}

	//
	// Navigation
	//
	nav,
	nav > ul,
	nav > ul > li,
	{
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: inline-block;
	}

	nav {
		float: right;
		margin-top: -0.3rem;
	}

	nav .button {
		@_size: @size_button_height - @size_button_padding_h/2;
		overflow: hidden;
		text-indent: -999em;
		width:  @_size;
		height: @_size;
		border: 0;
		background-position: center center;

		#action-buttons();
	}

}
