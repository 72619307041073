// Toujours un child page de activity...
.activity-child-page {
	// Icône de partage dans le portal de activity child.
	.group-header--link-share { a::before { #__icon-self("share", "", @color_blanc); } }

	// 
	ul.group-header--links {
		#header-links()
	}
}

//.page-group section
.group-page-child > section,
.group-page-child > *:not(section) > section {
	.section-box;
}

.group-page--layout {
	// Utilise un side-nav
	#side-nav();
	#side-nav.specialize(groups, soccer-inv);
	#side-nav.specialize(schedule, calendar);
}

// En-tête omniprésent avec la description.
.page-group > header {
	@_aspect-ratio: 300/1195*100%;
	position: relative;
	overflow: hidden;
	background: @color_blanc;
	border-radius:
		0
		0
		@size_general_radius
		@size_general_radius
	;
	border: 1/14rem solid @color_gris1;
	border-top: 0;
	margin-bottom: @gutter;

	h1 {
		margin: 0;
		padding-top: @gutter/2;
		font-size: @size_categorie0;
		//margin-bottom: @gutter;
		//#self-clear();
	}

	.group--cheap-preload {
		height: 0;
		position: absolute;
		visibility: hidden;

		// Images faussement cachées, permettant un preload.
		img {
			#no-select();
			position: absolute;
			width: 1px;
			height: 1px;
			visibility: hidden;
			opacity: 0.001;
		}
	}

	.group-header--description {
		position: relative;
		padding: @gutter;
		padding-top: 0;

		h1 a {
			color: inherit;
			text-decoration: inherit;
		}

		.is-raw-text {
			p {
				white-space: pre-line;
			}
		}

		:last-child {
			margin-bottom: 0;
		}

		.share-menu {
			position: absolute;
			right: 0;
			bottom: 100%;
			//width: 100%;
			padding-top: @_aspect-ratio;
		}
	}

	.group-logo {
		@_s: 132/1155 * 100%;
		// Aspect ratio hack
		padding-top: @_s;
		width: @_s;
		box-shadow: -2/14rem 0/14rem 7/14rem 0 rgba(0, 0, 0, 0.2);

		//#screen-sm-min({
			position: relative;
			float: left;
			z-index: 100;
			margin-right: @gutter;
			margin-top: -@_s / 2;
		//});
	}

	.group--informations {
		margin-top: @gutter/2;
		h5 {
			font-size: @size_categorie2;
			margin: 0;
		}
		ul.group--details li {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
		&>ul.group--details>li {
			padding-bottom: @base_line_height/2;
		}
		div.group--supervisors {
			ul {
				li {
					list-style-type: none;
					margin: 0;
				}
			}
		}
		#screen-sm-min({
			ul.group--details,
			div.group--supervisors {
				display: inline-block;
				vertical-align: top;
			}
			ul.group--details {
				width: 66%;
			}
			div.group--supervisors {
				width: 33%;
			}
			&>ul.group--details>li {
				float: left;
				width: 50%;
				&:nth-child(2n) {
					padding-left: @gutter/2;
				}
				&:nth-child(2n+1) {
					padding-right: @gutter/2;
					clear: left;
				}
			}
			&::after {
				content: "";
				display: block;
				clear: both;
			}
		});
	}
}

//
// Contenu
// -------
//

.page-group > section {
	border: 1px solid @color_container_border;
	background: @color_blanc;
	padding: @gutter;
	margin: 0;
	margin-bottom: @gutter;

	&>:last-child {
		margin-bottom: 0;
	}

	h2:not(:first-child) {
		margin-top: @size_courant;
	}

	&.group--organization {
		h3 {
			margin-bottom: 0.3em;
			border-bottom: 1px solid @color_container_border;
		}

		.organization-informations>div {
			margin-bottom: 0;
		}
		.organization-informations>div>div:not(:last-child) {
			margin-bottom: 1.2em;
		}
	}
}

.page-group.has-no-offers {
	.group--offers {
		min-height: 15rem;
	}
}

.group--offers {
	&.is-adding {
		#no-select();
	}
	position: relative;
}
