//
// Select boxes
// ============
//

.input-select {
	// Applique les couleurs par défaut.
	#input-select.color();
	margin: @gutter auto;
	width: 100%;

	@_button_size: 0.82em;
	position: relative;

	&>.input {
		margin-top: 0;
		margin-bottom: 0;
	}

	.is-disabled {
		.input-select--fake, .input-select--button {
			background: @color_gris0;
		}
	}

	// "Fake" select. Élément créé en JS (react) qui représente
	// visuellement le select (qui sera caché).
	.input-select--fake {
		// Styles de input.
		#input();
		#no-select();
		display: block;
		width: 100%;
		white-space: nowrap;

		// Pour positionner le stock dedans...
		position: relative;
		z-index: 0;

		// Pour éviter les dépassements
		overflow: hidden;
	}

	// Le bouton "\/" dans le fake select.
	.input-select--button {
		outline: 1/14rem solid transparent;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: @_button_size * 2.5;

		// La "flèche" vers le bas.
		&::after {
			pointer-events: none;
			content: "";
			display: block;
			margin: auto;
			position: absolute;
			top: 5%;
			bottom: 0;
			right: 0;
			left: 0;

			width: @_button_size;
			height: @_button_size;

			border-style: solid;
			border-width: 0;
			border-left-width: @_button_size/2;
			border-bottom-width: @_button_size/2;
			transform:
				translateY(-0.1em)
				scale(0.5)
				rotate(-45deg)
			;
		}
	}

	// Rend le vrai select invisible, mais toujours présent dans le DOM.
	// Overlay le select par-dessus notre fake.
	// En faisant ça:
	//   * On assure que le fake agit 100% comme un select
	//      * En ayant toute la dimension activable
	//      * En donnant un endroit où le contrôle natif doit afficher le menu
	select.input--input {
		border: 0;
		opacity: 0;
		color: transparent;
		background: transparent;

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
}

#input-select {
	.color(
		@text: @color_police,
		@button: @color_grismoyen,
		@background: @color_blanc,
		@border: @color_gris2,
		@focus: @color_secondary,
	) {
		&, .input-select--fake {
			color: @text;
		}

		.input-select--fake {
			background: @background;
			border-color: @border;
		}

		.input-select--button::after {
			border-left-color: @button;
			border-bottom-color: @button;
		}

		.input-select--button {
			// La transition vers le bouton a un léger dégradé
			// pour donner une coupure moins directe.
			background:
			linear-gradient(
			to right,
			fade(@background,   0%) 0%, 
			fade(@background, 100%) 8%, 
			fade(@background, 100%) 100%
			)
			;
		}

		// Étant donné que le select est plus bas dans la hérarchie, on a
		// du JS qui nous permet de faker l'état sélectionné.
		//.has-focus .input-select--fake .input-select--button,
		.has-focus .input-select--fake {
			#base_focus_rule(@focus);
			border-color: @focus;
		}
	}
}
