#badge() {
	&:extend(.badge all);
}

.badge {
	#no-select();
	cursor: inherit;
	@_color: @color_qidigo_rouge;
	@_spacing: 2/14rem;
	@_size: @size_mini;
	@_line_height: 0.8 * @base_line_height; 
	line-height: @_line_height;
	@_h_padding: @_line_height / 3;

	margin: 0;
	padding: 0;

	position: relative;
	color: @color_blanc;
	border-radius: 3/14rem;
	padding-left:  @_h_padding;
	padding-right: @_h_padding;
	padding-top: 1/14rem;
	padding-bottom: 1/14rem;
	background: @_color;
	font-size: @_size;
	#font-secondary;
	text-align: center;

	display: inline-block;
	vertical-align: middle;
}

// Badges in the header are *special*
#header .badge {
	font-size:   @size_mini * 0.8;
	line-height: @size_mini * 1.2;

	padding: 4/14rem;
	padding-top: 0;
	padding-bottom: 0;
	// Undo the text-substitution trick.
	text-indent: 0;
	
	//
	// Position
	//
	display:  block;
	position: absolute;
	// bottom-left corner at the middle
	bottom:50%;
	left:50%;
	// Anchor to its middle, then move to the right, then a bit up..
	transform: translateX(-50%) translateX(11/14rem) translateY(-7/14rem);
	// The badge is anchored by its middle bottom by the translateX
	// (Ensures a space will not wrap the badge)
	white-space: nowrap;

	#animation.fade-in();
}
