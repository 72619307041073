.alert-cart {
    list-style: none;
    margin: 0;
    padding: 0;
    color: black;
    overflow: auto;

    .message-box {
        margin: 0;
    }

    .success-message {
        #box.success();
        padding: 0;
    }

    .error-message {
        #box.error();
        padding: 0;
    }

    .warning-message {
        #box.warning();
        padding: 0;
    }

    .button-close {
        background: none;
        border: none;
        cursor: pointer;
    }
}

