//
// Icônes génériques à utiliser.
//

// Data implique la couleur "police".
.icon-data {
	&._icon-user { #__icon-self(user,   "police", @color_police); }
	&.icon-user { &:extend(.icon-data._icon-user); }
	&._icon-clock { #__icon-self(clock,   "police", @color_police); }
	&.icon-clock { &:extend(.icon-data._icon-clock); }
	&._icon-location { #__icon-self(location,   "police", @color_police); }
	&.icon-location { &:extend(.icon-data._icon-location); }
}
