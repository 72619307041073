.flash-message {
	padding-bottom: @gutter;
	& > div {
		// FIXME : Couleurs de la designer.
		background-color: @color_gris1;
		border: 1/14rem @color_grismoyen solid;
		border-radius: @size_general_radius;
		padding: @gutter/2;
	}
	&.is-success {
		& > div {
			background-color: #B9F7A8;
			border: 1/14rem #52C732 solid;
		}
	}
}
