.dashboard-reservations,
.dashboard-passes {
	font-size: 0.9em;
	* {
		vertical-align: top;
	}
	h4 {
		margin-bottom: 0.5ex;
	}
	ul {
		& > li {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}
	}

	& > ul > li {
		padding-top: @gutter/2;
		padding-bottom: @gutter/2;
		border-top: 1/14rem solid @color_gris1;
	}
	& > ul > li > ul > li {
	}

	.dashboard-passes--balance {
		display: block;
	}

	.button {
		text-align: center;
		display: block;
		width: 100%;
		padding-left: @gutter/2;
		padding-right: @gutter/2;
		&:not(:first-child) {
			margin-left: 0;
		}
		&:not(:last-child) {
			margin-bottom: @gutter/2;
		}
	}

	#screen-md-min({
		& > ul > li > ul {
			width: 100%
		}
		& > ul > li > ul > li {
			&:not(:last-child) {
				padding-right: @gutter;
			}
			display: inline-block;
		}
		.dashboard-passes--organization { width: 35%; }
		.dashboard-passes--subscriber { width: 19%; }
		.dashboard-passes--date { width: 25%; }
		.dashboard-passes--quantity {
			text-align: right;
			width: 7%;
		}
		.dashboard-passes--actions { width: 14%; }

		.dashboard-reservations--organization { width: 22%; }
		.dashboard-reservations--group { width: 22%; }
		.dashboard-reservations--subscriber { width: 21% }
		.dashboard-reservations--date { width: 20% }
		.dashboard-reservations--actions { width: 15% }

		.dashboard-reservations--actions,
		.dashboard-passes--actions {
			text-align: right;
			& > .button {
				display: block;
				margin: 0;
			}
		}
	});
}

