// FIXME : Provide #input() styles through a package.
// FIXME : Provide #translucent() helper through a package.
@import "~qidigo-design-dna/vars";

.imagepicker {
	position: relative;

	.imagepicker--wrapper {
		position: relative;
		display: inline-block;
		width: auto;

		&>* {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}

		.button {
			margin-top: @gutter/2;
		}
	}

	&.is-drop-target {
		// Utiliser un ::after pour le "drop target" permet
		// d'éviter un problème lorsque l'élément draggé change
		// de target DOM où on peut avoir un flash où is-drop-target
		// est rendu false.
		&::after {
			content: "";
			display: block;
			background-color: rgba(0,0,0,0.1);
			color: #ffffff;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: @size_general_radius;
			z-index: 10;
			text-align: center;
			line-height: 100%;
			vertical-align: middle;
		}
	}

	input[type=file] {
		display: none;
	}

	.imagepicker--preview {
		padding: 0;
		overflow: hidden;
		display: block;
		position: relative;
		width: 10rem;
		height: 10rem;
		cursor: pointer;

		.fake-image {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
}

.imagepicker--preview {
	border: 1px solid #cccccc;
}
