//
// icomoon
// =======
//
// Helpers pour les icônes icomoon.
//
// ATTENTION, ne pas simplement inclure les icônes en mixins partout.
// S'assurer d'une seule version par couleur.
//

#__icon-self(@name, @color_name, @color) {
	background-repeat: no-repeat;
	background-image: url("~icomoon/svg/@{name}.svg?fill=@{color}");
}
#__icon(@name, @color_name, @color, @prefix: icon) {
	&.@{prefix}-@{name} {
		#__icon-self(@name, @color_name, @color);
	}
}
