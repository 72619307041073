.dashboard-activities .dashboard-activities--nothing {
	#empty_box();
}

//
// "Header" utilisateur.
//
.dashboard-activities {
	@_default_color: @color_grisfonce;
	& > section > header {
		@avatar_size: @size_mega;
		#screen-sm-min({
			@avatar_size: @gutter * 2.3;
		});
		position: relative;
		overflow: auto; // Clearons le float.
		background: @_default_color;
		color: @color_blanc;
		#screen-sm-min({
			border-radius: @size_general_radius @size_general_radius 0 0;
		});

		.avatar-image {
			float: left;
			width: @avatar_size - 2/14rem;
			height: @avatar_size - 2/14rem;
			margin: @avatar_size / 5 @avatar_size / 2;
			border: 1/14rem solid rgba(255, 255, 255, 0.5);
		}

		h2 {
			#no-select();
			margin: 0;
			#screen-xs-max({
				font-size: @avatar_size * 0.4;
				line-height: @avatar_size;
				margin-top: @avatar_size / 5;
				margin-bottom: @avatar_size / 5;
			});
			#screen-sm-min({
				margin-top: 0.25 * @avatar_size;
				font-size: 0.38 * @avatar_size;
			});
		}

		nav > ul {
			&, & > li {
				padding: 0;
				margin: 0;
				list-style-type: none;
			}
			li {
				display: inline-block;
			}
			a {
				display: inline-block;
				text-decoration: none;
				#font-secondary();
				background-color: inherit;
				border-bottom: 0;
				color: @color_blanc;
				text-align: center;

				&.active, &:hover, &:active, &:focus {
					background: @color_blanc;
					color: @color_police;
				}
			}
		}
		#screen-sm-min({
			nav > ul {
				font-size: @size_mini;
				line-height: 1.8em;
				position: absolute;
				bottom: 0;
				left: @avatar_size * 2;

			}
			nav a {
				min-width: 8rem;
				border: 1/14rem solid @color_blanc;
				margin-right: 1rem;
				box-shadow: rgba(0, 0, 0, 0.3) 0.3em -0.2em 1em;
			}
		});
		#screen-xs-max({
			nav {
				clear: both;
			}
			nav ul > li {
				width: 50%;
			}
			nav a {
				width: 100%;
				line-height: 2.5;
			}
		});
	}
}

//
// Listing des groupes.
//
.dashboard-activities {
	.dashboard-activities--activities-list {
		@_border: 1/14rem solid @color_gris1;
		margin-bottom: @gutter;

		& > ul {
			&, & > li {
				list-style-type: none;
				margin: 0;
				padding: 0;
			}
		}
		& > ul > li {
			border: @_border;
			#screen-xs-max({
				border-left: 0;
				border-right: 0;
			});
			&:first-child {
				border-top: 0;
			}
			&:not(:last-child).is-collapsed { border-bottom: 0; }
		}
		// Bordure top de la section collapsée
		// Doit être appliquée au premier enfant et non au div collapsé sinon ça fait un effet weird
		// quand le collapse finit de fermer (calculer un height est dur en js/css)
		.dashboard-activities--group-sessions {
			& > *:first-child {
				border-top: @_border;
			}
		}

		& > ul > li.dashboard-activities--empty {
			#no-select();
			padding: @gutter;
			font-style: italic;
		}
	}

	// La boîte des infos
	.dashboard-activities--activities-list .dashboard-activities--group-informations {
		position: relative;
		& > .dashboard-activities--group-informations-triangle {
			position: absolute;
			margin: auto;
			top: 0;
			bottom: 0;
			height: @collapse_triangle_size;
			left: @gutter;
			#collapse-triangle(@color_police);
		}
		& > .dashboard-activities--group-informations-link {
			#screen-xs-max({
				display: none;
			});
			position: absolute;
			right: @gutter;
			margin: auto;
			top: 0;
			bottom: 0;
			font-size: @size_mini;
			line-height: @base_line_height;
			height: @base_line_height;
		}

		cursor: pointer;
		padding: @gutter/2 @gutter;
		padding-left: @gutter * 2.5;

		h3 {
			margin-bottom: 0.1em;
			line-height: 1.1;
			#screen-xs-max({
				line-height: 1.5;
			});
		}
		.dashboard-activities--group-informations-line {
			#screen-sm-min({
				& > * {
					display: inline-block;
				}
				& > *:first-child {
					min-width: (4/12)*100%;
				}
			});
			#screen-xs-max({
				& > * {
					display: block;
				}
			});
		}
		.durationfragment {
			&, & > li {
				margin: 0;
				padding: 0;
				list-style-type: none;
			}
			& > li {
				display: inline;
				&::after {
					content: " ";
					display: inline;
				}
			}
		}
		a, a.active, a:active, a:focus, a:hover {
			color: inherit;
			font-style: italic;
		}
	}
}

//
// Listing des sessions
//
.dashboard-activities {
	.dashboard-activities--group-sessions {
		@_icon_size: 1.2em;
		@_border: 1/14rem solid @color_gris1;
		line-height: 1.2;
		background: @color_background;
		ol {
			overflow: auto;
			margin: 0;
			padding: 0;
		}
		ol > li {
			margin: 0;
			padding: @gutter / 2;
			margin-left: @gutter * 1.7;
			&:not(:last-child) {
				border-bottom: @_border;
			}
			* {
				font-size: @size_courant;
			}
			#self-clear();
		}

		ul {
			&, & > li {
				margin: 0;
				padding: 0;
				list-style-type: none;
			}
		}
		h4, h5 {
			margin: 0;
			line-height: 1.3;
		}
		h4 {
			margin-bottom: 0.4em;
		}
		ul.dashboard-activities--session-informations > li {
			margin-bottom: 1em;
			#screen-sm-min({
				float:left;
				width: 85% / 4;
			});
			// Positionnement de l'icône...
			& > li:not(:first-child) {
				padding-left: @_icon_size * 1.5;
			}
		}
		ul.dashboard-activities--session-informations > .dashboard-activities--session-action {
			width: 15%;
		}
		.dashboard-activities--session-supervisor,
		.dashboard-activities--session-local,
		.dashboard-activities--session-schedule,
		.dashboard-activities--session-action {
		    padding-left: @_icon_size * 1.4;
			h5 {
				&::before {
					content: "";
					display: inline-block;
					position: relative;
					top: 0.1 * @_icon_size;
					width: @_icon_size;
					height: @_icon_size;
					margin-left: -@_icon_size * 1.4;
					margin-right: 0.4 * @_icon_size;
					background-size: contain;
				}

			}
		}

		// Icônes
		.dashboard-activities--session-supervisor h5::before {
			.icon-data.icon-user();
		}
		.dashboard-activities--session-local h5::before {
			.icon-data.icon-location();
		}
		.dashboard-activities--session-schedule h5::before {
			.icon-data.icon-clock();
		}

		// Positionnement de la zone avec les dates/heures
		.dashboard-activities--session-dates {
			& > *::after {
				content: " ";
				display: inline;
			}
			#screen-sm-min({
				& > * {
					display: block;
				}
			});
		}
	}
}
