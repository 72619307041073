.app--view>.page-login ,
.app--view>.page-forgot {
	& > div {
		.container();
		#screen-md-min({
			text-align: center;

			& > * {
				text-align: left;
				display: inline-block;
				vertical-align: top;
			}
		});
	}
	section.forgot,
	section.login ,
	section.register {
		.container();
		margin-top: @gutter;

		& > div {
			.hitting.mainbox();
			padding-bottom: 0;
			max-width: @container-md * 5/12;
		}
	}

	section.login ,
	section.register {
		#screen-md-min({
			& > div {
				width: @container-md * 5/12;
				margin-left: @gutter/2;
				margin-right: @gutter/2;
			}
			width: auto;
		});
	}

}

.app--view>.page-login ,
.app--view>.page-forgot ,
.app--view>.page-register {
	.anticontainer();
	.layout.fill-vertical();
	.hitting.fullpage-background();

	div.collapse, .form-buttons-bar, footer{
		margin-left: -@gutter;
		margin-right: -@gutter;
		padding-left: @gutter;
		padding-right: @gutter;
	}

	.form-buttons-bar {
		padding-top: @gutter;
		padding-bottom: @gutter;
	}
	.form-buttons-bar, footer {
		background: rgba(0, 0, 0, 0.15);
	}
	footer {
		font-size: @size_mini;
		padding-bottom: @gutter/2;

		.separator:first-child {
			margin: 0;
			margin-bottom: @gutter/2;
			border-color: #999A9B;
		}
	}
}

.app--view>.page-login {
	.login--forgot-password:extend(.form-helpers .stuck-under) {
		text-align: right;
		font-size: @size_courant;
	}
}
.app--view>.page-forgot {
	.forgot--result {
		background: @color_beige;
		padding: @gutter / 2;
		margin: 0;
		margin-bottom: @gutter;
		border: 1px solid @color_gris2;
		border-radius: @size_input_radius;

		*:last-child {
			margin-bottom: 0;
		}
	}
}
