//
// Visuel du *layout* général
// ==========================
// 

//
// Le layout est
// body
//   #content <- où react est ancré
// 	   #app   <- react-root
//       (.alert)
//       #header
//       .app--view
//         (Route Handler)
//       #footer
//

@import "styles";
@import "layout/header";
@import "layout/footer";

body {
	background: @color_background;
}

// Assure que la page remplie l'écran, au minimum.
// Ça assure un *sticky footer*.
body, #content, #app { min-height: 100vh; }
// Et assure un *layout* vertical.
body> #content> #app {
	#souplesse.vertical();
	// Où tout prends le moins d'espace nécessaire
	&>* {
		#souplesse.grow(0);
		#souplesse.shrink(0);
		position: relative;
	}
	// Sauf la vue.
	&>.app--view {
		#souplesse.grow(1);
		#souplesse.shrink(0);
	}
}

// Par défaut, *layout vertical* pour la view.
.app--view {
	#souplesse.vertical();
	#vh-hack(min-height, 95);

	&>* {
		// Le contenu devrait prendre tout l'espace disponible.
		min-height: 100%;
		// Avec, par défaut, un background de style cover.
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;

		.container;
		// Container par défaut;
		#screen-xs-max({
			padding-left: 0;
			padding-right: 0;
		});
	}
}

// L'alerte principale a une police réduite sur petits appareils.
// Généralement ça règle un problème de retour de ligne.
#app>.alert {
	#screen-xs-max({
		font-size: @size_courant;
	});

	&>.inner {
		.container;
	}

	&.header--nouveauclient {
		.alert.tip_pos(5rem);
		text-align: right;
	}
}

.alert.alert-old-browser {
	&>.inner { .container; }
	.alert.colorize(@color_qidigo_rouge);
	margin-bottom: -45px;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 2;

	.important-word {
		font-size: @size_categorie1;
	}

	a {
		color: @color_blanc;
		text-decoration: none;
		display: block;
	}
}

body > #content > #app > .app--overlay {
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0, 0.4);
}

#app--overlay {
	// Donne un style pour un "own overlay".
	// C'est voulu si on veut rendre le fond cliquable pour se déabarasser d'un overlay.
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	// Centre dans le app-overlay, l'item.
	// Utilise un transform translateY pour centrer verticalement.
	// Donne un max-height de 100vh;
	// Donne un scroll quand ça ne rentre pas.
	.item {
		position: absolute;
		margin: auto;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		max-height: 95vh;
		overflow: auto;
	}
}
