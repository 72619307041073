
.structure .list {
	.inline() {
		margin: 0;
		padding: 0;

		& > li {
			margin: 0;
			display: inline-block;
		}
	}
}
