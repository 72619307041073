@qidigo_checkbox_size: 22px;
@padding_top_checkbox: 1rem;
@margin_top_bottom_inputGroup: 3px;
@fixed_width_height_right_left: 30px;
@fixed_width_height_right_left2: 20px;
@position_top: 40%;
@margin_left_price: 0.15rem;
@padding_top_bottom: 2.08rem;
@hundred_percent: 100%;
@half_percent: 50%;
@_image_size: 8rem;
@_small_image_size: 4rem;
@_actions_size: 130/14rem;
@_price_size: 80/14rem;
@_chevron_size: 20/14rem;
@div_sammary_top:0.6rem;
@div_sammary_left:@padding_top_checkbox+@div_sammary_top;


.selected-offer-restrictions-warning {
  margin-top: 3rem !important;
  margin-bottom: 1rem !important;
  #box.warning();
}

#colonized-list() {
  &:extend(._colonized-list all);
}

._colonized-list {
  &>li {
    #colonized-list-item;
  }

  ul,
  ul li {
    display: inline;
  }

  .schedulefragment {
    li:not(:last-child) {
      &::after {
        content: ", ";
        display: inline;
      }
    }
  }
}

#colonized-list-item() {
  &:extend(._colonized-list-item all);
}

._colonized-list-item {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &>*:first-child {
    display: inline;

    &::after {
      display: inline;
    }
  }
}

.activity-page-child .activity-groups-list {
  .not-allowed,
  .not-allowed::after {
    cursor: not-allowed ;
  }

  .empty-list {
    margin-top: 1.42857143rem;
  }

  &>section {
    &>h2:first-child {
      border-bottom: 0;
      margin-bottom: 0;
      &.page-title-attendee{
        @media screen and (max-width: 767.9999px) {
          padding-left: 3rem;
          padding-right: 3rem;
          }
      }
    }
  }
}

.activity-group-fragment {
  #list-item-box();
  // Puisque tout est un lien.
  cursor: pointer;

  &_no-pointer {
    cursor: initial;
  }

  &>* {
    // Correction du padding droit pour le chevron.
    // Visuellement, ça marche même sans compenser pour la rotation.
    padding-right: @gutter*2 + @_chevron_size;
  }

  // Chevron en fin de ligne.
  .activity-group-fragment--view {
    #right-chevron(@_chevron_size);
  }

  .activity-group-fragment--title {
    display: flex;
    justify-content: space-between;
  }

  .activity-group-fragment--error-message {
    color: red;
  }

  .activity-group-fragment--remove-link {
    margin-left: 20px;
    white-space: nowrap;
    font-style: normal;

    &_disabled {
      color: grey;
      text-decoration: none;
      cursor: not-allowed;
    }
  }

  &.is-preview {
    .inputGroup {
      background-color: inherit;
    }
  }

  &>a {

    &:hover,
    &:active,
    &:focus,
    &.active,
    &.focus {
      .activit-group-fragment--view {
        &>*:first-child {
          // Couleur du chevron.
          border-color: @color_bleu_fonce;
        }
      }
    }
  }

  .activity-group-fragment--informations {
    padding-left: @_image_size + @gutter;
    padding-right: 3rem;
    #colonized-list();
  }

  .activity-group-fragment--infos {
    // Un lien n'est pas block par défaut.
    // On le met en block.
    display: block;
    // On ne veut pas les styles par défaut du lien.
    color: inherit;
    // Padding appliqué au lien.
    // Permet de visuellement bien gérer la navigation par clavier.
    padding: @gutter;
  }

  .activity-group-fragment--image {
    border: 1px solid @color_container_border;
    border-radius: @size_button_radius;
    overflow: hidden;
    float: left;
    margin-right: @gutter;
    margin-bottom: @gutter;
    //margin-left: -@_image_size - @gutter;

    // Dimensions de l'image
    &,
    .fake-image {
      width: @_image_size;
      height: @_image_size;

      .fake-image--image-element {
        background-size: cover;
      }
    }

    // Image en cas d'erreur.
    .fake-image.is-error {
      #asset.search-noimage();
      background: @color_qidigo_bleu;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  #screen-xs-max( {
      .activity-group-fragment--image {

        // Dimensions de l'image
        &,
        .fake-image {
          width: @_small_image_size; height: @_small_image_size;
        }
      }

      .activity-group-fragment--informations {
        padding-left: @_small_image_size + @gutter;
        padding-right: @_small_image_size;
      }
    }

  );

  .activity-group-fragment--price,
  .activity-group-fragment--actions {
    clear: both;
  }

  .activity-group-fragment--actions {
    text-align: center;

    &>* {
      display: block;
    }
  }

  #screen-sm-max( {
      .activity-group-fragment--price {
        ._colonized-list-item;
      }
    }

  );

  #screen-md-min( {
      .activity-group-fragment--informations {
        padding-right: @_actions_size + @gutter * 2 + @_price_size;
      }

      .activity-group-fragment--price,
      .activity-group-fragment--actions {
        position: absolute; right: @gutter; top: @position_top;
      }

      .activity-group-fragment--price {
        right: @gutter + @_actions_size + @gutter; width: @_price_size; text-align: center; & > * {
          display: block;
        }
      }

      .activity-group-fragment--actions {
        & > * {
          width: @_actions_size;
          padding-left: 0; padding-right: 0;
        }
      }
    }

  );

  .inputGroup {
    background-color: #fff;
    display: block;
    margin: @margin_top_bottom_inputGroup 0;
    position: relative;
  }

  .inputGroup label {
    padding: @padding_top_checkbox @gutter 0 @gutter;
    width: @hundred_percent;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;

    overflow: hidden;

  }

  .inputGroup label:before {
    width: @hundred_percent;
    height: @hundred_percent;

    content: "";
    position: absolute;

    opacity: 0;
    z-index: -1;
  }

  .inputGroup label:after {
    width: @fixed_width_height_right_left;
    height: @fixed_width_height_right_left+1px;
    content: "";
    border: 2px solid #d1d7dc;
    background-color: #fff;
    background-image: url("~design/assets/forms/checkmark-active.svg");
    background-repeat: no-repeat;
    border-radius: @half_percent;
    z-index: 2;
    position: absolute;
    right: @fixed_width_height_right_left;
    top: @position_top;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: @fixed_width_height_right_left2;
    background-position: center center;
  }

  .inputGroup input:checked~label {
    color: #fff;
    background-color: #194453;
  }

  .inputGroup input:checked~label:before {
    opacity: 1;
  }

  .inputGroup input:checked~label:after {
    background-color: #ed3f3a;
    border-color: #27708e;
  }

  .inputGroup input {
    width: @fixed_width_height_right_left;
    height: @fixed_width_height_right_left;
    order: 1;
    z-index: 2;
    position: absolute;
    left: @fixed_width_height_right_left2;
    top: @position_top;
    cursor: pointer;
    visibility: hidden;
  }

  .input-input-select {
    display: table;
    padding: 0px;
  }

  .input-select-plan {
    float: left;
    margin-top: -0.28rem;
    min-width: @padding_top_checkbox*20;
    max-width: max-content;
  }

  .right {
    float: right;
    right: @qidigo_checkbox_size;
    cursor: pointer;
    margin-top: -27px;
  }

  .right:active {
    border: none;
  }

  .activity-group-selected--price {
    display: -webkit-inline-box;
    margin-top: -@padding_top_checkbox;
    margin-left: @margin_left_price;
  }

  .group-fragment--restrictions {
    #box.warning();
  }

  .not-allowed,
  .not-allowed::after {
    cursor: not-allowed ;
  }

  .group-activity--offer-warning {
    padding-left: @padding_top_checkbox;
    color: @color_qidigo_rouge;
  }

  .group-activity--price-label {
    padding-left: @padding_top_checkbox;
  }

  .margin-1rem {
    margin: @padding_top_checkbox;
  }

  .padding-top-bottom {
    padding-top: @padding_top_bottom;
    margin: 0px;
  }
}

.btn-select-all-groups{
  margin-bottom: @padding_top_checkbox;
}

.span-checkbox-view-complete>span {
  margin-left: @gutter - @padding_top_checkbox;
}

@media screen and (max-width: 767.9999px) {

  section.page :not(header) section>h2:first-child,
  ._section-box>h1:first-child,
  ._section-box>h2:first-child,
  .static-page>div>section>h1:first-child,
  .static-page>div>section>h2:first-child,
  .static-page>section>h1:first-child,
  .static-page>section>h2:first-child,
  div.page-activity>section>h1:first-child,
  div.page-activity>section>h2:first-child,
  div.page-activity>*:not(section)>section>h1:first-child,
  div.page-activity>*:not(section)>section>h2:first-child,
  .activity-page-child>section>h1:first-child,
  .activity-page-child>section>h2:first-child,
  .activity-page-child>*:not(section)>section>h1:first-child,
  .activity-page-child>*:not(section)>section>h2:first-child,
  section.page-dashboard .dashboard--dashboard-box>.dashboard--child> :not(section)>section>h1:first-child,
  section.page-dashboard .dashboard--dashboard-box>.dashboard--child> :not(section)>section>h2:first-child,
  section.page-dashboard .dashboard--dashboard-box> :not(section)>section>h1:first-child,
  section.page-dashboard .dashboard--dashboard-box> :not(section)>section>h2:first-child,
  section.page-dashboard .dashboard--dashboard-box>section>h1:first-child,
  section.page-dashboard .dashboard--dashboard-box>section>h2:first-child,
  .group-page-child>section>h1:first-child,
  .group-page-child>section>h2:first-child,
  .group-page-child>*:not(section)>section>h1:first-child,
  .group-page-child>*:not(section)>section>h2:first-child,
  section.page-messages>section>h1:first-child,
  section.page-messages>section>h2:first-child,
  .organization-page-child>section>h1:first-child,
  .organization-page-child>section>h2:first-child,
  .organization-page-child>*:not(section)>section>h1:first-child,
  .organization-page-child>*:not(section)>section>h2:first-child {
    padding-left: @fixed_width_height_right_left+@fixed_width_height_right_left2;
  }

  .div-sammary {
    text-align: left;
    margin-top: -@div_sammary_top;
    margin-left:@div_sammary_left;
    span{
      font-weight: bolder;
    }
  }


  .right.activity-group-fragment {
    font-size: smaller;
    margin-top: 0px;
  }

  .activity-group-fragment .offer-fragment--restrictions {
    width: @hundred_percent;
    margin-left: 0rem;
  }
}

.title-h2-hidden
{
  display: none;
}


.div-sammary {
  span{
    font-weight: bolder;
  }
}
