//
// Souplesse.less
// ==============
//
// Wrapper around flexbox, using modernizr styles, to give logical layout
// styles, with good enough fallbacks when used on either tweeners or 2012
// syntax flexbox, and good enough fallbacks for no flexbox.
//
// Well, that's what I want it to be, but from the reality of it all, it
// uses other dirty tricks to make much more palatable layouting.
//
// Notes about table layout
// ------------------------
//
// When using the table layout helpers, *make sure that all DOM elements from
// `table` to `table-cell` are all laid-out as table-something*. Doing
// otherwise, the rows will all be independent, making a table wonky.
//
//

#souplesse(){.todo() {
	outline:10px solid red !important;
}}

#souplesse() {
	._base_flex() {
		display: flex;
		html.no-flexbox body.env-development & {
			#souplesse.todo();
		}
	};

	.horizontal() {
		#souplesse._base_flex();
		flex-direction: row;
		&>* {
			#souplesse.grow();
		}
	}

	.vertical() {
		#souplesse._base_flex();
		flex-direction: column;
		&>* {
			#souplesse.grow();
		}
	}

	.baseline() {
		align-items:   baseline;
		align-content: baseline;
	}
	.middle() {
		align-items:   center;
		align-content: center;
	}
	.middle-one() {
		&>* {
			margin:auto;
			#souplesse.grow(0);
		}
	}
	.stretch() {
		align-items:   stretch;
		align-content: stretch;
	}
	.all-end() {
		justify-content: flex-end;
	}

	.self-middle() {
		align-self: center;
	}

	.self-end() {
		align-self: flex-end;
	}

	.basis(@b: 100%) {
		flex-basis: @b;
	}

	.grow(@g: 1) {
		flex-grow: @g;
	}

	.shrink(@s: 1) {
		flex-shrink: @s;
	}

	.wrap(@v: nowrap) {
		flex-wrap: @v;
	}

	.table() {
		display: table;
		// As a block-like layout, it should act
		// like a block and take full width.
		width: 100%;
	}
	.table-row() {
		display: table-row;
	}
	.table-rowgroup() {
		display: table-row-group;
	}
	.table-cell() {
		display: table-cell;
	}
}
