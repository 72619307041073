//
// Mixins utiles
// =============
//

// 
// Couleur translucide
// -------------------
//
// ### Utilisation
//
//     .cls {
//        #translucent(border-color, #f00, 0.5);
//     }
//
#translucent(@prop, @color, @opacity) {
	@{prop}: rgba(red(@color), green(@color), blue(@color), @opacity);
}

//
// Retire la possibilité de sélectionner
// -------------------------------------
//
#no-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
}

//
// Remplace les `vh`
// -----------------
//
// Contourne le problème où les vh sont instable en mobile à cause
// des barres de navigation qui disparaissent ou revienent.
//
#vh-hack(@prop, @vh) {
	@_urlbar: 1 - 9/100;
	@media screen {
		@{prop}: @vh * 1vh;
	}
	.__vhhack(@width, @height) {
		@media screen and (max-device-aspect-ratio: @width/@height) {
			@{prop}: calc(~"100vw * (@{height}/@{width}*(@{vh}/100)) * @{_urlbar}");
		}
	}
	// Les ratios ici doivent aller du plus grand au plus petit.
	.__vhhack( 3,  4); // 0.75
	.__vhhack( 2,  3); // 0.66_
	.__vhhack(10, 16); // 0.62_
	.__vhhack( 9, 16); // 0.56_
}

//
// Makes an element self-clear.
// This won't work with ie9.
//
#self-clear() {
	&:extend(._self-clear all);
}
._self-clear {
	&::after {
		display: block;
		content: "";
		clear: both;
	}
}
