.fake-image,
.fake-image--image-element {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.fake-image {
  display: inline-block;
  overflow: hidden;
  position: relative;
}
.fake-image .fake-image--image-img {
  display: none;
}
@media print {
  .fake-image .fake-image--image-img {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.fake-image .fake-image--image-element {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fake-image.is-loading::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
