// HAS to be unit-less
// 1200 is @screen-lg
@cart_max_width: 1920 - 1200;

@media print {
	.cart.panel {
		display: none;
	}
}

.cart {
	h2 {
		line-height: @size_button_height;
		padding-bottom: @gutter / 2;
		border-bottom: 1 / 14rem solid @color_blanc;
		&::before {
			content: "";
			background-image: url("~design/assets/menu/cart.svg");
			display: inline-block;
			width: 20 / 14rem;
			height: 20 / 14rem;
		}
		& > span {
			padding-left: 1rem;
		}
	}
	.cart-header {
		button {
			float: right;
			margin: 0;
			#screen-xxs-only({
				display: none;
			});
		}
	}
	color: @color_blanc;

	& > * {
		background: @color_bleu_fonce;
	}


	// On veut un minimum height agréable
	.loading {
		.loading--wrapper {
			top: 4em;
		}
	}

	& > div {
		padding: @gutter;
	}

	.panel--cart-items .gm-scroll-view {
		#listtable-base();
	}
	.gm-scrollbar .thumb {
		background-color: rgba(255, 255, 255, 0.6);
	}

	.empty-state {
		background: none;
	}
}

body > #content > #app > #cart--holder {
	position: sticky;
	top: 0;
	right: 0;
}

.cart.panel {
	@_bar_height: 64 / 14rem;

	position: absolute;
	top: 0;
	#screen-xs-max({
		left: 0;
	});
	right: 0;
	bottom: -100vh; // This may cause glitchyness on safari mobile...
	& > * {
		padding-bottom: @_bar_height;
	}

	& > div {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
		height: 100%;

		& > * {
			flex-grow: 0;
		}
		& > .panel--cart-items {
			flex-grow: 1;
			overflow: auto;
		}
	}
}


.cart ul.cart-items {
	border-collapse: collapse;
	ul.cart-item {
		border-bottom: 1/14rem solid @color_blanc;
		position: relative;
		li {
			vertical-align: top;
		}
		&:last-child{
			border:none;
		}
	}

	.cart-item--image .fake-image {
		@_s: 5em;
		width: @_s;
		height: @_s;
		border-radius: @size_general_radius;
		border: 1/14rem solid @color_container_border;

		&.is-error {
			#asset.search-noimage();
		}
	}

	.cart-item--name {
		#font-secondary;
		font-size: @size_categorie2;
	}

	.cart-item--organization {
		margin-bottom: @gutter/2;
		a.organization-link {
			color: @color_blanc;
		}
	}

	.cart-item--price {
		font-weight: bold;

		.cart-item--taxes-label {
			font-weight: normal;
			font-size: @size_mini;
		}
	}

	.cart-item--end-moment {
		font-style: italic;
		color: @color_gris2;
		font-size: @size_courant;
	}

	.cart-item--details {
		ul {
			padding-bottom: 1em;
		}
	}

	.cart-item--groups {
		a {
			color: @color_blanc;
			&::before {
				border-left-color: @color_blanc;
			}
		}
	}

	.cart-item--subscriber {
		#screen-md-min({
			text-align: center;
		});

        .cart-item--subscriber-name-separator {
            #screen-md-min({
                display: none;
            });
        }

        .cart-item--subscriber-name {
            font-weight: normal;
            &::before {
                #screen-sm-max({
                    content: " ";
                })
            }
        }

		&>span {
			font-weight: bold;
            #screen-sm-max({
                display: inline !important;
            });

			#screen-md-min({
				white-space: nowrap;
			});

			&>span {
				#screen-md-min({
					display: block;
				});
				font-weight: normal;

				&::before {
					#screen-sm-max({
						font-weight: bold;
					});
				}
			}
		}
	}

	.cart-item--main {
		#screen-md-min({
			width: 80%;
		});

		// Spacer pour le bouton ×.
		// Ça fonctionne uniquement parce qu'on connaît déjà
		// la grosseur de ce bouton et qu'il est placé en haut
		// à droite de la ligne. C'est un trick, pas trop dirty.
		#screen-sm-max({
			&::before {
				content: "";
				display: block;
				width:  2.5em;
				height: 2.5em;
				float: right;
			}
		});
	}

	.cart-item {
		@_s: 5em;
		#screen-sm-max({
			& > li {
				&.cart-item--image {
					.fake-image {
						width: @_s;
						height: @_s;
						float: left;
						margin-right: @gutter/2;
					}
				}
			}
		});
		#screen-sm-only({
			& > li {
				&:not(.cart-item--image) {
					padding-left: @_s + 1.5*@gutter;
				}
			}
		});
	}

	.cart-item--remove,
	.cart-reservation--remove {
		@_s: 2em;
		#debuttonize();
		&:hover, &:focus, &:active {
			color: @color_qidigo_bleu;
		}
		position: relative;
		overflow: hidden;
		text-indent: -999em;
		width: @_s;
		height: @_s;
		&::before {
			font-weight: normal;
			text-indent: 0;
			font-size: 1.5em;
			display: block;
			content: "×";
			line-height: 1.25em;
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	.cart-reservation {
		ul, li {
			margin: 0;
			padding: 0;
			display: inline;
			list-style-type: none;
		}
	}
	.cart-reservation--remove {
		display: inline-block;
		background: @color_bleu_fonce;
		color: inherit;
		margin-left: 1em;
		padding-left: 1ex;
		padding-right: 1ex;
	}

	.cart-reservations--title {
		font-weight: bold;
	}

	.cart-item--remove {
		#screen-sm-max({
			position: absolute;
			top: @gutter/2;
			right: @gutter/2;
		});
	}

}

// While not strictly cart-related, this is to better group what's affected by its opening.

@cart-open: 0.4s ease-out;
@cart-close: 0.3s ease-in;

.app--view, #footer {
	position: relative;
	transition: transform @cart-close;
	.with-cart > & {
		transition: transform @cart-open;
		#screen-sm-max({
			transform: translateX(-100vw);
		});
		#screen-sm-min({
			transform: translateX(-50vw);
		});

		//
		// Ahoy hoy, here be maths.
		//                           /-> width of viewport (x)
		//                          /       /-> width of resolution to start
		//                         /       /          /-> width of cart
		// translateX = slope * (100vw - 1400px) + (-700px)
		//                \-> (translateX[min] - translateX[max]) / (100vw[min] - 100vw[max])
		//                    (    -700px      -       0        ) / (   1400    -   2800    )
		//
		// translateX = (-700/(1400-2630)) * (100vw - 1400px) - 700px
		//

		// ALL unit-less!
		@_cart_width:  @cart_max_width;
		@_vw_min:     (@_cart_width*2);
		@_vw_max:     (@_cart_width*4);

		@media screen and (min-width: ~"@{_vw_min}px") {
			// Here, `100vw` is equivalent to `x`; which is width in pixels of the viewport.
			transform: ~"translateX(calc(
				(-@{_cart_width} / (@{_vw_min} - @{_vw_max})) * (100vw - @{_vw_min}px) - @{_cart_width}px
			))";
		}

		// And here, clamp to the maximum requied viewport.
		@media screen and (min-width: ~"@{_vw_max}px") {
			transform: translateX(0);
		}
	}

}

#cart--holder {
	& > .cart.panel {
		overflow: hidden;
	}

	& > .cart.panel > * {
		transition: transform @cart-close;
		#screen-sm-max({
			transform: translateX(100vw);
		});
		#screen-sm-min({
			transform: translateX(50vw);
		});
	}
	.with-cart > & > .cart.panel > *{
		transition: transform @cart-open;
		transform: translateX(0);
	}
	.cart.panel > * {
		#screen-xs-max({
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		});
		#screen-sm-min({
			width: 50vw;
			max-width: @cart_max_width * 1px;
		});
	}
}

#cart--holder {
	// NOW, this is the weirdest thing ever, but I swear it makes sense.
	// The idea is that the *holder* itself is behind the page, so you can
	// interact with the page while the cart is hidden...
	z-index: 10;
	// And push it back up when the cart is open.
	.with-cart & {
		z-index: 30;
	}
}

.app--view, #footer {
	z-index: 20;
}
.with-cart #footer {
	z-index: 9;
}

.cart-button-bar {
    #screen-sm-max({
        margin-bottom: @gutter;
        .loading {
            width: 100%;
            display: block;
        }
        text-align: right;
        .form-buttons-bar__before > *.button,
        & > *.button {
            display: block;
            width: 100%;
            margin-left: 0 !important;
            margin-right: 0;
            &,
            &:not(:last-child) {
                margin-top: @gutter/2;
            }
        }
    });
}
