.groupfragment {
	// Titre des "colonnes"
	h4, h5 {
		margin: 0;
	}
	// Toutes les listes du fragment sont déstylisées partiellement.
	li {
		margin: 0;
		list-style-type: none;
		padding: 0;
	}
	*, h5 {
		font-size: @size_courant;
	}
	h4 {
		font-size: @size_texto;
	}

	// Liste directement enfants.
	// Les floats c'est pour faire une grid à la bootstrap.
	#screen-sm-min({
		margin: 0;
		padding: 0;
		@_padding: @gutter / 2;
		padding: @_padding;
		&:not(:last-child) {
			border-bottom: 1/14rem solid @color_grismoyen;
		}
		h4 {
			margin-bottom: 1ex;
		}

		&>ul {
			// Multiplier pour dire sur 24 combien de place prendre.
			@_grid: 100%/24;
			margin: 0;

			&>li {
				padding: 0 @gutter/2;
				float: left;
			}

			// Sauf les dates
			&>li:not(.groupfragment--dates) {
				text-align: center;
			}

			&>li {
				width: @_grid * 3;
				min-height: 1em;
			}
			.groupfragment--dates {
				width: @_grid * 5;
			}
			.groupfragment--schedule {
				width: @_grid * 6;
			}
			.groupfragment--action {
				width: @_grid * 4;
			}

			// On prend le minimum possible pour le bouton inscription.
			.groupfragment--action {
				float: right;
			}

			&, &::after {
				content: "";
				display: block;
				clear: both;
			}
		}
	});
	#screen-xs-max({
		margin: 0;
		padding: 0 @gutter/2;
		&:not(:last-child) {
			border-bottom: 1/14rem solid @color_grismoyen;
		}

		padding-bottom: @gutter/2;
		padding-top: @gutter/2;
		&>ul {
			.groupfragment--dates {
				ul li {
					display: inline;
					&:not(:last-child)::after {
						content: " ";
						display: inline;
					}
				}
			}
		}
		:not(.groupfragment--schedule) {
			h5 {
				display: inline;
				&::after {
					display: inline;
				}
			}
		}
		.groupfragment--action {
			margin-top: @gutter/2;
			font-size: @size_texto;
		}
	});
}
