.gender-restrictions {
  display: inline-flex;
}
.gender-restrictions .gender-restrictions--tooltip-text {
  width: fit-content;
  max-width: 25rem;
  background-color: #27708EFF;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem;
  z-index: 999;
  position: absolute;
  top: 50%;
}
@media (max-width: 500px) {
  .gender-restrictions .gender-restrictions--tooltip-text {
    top: 10% !important;
  }
}
.gender-restrictions .for-pass-modal {
  top: 60%;
}
@media (max-width: 500px) {
  .gender-restrictions .for-pass-modal {
    top: 65% !important;
  }
}
