.static-page {
	& > div > section,
	& > section {
		.section-box;

		& > ul:last-child {
			margin-bottom: 0;
		}
	}
	padding-top: @gutter;
	padding-bottom: @gutter;
}
