@keyframes fade_in_animation {
	0% {
		opacity: 0.001;
	}

	100% {
		opacity: 1;
	}
}

@keyframes loading_spinner_animation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

#animation {
	.spinner(@duration: 1.2s) {
		animation: loading_spinner_animation @duration infinite linear;
	}

	.fade-in(@duration: 0.3s) {
		animation: fade_in_animation @duration linear;
	}
}
