@media print {
	.dashboard-invoice {
		h1:first-child {
			display: none;
		}
		.dashboard-invoice--warning {
			display:none;
		}
	}
	
	.form-buttons-bar {
		display: none;
	}

	.route-_-dashboard-invoices-_id {
		#header > div.container > h1{
			display: none;
		}
	}

	.invoice-page--important a {
		color: inherit;
		text-decoration: inherit;
	}
}

.dashboard-invoice--warning {
	#box.warning();
}

.invoice-page {
	// Retire la marge initiale donnée par les éléments headers.
	margin-top: -@gutter;

	* {
		line-height: 1.5;
		vertical-align: top;
	}
	h1, h2, h3, h4, h5 {
		margin-top: @gutter;
		margin-bottom: 0.2ex;
	}

	h2, h3, h4, h5, h6 { font-size: inherit; }

	.invoice-page--legal_text {
		white-space: pre-line;
	}

	.invoice-page--legal_contract_text {
		white-space: pre-line;
	}

	.invoice-organization--logo{
		height:100px;
		width:100px;
		display:block;
	}

	.fake-image, .fake-image--image-element{
		background-position: left;
	}

	header {
		ul {
			&, & > li {
				margin: 0;
				padding: 0;
				list-style-type: none;
			}
		}
		& > * {
			margin-bottom: 2ex;
			padding-right: 2ex;
			@media print {
				display: inline-block;
				width: 100%/3;
			}
			#screen-md-min({
				display: inline-block;
				width: 100%/2;
			});
			#screen-lg-min({
				width: 100%/3;
			});
		}

		.invoice-page--due_date,
		.invoice-page--refunding {
			font-weight: bold;
			* > * {
				font-weight: normal;
			}
		}
		margin-bottom: @gutter;
	}
	footer {
		margin-top: @gutter;
		text-align: right;
		& > * {
			text-align: left;
			@media print {
				display: inline-block;
				width: 100%/2;
			}
			#screen-md-min({
				display: inline-block;
				width: 100%/2;
			});
		}
	}

	.invoice-page--amounts {
		h2 {
			font-size: 1.6em;
		}
		ul {
			padding: 0;
			&, & > li {
				display: block;
				list-style-type: none;
				margin: 0;
			}
		}
		& > ul > li > ul > li {
			padding-left: @gutter;
			margin-left: -@gutter/2;
			margin-right: -@gutter/2;
			&:last-child {
				border-bottom: 0;
				padding-bottom: 0;
			}
		}
		ul {
			& > li {
				padding: @gutter/2 @gutter/2;
				overflow: auto;
				@_border: 1/14rem solid @color_gris2;
				clear: both;
				&:first-child {
					margin-top: @gutter/2;
					border-top: @_border;
				}
				position: relative;
				border-bottom: @_border;
			}
		}
		& > ul > li {
			&:not(.amounts__cash_gap):not(.amounts__receipt)
			> span:not(.value) {
				font-weight: bold;
			}

			&.amounts__receipt.failed {
				color: @color_error;
			}
		}
		& > ul > li.amounts__cash_gap {
			font-style: italic;
			&::before, &::after {
				display: inline;
			}
			&::before { content: "("; }
			&::after { content: ")"; }
		}
		ul li > span.value {
			float: right;
		}
	}

	margin-bottom: @gutter;

	.table-wrapper {
		width: 100%;
		max-width: 100%;
		@media screen {
			overflow: auto;
		}
	}
	.invoice-table {
		font-size: 0.9em;
		& > thead > tr,
		.invoice-row {
			& > td, & > th {
				padding: @gutter/2;
			}
		}
		table {
			&, td {
				background: @color_blanc;
			}
		}
	}

	.invoice-table-cell {
		text-align: left;
	}

	table {
		table {
			margin: 0;
			tr > th, tr > td {
				padding-top: @gutter / 3;
				padding-bottom: @gutter / 3;
			}
		}
		width: 100%;
		h1,h2,h3,h4,h5,h6 {
			font-family: inherit;
			font-weight: inherit;
			margin: 0;
			padding: 0;
		}
		tr {
			th {
				white-space: nowrap;
			}
			td, th {
				border: 0;
				vertical-align: top;
				text-align: center;
			}
			td {
				background: @color_gris0;
				border-top: 1/14rem solid @color_gris2;
			}
		}

		.invoice-row--duration{
			p {
				display: inline
			}
		}
	}
	.invoice--column-description {
		width: 60%;
		text-align: left;
	}
	.invoice--column-description-reservation {
		text-align: left;
	}
	.invoice--column-quantity {
		width: 15%;
	}
	.invoice--column-subscriber {
		width: 40%;
	}
	.invoice-row--column-details {
		width: 60%;
	}
	.invoice-row--column-schedule {
		width: 40%;
	}
	.invoice-row--column-date {
		text-align: left;
	}
	.invoice-row--column-hours {
		text-align: left;
	}
	.invoice-row--column-location {
		text-align: left;
	}
	.invoice--column-other_fees_subtotal {
		text-align: right;
	}

	table {
		.is-date {
			white-space: nowrap;
		}
		.is-money, .is-number {
			white-space: nowrap;
			text-align: center;
		}
	}
	.invoice-row--informations {
		& > span {
			display: block;
			padding-top: @gutter / 2;
			padding-bottom: @gutter / 3;
		}
	}
	.invoice-row--groupname {
		& > span {
			display: block;
			padding-top: @gutter / 2;
			padding-bottom: @gutter / 3;
		}
		text-align: left;
	}
	.invoice-row--groups {
			margin-bottom: @gutter / 2;
	}

	.invoice-row--description {
		text-align: left;
	}

	.invoice-row--other_fees_subtotal {
		text-align: right !important;
	}

	.invoice-row--additional-data > td {
		@media print {
			border-top: 0;
			border-left: 2ex solid @color_gris0;
			padding-left: @gutter;
		}
	}
	.schedulefragment {
		&, li {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}
	}
}

.dashboard-invoice {
	.checkout-confirmation > .checkout-confirmation--blurb {
		#box.success();
	}
	@media print {
		color: black;
		.checkout-confirmation > .checkout-confirmation--blurb,
		.checkout-installment-warning > .checkout-installment-warning--blurb,
		.checkout-failed-receipts-warning > .checkout-failed-receipts-warning--blurb,
		.error-message,
		.box-message {
			display: none;
		}
	}

	.checkout-installment-warning > .checkout-installment-warning--blurb,
	.checkout-failed-receipts-warning > .checkout-failed-receipts-warning--blurb {
		#box.warning();
	}
}

.adjustment_label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 55%;
	display: inline-block;
}