//
// Separator
// =========
//
// Se présente soit comme un filet, soit comme un filet ayant
// un mot au centre, le coupant.
//

.separator {
	#no-select();

	.separator-line {
		border: 0;
		border-bottom: 0.1rem solid @color_grismoyen;
	}

	div& {
		#souplesse.horizontal();
		#souplesse.middle();

		text-align: center;

		.separator--before, .separator--after {
			position: relative;
			top: 0.05rem;
			&:extend(.separator .separator-line);
		}

		.separator--text {
			#souplesse.grow(0);
			padding: 0 @gutter/2;
			white-space: nowrap;
		}
	}

	hr&, div& .separator--before, div& .separator--after {
		margin-top: @gutter;
		margin-bottom: @gutter;
	}

	hr&, div& .separator--before {
		margin-left: -@gutter;
	}

	hr&, div& .separator--after {
		margin-right: -@gutter;
	}

	hr& {
		display: block;
		&:extend(.separator .separator-line);
	}
}

