.pager {
	#no-select();
	@_size: 1.5em * 1.5;
	@_border: @size_button_border;

	margin: @gutter 0;
	&:last-child {
		// Assume un padding-bottom.
		margin-bottom: 0;
	}

	ul {
		display: inline-block;
	}

	ul,
	ul li,
	ul li a {
		margin: 0;
		padding: 0;
	}

	ul li {
		position: relative;
		z-index: 0;
		list-style-type: none;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		border: @_border solid @color_gris1;

		&:not(:first-child) {
			margin-left: -1 * @size_button_border;
		}

		&:not(:first-child) {
			border-left: @_border solid @color_gris1;
		}

		&.is-selected {
			z-index: 1;
			border-color: @color_qidigo_bleu;
		}

		&:first-child {
			border-radius:
				@size_general_radius
				0
				0
				@size_general_radius
			;
		}

		&:last-child {
			border-radius:
				0
				@size_general_radius
				@size_general_radius
				0
			;
		}
	}

	a, span {
		display: inline-block;
		width: @_size;
		line-height: @_size;
		background-color: @color_blanc;

		&, &:focus, &:hover, &:active {
			text-decoration: none;
		}
	}
	.pager--ellipsis {
		color: @color_grismoyen;
	}

	li:not(.is-selected) {
		a:focus,
		a:hover,
		a:active {
			background: @color_gris2;
			color: @color_qidigo_bleu;
		}
	}

	.is-selected a {
		cursor: default;
		background: @color_qidigo_bleu;
		color: @color_blanc;
	}
}

