//
// Définition de styles réutilisables
// ==================================
//

.links {
	.insensitive() {
		text-decoration: inherit;
		//color: inherit; //??
	}
}

// Structures communes de layouts.
.layout {
	// Remplit à peu près l'écran. C'est assez bon pour nos besoins.
	// Si on a besoin de plus ou moins, on peut ajuster.
	.fill-vertical(@v: 95) {
		#vh-hack(min-height, @v)
	}
}

// Stylisations visuelles.
.hitting {
	.fullpage-background() {
		background-color: darken(@color_qidigo_bleu, 10%);
		background-image:
			linear-gradient(rgba(35, 31, 32, 0.498039),
			rgba(35, 31, 32, 0.498039)),
			// Fallback
			url("~design/assets/backgrounds/main/bg1.jpg");
		;

		.__bg(@i, @color) {
			.bgselect@{i} & {
				background-color: @color;
				background-image:
				linear-gradient(rgba(35, 31, 32, 0.498039),
				rgba(35, 31, 32, 0.498039)),
				url("~design/assets/backgrounds/main/bg@{i}.jpg");
				;
			}
		}

		// Ne pas oublier d'ajuster le max dans `components/qidigo/app.js`.
		.hitting .fullpage-background .__bg(1, #5a321f);
		.hitting .fullpage-background .__bg(2, #607074);
		.hitting .fullpage-background .__bg(3, #586931);
		.hitting .fullpage-background .__bg(4, #9a9190);
		.hitting .fullpage-background .__bg(5, #8b8a8b);
		.hitting .fullpage-background .__bg(6, #808c8f);
		.hitting .fullpage-background .__bg(7, #8c8c8e);

		// TODO : Helper pour auto-créer des classes
		// TODO : Ajustements par background possible.
		background-position: 46% 20%;
		background-repeat: no-repeat;
		background-size: cover;
	}

	._basebox() {
		margin: auto;
		border: 1px solid @color_grismoyen;
		#translucent(background-color, @color_blanc, 0.85);
		padding: @gutter;
		width: 100%;
	}

	// A cool looking box.
	.widebox() {
		.hitting._basebox();
		margin-top: @gutter;
		margin-bottom:@gutter;
	}

	// Has more opinions.
	.mainbox() {
		.hitting._basebox();

		&>h1 {
			#no-select();
			text-align: center;
		}
	}
}

//
// Généralement utilisé avec les formulaires.
//
// Ramasse l'espace gauche/droite/bas pour contrer les marges
// d'une boîte qui circonscrit un formulaire.
// 
// S'utilisent bien avec `<ButtonsBar>` de `qidigo-form`.
// 
//     <ButtonsBar className="buttons-channel" />
//
.buttons-channel {
	margin: -@gutter;
	margin-top: 0;
	padding: @gutter;
	background: @color_gris1;
	border-top: 1/14rem solid @color_blanc;
}

//
// Titre des pages / sections
// Généralement utile pour les side-nav.
//
section.page :not(header) {
	section > h2:first-child,
	section > h1:first-child {
		.section-header;
	}
}

//
// "Boîte" de section.
//
// Généralement appliquée aux <section> d'une <Page>.
//
.section-box() {
	&:extend(._section-box all);
}

// Implémentation
._section-box {
	overflow: auto;
	position: relative;
	border: 1px solid @color_container_border;
	padding: @gutter;
	background: @color_blanc;
	@media print {
		border: none;
	}
	//&:not(:last-child) {
		margin-bottom: @gutter;
	//}
	// TODO : Déterminer comment on gère ça.
	//#screen-xs-max({
	//	padding-left: 0;
	//	padding-right: 0;
	//});
	& > h1:first-child,
	& > h2:first-child {
		.section-header;
	}
}

.section-header() {
	&:extend(._section-header all);
}
._section-header {
	line-height: 1.48;
	margin: 0;
	#screen-xs-max({
		text-align: center;
	});
	//#screen-sm-min({
		margin-left: -@gutter;
		margin-right: -@gutter;
	//});
	margin-top: -@gutter;
	margin-bottom: @gutter;
	padding: @gutter/2 @gutter;
	font-size: @size_categorie0;
	color: @color_bleu_fonce;
	border-bottom: 1/14rem solid @color_gris1;
}

//
// Donne une indication de focus à gauche de l'élément, de grosseur @size/2;
// On donne généralement le padding de l'élément à ce mixin.
//
// FIXME : Ce mixin DÉTRUIT un box-shadow ou des transitions custom.
// Ajouter des paramètres nommés @transitions @active_transitions et les prepender devrait marcher.
//
#left-focus-guide(@size, @color: @color_qidigo_bleu) {
	box-shadow: 0 0 transparent inset;
	transition: box-shadow 0.4s ease-in;
	&:hover, &:active, &:focus, &.is-selected {
		border-left-color: @color;
		box-shadow: @size/2 0 @color inset;
		transition: box-shadow 0.2s ease-out;
	}
}

//
// Place un chevron en fin de ligne.
//
#right-chevron(@_chevron_size, @color: @color_qidigo_bleu) {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;

	&>*:first-child {
		@_size: @_chevron_size;
		@_line: 0.3rem;
		border-radius: 2/14rem;
		text-indent: -999em;
		display: block;
		overflow: hidden;
		width: @_size;
		height: @_size;
		border-bottom: @_line solid @color;
		border-right: @_line solid @color;
		transform:
			scaleY(1)        // Si on veut disproportionner verticalement (angle de pointe différent de 90°).
			rotate(-45deg)   // La rotation pour mettre le coin en bas à droite à droite.
			translateY(-50%) // top: 50%; place le top de l'élément à 50%, on remonte de la moitié du height.
		;
		position: absolute;
		top: 50%;
		right: @gutter;
	}
}

// 
// Styles par défaut...
// --------------------
//
ins {
	// AAL est weird pour <ins>...
	// Le component de WYSIWYG utilise <ins>...
	text-decoration: underline;
}

a {
	cursor: pointer;
}

.alert-translation-missing {
	background-color: #FFFFBF;
	border: 1/14rem #FFED61 solid;
	border-radius: @size_general_radius;
	padding: @gutter/2;
	margin-bottom: @gutter;
}

.is-preview {
	h3::after {
		content: "";
		background-image: url("~icomoon/svg/eye-blocked.svg?fill=%2327708e");
		background-repeat: no-repeat;
		background-position: center center;
		width: @_size;
		height: @_size;
		position: relative;
		top: @gutter * 0.75;
		display: inline-block;
	}
	font-style: italic;
}

// Prend pour acquis l'utilisation du component <HTML> globalement.
.is-raw-text {
	p {
		white-space: pre-line;
	}
}

// Replacement css for h1 to h3 wysiwyg.
.fake-h1 {
	font-size : 1.5rem;
}

.fake-h2 {
	font-size : 1.25rem;
}

.fake-h3 {
	font-size : 1.15rem;
}
