#loading {
	@_border_radius: 5px;
	@_full: rgba(255,255,255,0.7);

	// "Assure" d'avoir un glow éventuel au-dessus de la page.
	z-index: 999;

	// Quand on la garde dans l'en-tête
	position: absolute;
	top: auto;
	left: 0;
	right: 0;
	bottom: 0;

	body.header-is-not-showing &#loading {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: auto;

		height: 5px;
	}

	// Commence avec aucune opacité.
	opacity: 0;
	transition: opacity 0.4s ease-in-out;

	&.is-loading {
		opacity: 1;
		transition: opacity 0.4s ease-in-out;
	}

	&.is-loading {
		.loading-bar--value {
			transition: width 0.5s ease-in-out;
		}
	}

	.loading-bar--value {
		height: 100%;
		border-radius:
			0 @_border_radius
			  @_border_radius
			0
		;
		//box-shadow: 3px 0 10px 1px @_full;
		background:
			linear-gradient(
				to right,
				@color_qidigo_bleu,
				@color_qidigo_bleu 40%,
				@_full
			)
		;
	}
}

