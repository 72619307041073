//
// Valeurs par défaut des éléments
// ===============================
//

body {
	font-family: @font_primary;
	color: @color_police;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	#font-secondary();
	line-height: 1.2;
	font-size: @size_courant;
}

h1 {
	font-size: @size_mega;
}
h2 {
	font-size: @size_categorie1;
}
h3 {
	font-size: @size_categorie2;
}
h4 {
	font-size: @size_texto;
}

address {
	font-style: normal;
	margin-bottom: 1em;
}

a {
	color: @color_qidigo_bleu;
	text-decoration: none;

	&:hover, &:focus, &:active, &.active {
		text-decoration: underline;
	}
}
