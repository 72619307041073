//
// Component générique avec:
//
//   * Carte en pleine largeur
//   * 3 colonnes d'informations
//       * Première: adresse + numéro de téléphone
//       * Deuxième: horaire (à venir)
//       * Troisième: libre
//
.map-columns {
	#screen-sm-min({
	});

	.map-columns--map {
		margin: -@gutter;
		margin-bottom: @gutter;
		min-height: 190/14rem;
		position: relative;

		.qidigomap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
	@_icon: 30/14rem;
	.map-columns--wrapper {
		#self-clear;
		margin-left: -@gutter/2;
		margin-right: -@gutter/2;
		& > * {
			margin-bottom: @gutter;
			padding: 0 @gutter/2;
			padding-left: @_icon + 2*@gutter - @gutter/2;

			#screen-md-min({
				width: 4/12*100%;
				float: left;
			});

			#screen-lg-min({
				&.map-columns--infos {
					width: 5/12*100%;
				}
				&.organization-informations--social,
				&.map-columns--social {
					width: 3/12*100%;
				}
			});
		}
	}
	h2, h3 {
		font-size: @size_categorie2;
		margin-bottom: 1ex;
		position: relative;
		&::before {
			display: block;
			content: "";
			position: absolute;
			left: -@_icon - @gutter;
			width: @_icon;
			height: @_icon;
			top: -@size_categorie2 * 0.4;
			background-position: center center;
			background-size: contain;
		}
	}
	.__icon(@name) {
		h2, h3 {
			&::before {
				#__icon-self(@name, black, @color_police);
			}
		}
	}
	.map-columns--infos    { .__icon(location); }
	.map-columns--schedule { .__icon(clock); }
	//.map-columns--social   { .__icon(bubble); }

}
