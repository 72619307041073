@import "~qidigo-design-dna/vars";

.form {
	.form--row {
		// Always for two columns. If we need more, we'll have to fix it.
		margin-bottom: @gutter;
		margin-top: @gutter;
		// Mostly, 'xs' feels enough?
		#screen-xs-min({
			& > * {
				&.input, &.input-select{
					float: left;
					margin-top: 0;
					margin-bottom: 0;

					&:first-child:nth-last-child(1) {
						width: 100%;
					}

					&:first-child:nth-last-child(2),
					&:first-child:nth-last-child(2) ~ div {
						width: 50%;
					}

					&:first-child:nth-last-child(3),
					&:first-child:nth-last-child(3) ~ div {
						width: 33.3333%;
					}

					&:first-child:nth-last-child(4),
					&:first-child:nth-last-child(4) ~ div {
						width: 25%;
					}
				}	
			}
			&::after {
				display: block;
				content: "";
				clear: both;
			}
			&>.input-select:not(:first-child),
			&>.input:not(:first-child) {
				padding-left: @gutter/2;
			}

			&>.input:not(:first-child) {
				label span {
					left: @gutter/2;
				}
			}

			&>.input-select:not(:last-child),
			&>.input:not(:last-child) {
				padding-right: @gutter/2;
			}
		});
	}
}
