
#box {
	.message {
		&:extend(._box_message all);
	}
	.error {
		&:extend(._box_error all);
	}
	.warning() {
		&:extend(._box_warning all);
	}
	.success() {
		&:extend(._box_success all);
	}
	.normal {
		&:extend(._box_normal all);
	}
	._base(@_name, @_icon, @_border_color, @_background_color) {
		@_side_bar: @gutter * 2;
		min-height: @_side_bar;

		position: relative;
		border-width: 2/14rem;
		border-style: solid;
		border-radius: @size_general_radius;
		margin: @gutter/2 0;
		margin-bottom: @gutter;

		border-color: @_border_color;
		background-color: @_background_color;

		padding: @gutter/2;
		// Space for an icon!
		padding-left: @gutter/2 + @_side_bar;

		&::before {
			border-radius:
				@size_general_radius * 0.4
				0
				0
				@size_general_radius * 0.4
			;
			display: block;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: @gutter * 2;
			background: @_border_color;
			#__icon-self(@_icon, @_name, @_background_color);
			background-size: @gutter @gutter;
			background-position: center @gutter/2;
		}

		p {
			margin-bottom: @gutter/2;
		}

		h2, h3 {
			margin-bottom: @gutter/2;
			font-size: 1.2rem;
		}

		& > :last-child {
			margin-bottom: 0;
		}
	}
}

.box-message, ._box_message {
	width: 100%;
	#box._base("message", "bubble", @color_qidigo_bleu, lighten(@color_qidigo_bleu, 60%));
}
.box-error, ._box_error {
	#box._base("error", "notification", darken(@color_error, 10%), lighten(@color_error, 30%));
}
.box-warning, ._box_warning {
	#box._base("warning", "warning", #EFC748, lighten(#EDED3A, 36%));
}
.box-success, ._box_success {
	#box._base("success", "checkmark", #237E45, lighten(#237E45, 60%));
}

.box-normal, ._box_normal {
	#box._base("normal", "warning", #B7BBC2FF, lighten(#D2D6DEFF, 60%));
}
