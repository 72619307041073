.imagepicker {
	&.is-drop-target {
		#translucent(background, @color_qidigo_bleu, 0.3);
		color: @color_blanc;
	}

	.imagepicker--preview {
		#input();
	}
}
