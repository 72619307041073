.tag {
	#no-select();
	@_color: @color_qidigo_bleu;
	@_spacing: 1/14rem;
	@_size: @size_mini;
	@_h_padding: @_spacing * 3;

	position: relative;
	top: -@_spacing;
	color: @color_blanc;
	border-radius: 2em;
	border:@_spacing solid @_color;
	padding-left:  @_h_padding;
	padding-right: @_h_padding;
	margin-left:  @_spacing;
	margin-right: @_spacing;
	background: @_color;
	font-size: @_size;
	#font-secondary;
}
