.qidigo-contacts-input {
	// The element itself needs to have a higher z-index
	// as the z-index of the overflowing element is not
	// calculated.

	// The textarea/wysiwyg is of ~10 z-index.
	z-index: 99;
	* {
		z-index: 1;
	}
	cursor: text;
	position: relative;

	&.input {
		padding-top: 0;
		padding-bottom: 0;
	}

	&.is-empty label > span.input--label-text {
		opacity: 0;
	}

	// Un peu hacky... Les tags ont un margin pour éviter un rapprochement
	// trop serré, mais ça augmente le height du input.
	&.with-tags .input--input {
		padding-top: 0;
		padding-bottom: 0;
	}

	.react-autosuggest__container {
		display: inline-block;
	}

	.qidigo-contacts-input--tagsinput {
		.internal-input {
			margin-left: 0;
			border: 0;
			&:focus { outline: 0; }
			&:disabled { background: transparent; }
		}
		.react-autosuggest__suggestions-container {
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			z-index: 100;
			transform: translateY(-1/14rem);

			ul {
				margin: 0;
				padding: 0;
				width: 100%;
				max-height: 75vh;
				overflow: auto;

				background: @color_blanc;
				border: @size_input_border @color_gris2 solid;
				border-top: 0;
				border-radius: 0 0 @size_input_radius @size_input_radius;
				box-shadow: ;
				box-shadow: 3/14rem 3/14rem @size_focus_glow @color_grisfonce;
			}
			ul li {
				list-style-type: none;
				margin: 0;
				padding: @gutter/4;
				cursor: pointer;
			}
		}
		.react-autosuggest__suggestion--focused {
			background: @color_qidigo_bleu;
			color: @color_blanc;
		}

		.contact-item {
			#no-select();
			.contact-item--avatar {
				display: inline-block;
				vertical-align: text-bottom;
				width: @base_line_height * 0.9;
				height: @base_line_height * 0.9;
				margin-right: @gutter/3;
				max-width: 100%;
				overflow: ellipsis;
			}
			// Quand c'est un tag
			&.contact-tag {
				border-radius: @size_general_radius;
				padding: 1/14rem @gutter/2;
				padding-left: 3/14rem;
				margin: 4/14rem;
				margin-left: 0;
				background: @color_qidigo_bleu;
				color: @color_blanc;
				display: inline-block;
				line-height: @base_line_height;
				border: 1/14rem solid @color_qidigo_bleu;
				.contact-item--avatar {
					transform: translateY(1/14rem);
				}
			}

			// Removal ×
			a.react-tagsinput-remove {
				&::before {
					content: "×";
					display: inline;
				}
				color: @color_blanc;
				#font-secondary(400);
				cursor: pointer;
				text-decoration: none;
				transform: translateY(1/14rem);
				margin-left: 0;
				padding-left: 1ex;
				margin-right: -@gutter/2;
				padding-right: @gutter/2;
			}
		}
	}
}
