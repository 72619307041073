.qidigo-calendar {
	button {
		#base_button();
	}
}

.qidigo-calendar {
	.rbc-toolbar .rbc-active,
	.rbc-active {
		text-decoration: none;
		// FIXME : get from mixin.
		background-color: darken(@color_qidigo_bleu, 10%);
		color: #fff;
	}
	// FIXME : La toolbar devra être refactorée pour ressembler aux maquettes.
	#screen-sm-min({
	.rbc-toolbar {
		display: block;
		& > * {
			display: inline-block;
			white-space: nowrap;
			width: auto;
		}
		.rbc-toolbar-label {
			padding-top: 0.3rem;
		}
		.rbc-btn-group:first-child {
			float: left;
		}
		.rbc-btn-group:last-child {
			float: right;
		}
	}
	});
	#screen-xs-max({
		.rbc-toolbar {
			display: block;
		}
		.rbc-btn-group {
			display: block;
			text-align: center;
		}
		.rbc-toolbar-label {
			display: block;
			padding: @gutter/2;
		}
	});
	// iphone 4/5
	#screen-lte(320px, {
		.rbc-btn-group>* {
			font-size: @size_mini;
		}
	});

	// Prend pour acquis qu'on utilise les listes uniquement pour la structure.
	ul {
		&, & > li {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}
	}
}

.qidigo-calendar--simple-week {
	.is-ordinal-0,
	.is-ordinal-6 {
		background-color: @color_background;
	}

	.is-today {
		box-shadow: inset 0 0 0.5rem @color_qidigo_bleu;
	}

	&>ul>li {
		position: relative;
		border: 1/14rem solid @color_gris2;
	}

	ul, ul li {
		margin: 0;
		padding: 0;
	}

	li {
		list-style-type: none;
	}

	.qidigo-calendar--event {
		position: relative;
		display: block;
		cursor: default;

		background: @color_qidigo_bleu;
		padding: 1/14rem;
		margin: 2/14rem;
		border-radius: @size_general_radius;

		&, a {
			color: @color_blanc;
		}
	}

	.qidigo-calendar--day-date {
		#no-select();
	}

	#screen-xs-max({
		.qidigo-calendar--day-date {
			font-weight: bold;
		}
		&>ul>li {
			padding: @gutter/2;
			&:not(:last-child) {
				border-bottom: 0;
			}
		}
		.qidigo-calendar--event {
			margin-left: 0;
			margin-right: 0;
		}
	});

	#screen-sm-only({
		font-size: @size_courant;
	});

	#screen-sm-min({
		position: relative;
		width: 100%;
		display: table;
		border-collapse: collapse;
		table-layout: fixed;
		min-height: 7em;

		&>ul {
			display: table-row;
		}

		&>ul>li {
			width:100%/7;
			display: table-cell;
		}

		.qidigo-calendar--day-date {
			display: block;
			text-align: center;
			width: 100%;
			border-bottom: 1/14rem solid @color_gris2;
		}

		.qidigo-calendar--event {
			&>a>span,
			&>a {
				white-space: nowrap;
			}
			max-width: 100%;
			overflow: hidden;
		}

	});
}

.rbc-event {
	background-color: @color_grisfonce;
}


//
// Affichage "Liste"
//

.qidigo-calendar {
	.qidigo-calendar--agenda-view {
		.qidigo-calendar--empty {
			min-height: @base_line_height * 1em + @gutter;
		}
		.qidigo-calendar--event {
			&.is-full,
			&.is-past {
				cursor: default;
				pointer-events: none;
			}
		}
		a.qidigo-calendar--event {
			position: relative;
			display: block;
			color: inherit;
			text-decoration: inherit;
			#no-select();
			cursor: pointer;
		}
		.qidigo-calendar--hour > .is-full,
		.qidigo-calendar--event.is-full,
		.qidigo-calendar--hour > .is-past,
		.qidigo-calendar--event.is-past {
			color: @color_grisfonce;
		}

		.qidigo-calendar--hour > *,
		.qidigo-calendar--event {
			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 0;
				background-color: @color_qidigo_bleu;
				transition: width 0.4s ease-in;
			}
		}
		.qidigo-calendar--hour > .is-selected,
		.qidigo-calendar--event,
		.qidigo-calendar--event.is-selected {
			&:not(.is-past, .is-full) {
				#left-focus-guide(@gutter);
			}
		}
	}

	// Une journée
	.qidigo-calendar--agenda-view > ul > li {
		h2, h3 {
			#no-select();
			margin: 0;
			padding: 0 @gutter;
			line-height: 2.2;
			font-size: @size_categorie2;
			background: @color_bleu_fonce;
			color: @color_blanc;
		}
		h3 {
			background: @color_grisfonce;
			font-weight: normal;
		}
	}
	// La majorité des "agenda" auront une row
	// spécifique en children.
	.qidigo-calendar--empty,
	.qidigo-calendar--hour > :not(h3) {
		border: 1/14rem solid @color_gris2;
		border-top: 0;
		padding: @gutter/2 @gutter;

		&:last-child {
			border-bottom: 0;
		}
	}

	border-bottom: 1/14rem solid @color_gris2;
}
