//
// Stylisation des alertes
// =======================
//
// Position de la "houpette"
// -------------------------
//
//     .alert.vers-nouveauclient {
//         .alert.tip_pos(5rem);
//     }
//     .alert.vers-le-logo {
//         .alert.tip_pos(auto, 5rem);
//     }
//
// Couleur
// -------
// 
//     .alert.vers-nouveauclient {
//         .alert.colorize(#f0f);
//     }
//     

// FIXME : Re-work in a components library.

.alert {
	@_tip: 1.5em;
	#no-select;
	background: @color_police;
	color: @color_blanc;
	font-size: @size_texto;
	#font-secondary;

	.inner {
		padding: @size_input_padding_h 0;
		position: relative;
	}

	.inner::before {
		position: absolute;
		bottom: 0;

		// À overrider où nécessaire
		left: auto;
		right: auto;
		display: none;

		z-index: 1;
		background: @color_police;
		margin: auto;
		content: "";
		width: @_tip;
		height: @_tip;
		transform: scaleX(0.9) translateY(@_tip/2) rotate(45deg);
	}

	.colorize(@color) {
		background-color: @color;

		&>.inner::before {
			background-color: @color;
		}
	}

	.tip_pos(@right, @left: auto) {
		&>.inner::before {
			display: block;
			left: @left;
			right: @right;
		}
	}

	.inner > span:last-of-type {
		margin-right: 1rem;
	}

	// Bouton close (×) de l'alert.
	.alert--close {
		line-height: 1rem;
		width: 1.5rem;
		height: 1.3rem;
		font-size: 1.5rem;
		top: @size_input_padding_h;
		// Pour éloigner le `close` du texte.
		right: 0.1rem;

		&:focus {
			outline: 2px solid @color_secondary;
			color: #ffffff;
		}
	}
}

.alert {
	&.is-hidden {
		overflow: hidden;
		display: none;
	}

	.inner {
		position: relative;
	}

	.alert--close {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		line-height: 1em;
		text-align: center;
	}
}

.alert.alert-error {
	.alert.colorize(@color_qidigo_rouge);
}
