//
// Volé de messages.
//
section.page-documents {
	h1 { #no-select(); }
	
	// Utilise un side-nav
	#side-nav();

	@media print {
		&>.side-nav {
			display: none;
		}
		margin-left: 0;
	}

	// Retire le padding de la page... (migrer dans side-nav?)
	padding: 0;

	&>nav {
		// Assure que l'option de menu n'est pas collée sur le badge.
		// (Assum un span react, nav>ul>li>a>span
		li>a>:first-child::after {
			content: " ";
			display: inline;
		}
		// Assure un badge blanc sur rouge
		.badge {
			background-color: @color_qidigo_rouge;
			color: @color_blanc;
		}
	}

	// La "page"
	&>section {
		padding: 0;
		background: @color_blanc;
		padding: @gutter/2;
		#screen-sm-min({
			padding: @gutter;
		});
		padding-bottom: @gutter;
	}

	// On veut un minimum height agréable
	.documents--child>.loading {
		padding-top:    4rem;
		padding-bottom: 4rem;
	}
}

//
// Documents listing
// =================
//

.documents-list {
	// Présente la la liste comme une listtable
	#listtable();

	// La liste doit se coller aux bords de l'écran.
	#screen-xs-max({
		margin-left:  -@gutter/2;
		margin-right: -@gutter/2;
	});

	.document-item {
		clear: both;
		position: relative;
	}
	.document-item {
		#screen-sm-max({
			@_image_size: @base_line_height * 2.5;
			.fake-image {
				background: #fff;
				border: 1/14rem solid @color_grismoyen;
				border-radius: @size_general_radius;
				display: block;
				float: left;
				width:  @_image_size;
				height: @_image_size;
				//position: absolute;
				//vertical-align: text-bottom;
				margin-right: @gutter/2;
			}
			// Si on ajoute un preview, éventuellement.
			//// &.with-avatar {
			//	&>* {
			//		padding-left: @_image_size + @gutter/2 + @gutter/2;
			//	}
			//// }

			.document-item--organization {
				padding-left: @gutter/2;
				font-size: @size_courant;
				#font-secondary(400);
			}
			.document-item--date {
				display: none;
			}
			#self-clear();
			padding-bottom: @gutter/2;
		});
		#screen-md-min({
			.fake-image {
				display: inline-block;
				vertical-align: text-bottom;
				width:  @base_line_height;
				height: @base_line_height;
				margin-right: @gutter/4;
			}
		});
	}
	.document-item {
		.document-item--name {
			font-weight: bold;
		}
		.document-item--description {
			font-weight: normal;
			font-size: @size_courant;
		}
	}
	//
	// Spécialisations de colonnes
	//
	ul>li>ul {
		&>li.document-item--date {
			white-space: nowrap;
			#screen-md-min({
				text-align: right;
			});
		}
		&>li.document-item--organization {
			#screen-md-min({
				// Child items are nowrapped.
				&>* {
					white-space: nowrap;
				}
			});
		}
		&>li .document-item--plus {
			#badge();
			background-color: @color_gris1;
			color: @color_grisfonce;
			margin-left: 1em;
			// Remove some padding because of the weight of the +
			padding-left: 0.3em;
		}
		&>li.document-item--name {
			width: 100%;
			#screen-sm-max({
				a {
					width: 100%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					// Remove when/if we add a body text preview.
					// This makes the name line's link expand
					// to have a bigger hit target.
					padding-bottom: 1rem;
				}
			});
			#screen-md-min({
				max-width: 0;
				width: 100%;
				&>* {
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 100%;
					width: 100%;
					white-space: nowrap;
				}
			});
		}
		&>li.document-item--date {
			#screen-md-min({
				width: 0%;
				white-space: nowrap;
			});
		}
	}
}

//
// Quand il n'y a rien...
//

.documents-list .documents-list--nothing {
	#empty_box();

	// Si on veut ajouter de quoi après le texte.
	// &::after {
	// 	content: " :) ";
	// 	display: block;
	// 	padding: 1em;
	// 	padding-bottom: 0;
	// }
}
