.searchbox--q {
	.typeahead--results {
		li {
			border-top: 1/14rem solid @color_gris0;
		}
		//li:not(:last-child) {
		//	border-bottom: 1/14rem solid @color_gris0;
		//}
	}
}
.quickresult {
	// Hack pour #33.
	// Il faut remédier au problème différemment.
	@_magic: 3.8rem;
	#souplesse.horizontal();

	h2, h3 {
		font-size: 1em;
		font-weight: bold;
		font-family: @font_primary;
		margin: 0;
		padding: 0;
		margin-bottom: 0.8rem;
	}

	.quickresult--thumb {
		#animation.fade-in();

		#souplesse.grow(0);
		margin-right: @gutter;
		display: block;
		width: @_magic;
		min-width: @_magic;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;

		img { display: none; }

		&.is-error {
			#asset.search-noimage();
		}
	}

	.quickresult--description {
		@_line-height: 1.2em;
		overflow: hidden;
		height: @_magic;
		line-height: @_line-height;

		p {
			height: @_line-height * 2;
			overflow: hidden;
		}
	}
}
