.avatar-image {
	.fake-image--image-element {
		background-size: cover;
	}

	&.is-error {
		.fake-image--image-element {
			#asset.avatar-default();
		}
	}
}
