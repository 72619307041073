#header {
	@_border: 0.7rem;
	@_bar_height: 44 / 14rem;
	@_padding: 6 / 14rem;
	@_inner_height: @_bar_height - (2 * @_padding);

	color: @color_blanc;
	font-size: @size_mini;

	a {
		color: inherit;
	}

	.container {
		height: @_inner_height;
		position: relative;
		min-height: @_bar_height;

		// Get a bit of breathing room.
		#screen-xs-max({
			padding-left: 0;
			padding-right: 0;
		});
	}

	.gradient(
		@color_qidigo_bleu, @color_qidigo_bleu,
		darken(@color_qidigo_bleu, 5%)
	);
	border-bottom: @_border solid @color_bleu_fonce;

	nav {
		&.header--user-menu {
			position: absolute;
			right: @gutter;
			// To follow the previously unpadded breathing room.
			#screen-xs-max({
				right: 0;
			});
			top: 0;
			bottom: 0;
			text-align: right;
		}

		ul>li {
			text-align: center;
		}
	}
	.header--main-menu {
		#screen-sm-min({
			margin-left: @gutter;
		});
	}

	nav {
		ul {
			.structure.list.inline();

			li {
				vertical-align: middle;
			}
		}
	}

	nav ul>li {
		position: relative;
		min-width: @_inner_height * 1.5;

		a:not(.button) {
			#no-select();
			cursor: pointer;
			display: block;
			padding-left: @_padding;
			padding-right: @_padding;
			padding-top: @_inner_height - @size_mini + @_padding;
			line-height: @size_mini;
			height: @_bar_height;

			&:active, &:focus, &:hover, &.active {
				background-color: @color_bleu_fonce;
				text-decoration: none;
			}
		}
		a.button, a.button.active {
			color: @color_qidigo_bleu;
			background: @color_blanc;
			font-size: inherit;
			padding-left: @gutter*1.7;
			padding-top:    5/14rem;
			padding-bottom: 5/14rem;
		}

		.header--user-avatar,
		button::before, a::before {
			background: transparent;
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			content: "";
			display: block;
			position: absolute;
			top: @_padding;
			left: 0;
			right: 0;
			width: @_inner_height - @size_mini - 2/14rem;
			height: @_inner_height - @size_mini - 2/14rem;
			margin: auto;
		}

		.header--menu-user a::before {
			display: none;
		}

		.header--user-avatar {
			border-radius: @size_general_radius;
			background: @color_blanc;
		}

		#screen-xs-max({
			a {
				line-height: 0;
				text-indent: -300vw;
			}
			.header--user-avatar,
			button::before, a::before {
				height: @_inner_height;
				width: @_inner_height;
			}
		});

	}
	.header--menu-help a::before { background-image: url("~design/assets/menu/help.svg"); }
	.header--menu-messages a::before { background-image: url("~design/assets/menu/messages.svg"); }
	.header--menu-cart a::before { background-image: url("~design/assets/menu/cart.svg"); }
	.header--menu-login a::before { background-image: url("~design/assets/menu/login.svg"); }
	.header--menu-register a::before { background-image: url("~design/assets/menu/register.svg"); }

	// Spécialisation pour le bouton.
	a.button::before {
		right: auto;
		left: @gutter/1.5;
		top: 0.7em;
		bottom: auto;
		margin: auto;
		width:  1.6em;
		height: 1.6em;
	}

	// The profile link is a bit different.
	// This sets a default image.
	.header--user-avatar .fake-image--image-element {
		background-image: url("~design/assets/menu/user.svg");
	}

	// Responsive things. Really small devices
	// (iPhone 4, iPhone 5) gets a different menu layout.
	#screen-xxs-max({
		.header--menu-help {
			display: none;
		}
	});

	// The logo link.
	h1 {
		@_width: @_inner_height * 2.1;
		@_height: @_inner_height;
		width: @_width;
		height: @_height;

		margin: auto;
		padding: 0;
		font-size: 1rem;
		line-height: 0;

		a {
			margin: 0;
			padding: 0;
			display: block;
			height: 100%;
			width: 100%;
			background-image: url("~design/assets/logo-highlighted.svg");
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			text-indent: -300vw;
			//#base_focus();
		}
	}
	#screen-sm-min({
		h1 {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		}
	});

	#screen-xs-max({
		h1 {
			position: absolute;
			left: 1 * @_bar_height;
			right: 3 * @_bar_height;
			top: 0;
			bottom: 0;
		}
	});

	// Loading thingamajig
	#loading {
		bottom: -@_border;
		height: @_border;
	}

	// Loading spinner for user menu
	.loading .loading--wrapper {
		// Nudge it left for narrow layouts.
		// Otherwise we somehow get a scrollbar.
		position: relative;
		left: -8/14rem;
		padding: 0;
		padding-top: @gutter/2;

		.loading--spinner {
			border-width: 2/14rem;
			width: 2rem;
			height: 2rem;
			#translucent(border-color, @color_blanc, 0.2);
			border-left-color: @color_blanc;
		}
	}

	.badge {
		z-index: 1;
	}
	.header--menu-cart {
		position: relative;

		&.disabled {
			pointer-events: none;
		}
	}
	.header--cart-timer {
		&, * { text-indent: 0; }
		position: absolute;
		margin: auto;
		font-size:1.3em;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: @base_line_height;
		line-height: @base_line_height;
		width: 3.7em;
		font-weight: bold;
		background: rgba(0,0,0,0.6);
		border-radius: @size_general_radius;
		.cart-timer--separator {
			display: inline-block;
			width: 0.5ex;
			text-align: center;
		}
		.cart-timer--minutes ,
		.cart-timer--seconds {
			display: inline-block;
			width: 2.5ex;
		}
		.cart-timer--minutes {
			text-align: right;
		}
		.cart-timer--seconds {
			text-align: left;
		}
	}
}

#header {
	@media print {
		background: transparent;
		border: 0;
		&>*>nav {
			display: none;
		}
		#loading {
			display: none;
		}
		.container {
			height: auto;
		}
		h1 {
			position: static;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			margin: 0;
			height: auto;
			font-size: 0;
		}
		h1 a {
			&::before {
				margin-left: @gutter/2;
				content: url("~design/assets/logo-print.svg");
				display: block;
				text-indent: 0;
			}
			background-image: none;
		}
	}
}

