// Checkbox et Radio
// =================
//
// **Notes pour les courageux:**
//
// Étant donné que les <input> ne sont pas facilement stylisable,
// surtout pour les checkboxes et les radios, on a un DOM spécial.
//
//     <div class="checkbox  is-checked">
//         <label>
//             <input type="checkbox" class="checkbox--input">
//             <div class="checkbox--checkbox checkbox--fake"></div>
//             <span class="checkbox--label-text">Check this ↩</span>
//         </label>
//     </div>
//
// Qu'est-ce qu'on fait avec tout ça??
//
// On cache le <input>, MAIS ATTENTION, on ne le `display:none;` pas.
// En le cachant de la sorte, il reste dans le *tabindex*, donc utilisable,
// et ensuite, ses pseudo-classes sont utilisables.
//
// Qu'est-ce qu'on fait avec tout ça??
//
// On prends le <div> suivant, on le présente comme on voudrait présenter
// l'input. On lui donne le look voulu. Avec les sibling selectors (+ et ~)
// on peut aller spécifier des stylisations particulières au faux input sans
// avoir à recréer le comportement.
//
// C'est comme ça qu'on fait pour garder une accessibilité complète malgré
// une stylisation complexe!
//

@qidigo_checkbox_diameter: 1.0rem;

// Styles généraux.
.radio,
.checkbox,
.togglebutton {
	position: relative;
	&.is-disabled {
		opacity: @disabled_opacity;
		transition: opacity @disabled_transition;
	}

	&:not(.is-disabled) {
		&,
		.radio--radio,
		.checkbox--checkbox {
			cursor: pointer;
		}
	}

	&:not(.is-disabled) {
		label {
			cursor: pointer;
		}
	}

	label {
		#no-select();
		@_padding: @size_input_padding_v + @size_input_border*2;
		// Si on mets uniquement du padding, il n'y aura pas
		// de séparation entre les zones cliquables de contrôles
		// radios ou checkbox se suivant.
		// En mettant du padding, ça augmente la zone touchable!
		// Aussi, la zone cliquable, donc plus facile à cliquer!
		padding: @_padding/2 0;
		margin: @_padding/2 0 ;
	}

	// Heh, c'est difficile de styliser correctement le
	// vrai <input> node.
	// Il faut par contre conserver ça ici pour le fake!
	.radio--input,
	.checkbox--input {
		// Firefox needs the `absolute`... apparently...
		// This removes the input entirely from the layout flow.
		// The width + margin trick that worked before doesn't
		// work anymore. smh.
		position: absolute;
		display: inline-block;
		// Ne peut pas être 0x0
		width: 1px;
		height: 1px;
		margin: 0;
		padding: 0;
		// Donc il faut re-tasser
		margin-left: -1px;
		opacity: 0.001;
	}
}

.radio,
.checkbox {
	label {
		#souplesse.horizontal();
		#souplesse.middle();
	}

	.radio--input,
	.checkbox--input {
		#souplesse.grow(0);
	}

	.radio--radio,
	.checkbox--checkbox {
		#_input();
		#souplesse.grow(0);
		padding: 0;
		margin: 0;
		border-width: @size_button_border;
		border-color: @color_grisfonce;
		min-width: @qidigo_checkbox_diameter;
		width: @qidigo_checkbox_diameter;
		height: @qidigo_checkbox_diameter;
		margin-right: @gutter - @qidigo_checkbox_diameter + @size_button_border*2;
		background: @color_blanc;
		margin-top: 1/14rem;
		position: relative;

		// Le ::before présente le crochet magique.
		// On le met dans un ::before pour la transition opacity!
		&::before {
			transition: opacity 0.3s ease-out;
			display: block;
			opacity: 0;
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			height: @qidigo_checkbox_diameter;
			text-align: center;
			line-height: @qidigo_checkbox_diameter;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
		}
	}

	&:not(.is-disabled) {
		input:active ,
		input:focus {
			& ~ .radio--label-text,
			& ~ .checkbox--label-text {
				text-shadow: 1px 1px 1px @color_grismoyen;
			}
		}
	}

	input:focus {
		& + .radio--radio,
		& + .checkbox--checkbox {
			#base_focus_rule();
		}
	}

	&:not(.is-disabled) input:active {
		& + .radio--radio,
		& + .checkbox--checkbox {
			#base_focus_rule();
			border-color: @color_qidigo_bleu;
			background-color: @color_qidigo_bleu;

			&::before {
				opacity: 1;
			}
		}
	}

	&.is-checked {
		.radio--radio,
		.checkbox--checkbox {
			&::before {
				opacity: 1;
			}
		}

		&:not(.is-disabled) {
			input:active  + .checkbox--checkbox::before {
				opacity: 0;
			}
		}
	}

	// Checkbox only
	// Oui, on double le poids du ceckbox en ayant deux svg, mais
	// le poids final de l'image est négligible, à ~600 octets.
	.checkbox--checkbox {
		&::before {
			background-image: url("~design/assets/forms/checkmark.svg");
		}
	}

	&:not(.is-disabled) {
		input:active {
			& + .checkbox--checkbox::before {
				background-image: url("~design/assets/forms/checkmark-active.svg");
			}
		}
	}

	// Radios only
	.radio--radio {
		border-radius: 100%;

		&::before {
			border-radius: 100%;
			height: auto;
			background-color: @color_police;
			border: @size_button_border solid @color_blanc;
		}
	}

	&:not(.is-disabled) {
		input:active + .radio--radio::before {
			border-color: @color_qidigo_bleu;
			background-color: @color_blanc;
		}
	}
}

//
// Spécialisation de ce qui est un checkbox, en "toggle".
//
// (Cette classe triche et brise le contrat BEM, mais c'est tellement
// plus simple, et surtout, un component qui n'aura jamais d'enfant)
//
.togglebutton {
	position: relative;
	display: inline-block;
	#font-secondary;

	label,
	label>* {
		display: inline-block;
	}

	.checkbox--label-text {
		transition:
		color 0.3s ease-out,
		background-color 0.3s ease-out,
		box-shadow 0.2s ease-in,
		;
		display: inline-block;
		border-radius: @size_button_radius;
		border: 2px solid @color_grisfonce;
		padding-left: @qidigo_checkbox_diameter/2;
		padding-right: @qidigo_checkbox_diameter/2;
		// Part of the base_focus_rule, allows the shadow
		// to move upwards.
		box-shadow: 0 5px @size_focus_glow rgba(0,0,0,0);
	}
	.checkbox--input {
		width: 1px;
		height: 1px;
		margin-right: -1px;
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}

	//
	// C'est un peu comme ça qu'on pourrait ajouter un picto.
	// Il faudra voir ce qu'on fait pour rendre le togglebutton plus évident.
	//
	.checkbox--label-text::before {
		margin-right: @qidigo_checkbox_diameter/2;
		margin-top: 1/14rem;
		display: inline-block;
		opacity: 0;
		content: "";
		width: @qidigo_checkbox_diameter/1.3;
		height: @qidigo_checkbox_diameter/1.3;
		line-height: @qidigo_checkbox_diameter;
		background-image:url("~design/assets/forms/checkmark-active.svg");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		transition: opacity 0.3s ease-out;
	}

	input:focus {
		& ~ .checkbox--label-text {
			#base_focus_rule();
		}
	}

	&:not(.is-disabled) {
		input:active ~ .checkbox--label-text {
			#base_focus_rule();
			border-color: @color_qidigo_bleu;
			background-color: @color_qidigo_bleu;
			color: @color_blanc;

			// Picto actif
			&::before {
				opacity: 1;
			}
		}
	}

	&.is-checked {
		// Picto checked
		.checkbox--label-text::before {
			opacity: 1;
		}
	}

	&.is-checked {
		.checkbox--label-text {
			background: @color_grisfonce;
			color: @color_blanc;
		}
	}

}

//
// Espace après un groupe de radios.
//
.radiogroup {
	margin-bottom: @gutter / 2;
	&.radiogroup--inline {
		.radio {
			display: inline-block;
			&:not(:last-child) {
				margin-right: @gutter;
			}
		}

	}
}

//
// Highlight des radios sur sélection invalide.
//
.radiogroup.is-invalid {
	.radio--input, .radio--radio {
		#base_focus_rule(@color_highlight);
		border-color: @color_highlight;
	}
}
