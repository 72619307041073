// Dépendance implicite.
@import "~sdr-responsive/index";

// Provenant des *metrics* du site actuel.
@aal_font_size: 14px;

// FIXME: Use only a number (no unit)
//  → breaks buttons.
@base_line_height: 1.5rem;

//
// Polices
// =======
//

@font_primary: "Open Sans", "Helvetica", "Helvetica Neue", "Arial", sans-serif;

#font-secondary(@weight: 500) {
	// (Utilisation de weight en paramètre pour simplifier le listing des weights utilisés)
	font-family: Montserrat, sans-serif;
	font-weight: @weight;
}

//
// Dimensions
// ==========
//

// Dimensions: Texte
@size_mega:       36 / 14rem;
@size_categorie0: 24 / 14rem;
@size_categorie1: 20 / 14rem;
@size_categorie2: 15 / 14rem;
@size_texto_gras: 14 / 14rem;
@size_texto:      14 / 14rem;
@size_courant:    12 / 14rem;
@size_mini:       10 / 14rem;

// Dimensions: Texte Menu et Footer
@size_menu_vertical:    13 / 14rem;
@size_menu_horizontalA: 11 / 14rem;
@size_menu_horizontalB: 10 / 14rem;

// Dimensions: Texte Bouton
@size_button_impact:        22 / 14rem;
@size_button_conventionnel: 14 / 14rem;

// Généralement, "3px"
@size_general_radius: 3 / 14rem;

// Généralement, focus
@size_focus_glow:    8 / 14rem;

//
// Couleurs
// ========
//

@color_qidigo_rouge: #ed3f3a; // Rouge Qidigo
@color_qidigo_bleu:  #27708e; // Bleu Qidigo
@color_bleu_fonce:   #194453; // "Lowlight" Qidigo
@color_blanc:        #ffffff; // Beige
@color_beige:        #fcfcfc; // Beige
@color_background:   #f1f2f3; // Arrière-plan
@color_gris0:        #eaebed; // Gris 0
@color_gris1:        #e9e9e9; // Gris 1
@color_gris2:        #d2d3d5; // Gris 2
@color_grismoyen:    #bcbec0; // Gris Moyen
@color_grisfonce:    #777777; // Gris Foncé
@color_police:       #4c4c4e; // Gris Police

@color_highlight: @color_qidigo_rouge;
@color_secondary: @color_qidigo_bleu;

@color_container_border: @color_gris1;

@color_error: @color_qidigo_rouge;

@disabled_opacity: 0.5;
@disabled_transition: 0.5s ease-in;

// Couleurs "spéciales"
// --------------------

@color_facebook: #3b5998;
@color_google:   #dd4b39;

//
// Conteneurs responsive
// =====================

@gutter:       20/14rem; // Prefer this.
@gutter_px:    20px;     // When units can't be mixed.
@container-xs: @screen-xs - 1 * @gutter_px;
@container-sm: @screen-sm - 1 * @gutter_px;
@container-md: @screen-md - 2 * @gutter_px;
@container-lg: @screen-lg - 2 * @gutter_px;

//
// Spécifications
// ==============
//

// <Input>
// -------

// Dimensions <Input>
@size_input_radius:  @size_general_radius;
@size_input_border:  1 / 14rem;
@size_input_padding_v: @base_line_height / 4;
@size_input_padding_h: @base_line_height / 2;
@size_input_icon: 1.0rem;
@size_input_height: @size_input_padding_v*2 + @base_line_height;

// <Button>
// --------

// Dimensions <Button>
@size_button_radius: @size_input_radius;
@size_button_border: 2 / 14rem;
@size_button_padding_v: @size_input_padding_v - @size_button_border/2;
@size_button_padding_h: @size_input_padding_h * 2;
@size_button_height: @size_button_border*2 + @size_button_padding_v + @base_line_height;

// Compatibility with "pages entreprise"
@qidigo_main:        @color_qidigo_bleu;
@qidigo_secondary:   @color_qidigo_rouge;
@qidigo_main_dark:   @color_bleu_fonce;
@qidigo_main_darker: #081e23;

// FIXME : Meilleurs noms pour les gris
@qidigo_grey1:       @color_gris0;
@qidigo_grey2:       @color_gris1;
@qidigo_grey3:       @color_gris2;
@qidigo_medium_grey: @color_grismoyen;
@qidigo_dark_grey:   @color_police;
