//
// Point d'entrée de l'*ADN Qidigo*.
// =================================
//

// Variables pour l'implémentation de l'*ADN*.
@import "vars";

//
// Règles communes
// ---------------
//
// Certaines règles sont implicites pour le bon fonctionnement.
//

* {
	box-sizing: border-box;
}

@import "defaults";
@import "classes";
