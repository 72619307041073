.share-menu {
	@_size: 4.85rem;

	& > ul, & > ul > li {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	& > ul {
		position: absolute;
		right: 0;
		bottom: 0;
		#screen-sm-min({
			border-left: 1/14rem solid @color_blanc;
			border-top: 1/14rem solid @color_blanc;
		});
	}

	& > ul > li {
		a {
			text-indent: -999em;
			text-align: left;
			overflow: hidden;
			display: block;
			width: @_size;
			height: @_size;
			background-position: center center;
			background-size: 60% 60%;
		}
	}
	#screen-xs-max({
		left: 0;
		ul {
			width: 100%;
			text-align: right;
		}
		li {
			vertical-align: bottom;
			display: inline-block;
			border-top: 1/14rem solid @color_blanc;
			&:first-child {
				border-left: 1/14rem solid @color_blanc;
			}
		}
	});

	.specialize(@provider, @color) {
		@_mult: #dadada;

		.provider-@{provider} {
			background-color: @color;

			&:not(:disabled) {
				&:hover, &:focus, &:active, &.active {
					background-color: if(@color = #000000, lighten(@color, 20%), multiply(@color, @_mult));
				}

			}
		}
	}
	.share-menu.specialize(facebook,   #37569B);
	.share-menu.specialize(x,    #000000);
	.share-menu.specialize(googleplus, #E0492E);
}
