.applicationerror {
	border: 1px solid @color_error;
	background: lighten(@color_error, 40%);
	border-radius: @size_button_radius;
}
.applicationerror--title {
	font-weight: bold;
	border-bottom: 1px solid @color_error;
}
.applicationerror--title,
.applicationerror--message {
	padding: @gutter / 2 @gutter;
}
