//
// Base styles for <Image>
// =======================
//
// Those styles provide an acceptable behaviour for the image replacement
// component.
//

.fake-image,
.fake-image--image-element {
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
}

//
// It is advisable to add a transition to the `.fake-image`
// component.
//
//    .fake-image {
//        #animation.fade-in();
//    }
//
.fake-image {
	display: inline-block;
	overflow: hidden;

	.fake-image--image-img {
		display: none;
		// It is possible this can break in some browsers,
		// this is why we only use it for print.
		// Printing backgrounds is, by default, not enabled.
		// We *anyway* have the image.
		@media print {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
	position: relative;

	.fake-image--image-element {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	//
	// It is advisable to override / add behaviour
	// like a loading spinner here.
	//
	//     .fake-image.is-loading::before {
	//         .loading--spinner;
	//     }
	//
	&.is-loading::before {
		content: "";
		display: block;
		// FIXME
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
}

