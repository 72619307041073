#side-nav() {
	&:extend(#_side-nav all);
	.specialize(@name, @icon, @rules: {}) {
		// FIXME : Réduire le poids de la règle plus bas pour réduire le poids ici.
		nav.side-nav.side-nav .side-nav--item-main > .sidenav--@{name} {
			&::before {
				#__icon-self(@icon, "", @color_blanc);
			}
			@rules();
		}
	}
}

// Icônes par défaut pour certaines keys.
#side-nav.specialize(contact, phone);
#side-nav.specialize(activities, soccer-inv);
#side-nav.specialize(memberships, membership);
#side-nav.specialize(settings, cogs);

// En dev, on rend les icônes manquantes chose évidente.
.env-development .side-nav--item-main > a::before {
	#__icon-self("dev-invalid",      "", #f0f);
}

#_side-nav {
	@media print {
		& > nav {
			display: none;
		}
	}

	position: relative;
	// Assure que les colonnes ne seront pas tronquées.
	&, &>section>* {
		#self-clear();
	}

	// Navigation sur le côté en sm-min
	#screen-sm-min({
		&>nav.side-nav {
			float: left;
		}
	});
	// This is noisy, but we need different metrics for different
	// viewport widths.
	#screen-sm-only({
		@_menu_width: @container-sm / 12 * 3;
		&>nav.side-nav {width: @_menu_width;}
		&>section {
			width: @container-sm - @_menu_width;
			margin-left: @_menu_width;
		}
	});
	#screen-md-only({
		@_menu_width: @container-md / 12 * 2.5; // yay half-grid cheating
		&>nav.side-nav {width: @_menu_width;}
		&>section {
			width: @container-md - @_menu_width;
			margin-left: @_menu_width;
		}
	});
	#screen-lg-only({
		@_menu_width: @container-lg / 12 * 2;
		&>nav.side-nav {width: @_menu_width;}
		&>section {
			width: @container-lg - @_menu_width;
			margin-left: @_menu_width;
		}
	});

	ul.side-nav--menu {
		display: block;
		margin: 0;
	}
	ul.side-nav--menu > li {
		display: block;
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	& > nav.side-nav {
		a {
			position: relative;
			#font-secondary;
			display: block;
			#no-select();
			cursor: pointer;
		}
		@_bg: @color_grismoyen;
		.side-nav--item-main {
			& > a {
				background-color: @_bg;
				color: @color_blanc;
				&.active, &:hover, &:active, &:focus {
					@_bg: @color_bleu_fonce;
					background-color: @_bg;
				}
				text-decoration: none;
				#screen-sm-min({
					white-space: nobreak;
				});

				& > span {
					position: relative;
					padding: 0 @gutter;
					display: block;
				}
			}
			// Truc pour faire la barre de séparation.
			&:not(:last-child) > a {
				& > span::after {
					@_sp: 3/14rem;
					display: block;
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					height: 1/14rem;
					background: @color_blanc;
					border-left: @_sp solid @_bg;
					border-right: @_sp solid @_bg;
				}
			}
			// Emplacement du texte (centré verticalement)
			& > a {
				// Dimension des icônes
				@_size: 2em;
				// Espacement
				@_esp: @gutter / 2;
				// Total
				@_left: @_size + @_esp*3;

				// Pour placer verticalement centré le texte à tout coup.
				.side-nav--text {
					position: absolute;
					top: 50%;
					display: block;
					left: @_left;
					right: 0;
					transform: translateY(-50%);
					margin-right: @gutter;
					line-height: 1.2em;
				}
				&>span {
					min-height: @size_categorie2 + @gutter * 2 + 1/14rem * 2;
				}
				// FIXME : Réduire le poids de la règle ici pour réduire en haut.
				&::before {
					display: block;
					content: "";
					width: @_size;
					height: @_size;
					margin: auto;
					position: absolute;
					top: 0;
					left: @gutter/2;
					bottom: 0;
					background-size: contain;
					background-position: center center;
				}
			}
		}

		// Sous-menu
		.side-nav--item-main ul {
			background: @color_gris1;

			li {
				list-style-type: none;
				margin: 0;
				padding: 0;
				text-align: center;
			}

			li > a {
				padding: @gutter / 3;
				color: @color_grisfonce;
				text-decoration: none;
				&:not(:last-child) {
					border-bottom: 1/14rem solid @color_grismoyen;
				}
				&:active, &.active, &:focus, &:hover {
					background-color: @color_gris2;
				}
			}
		}
	}

	//
	// Magie obscure pour le collapse en -xs
	// -------------------------------------
	//

	// Le component react-collapse peut conserver les éléments DOM lorsque collapsés.
	// On peut donc overrider les propriétés calculées en JS ici pour gérer à un seul
	// endroit où le niveau de responsiveness des menus est placé.
	// C'est un peu sale, mais c'est une utilisation acceptable de `!important` où les
	// conséquences sont clairement limitées
	//
	//   1. Aux niveaux où le menu doit rester afficher
	//   2. On override le comportement spécial de react-collapse qui cache
	//   3. Ce qui force la présence du menu, donc s'assure que même s'il a été collapsé
	//      en mobile, il est visible sur plus large (après redimension / rotation).
	nav.side-nav > div {
		#screen-sm-min({
			overflow: auto !important;
			height: auto !important;
		});
	}

	@_toggler_size: @size_categorie2 + 2 * @gutter;
	@_toggler_width: @_toggler_size * 0.8;
	// Positionnement du hambourgeois
	nav.side-nav > .side-nav--toggler {
		display: block;
		padding: 0;
		margin: 0;
		width: @_toggler_width;
		height: @_toggler_size;
		position: absolute;
		top: 0;
		left: 0;
		text-indent: -999em;
		z-index: 2;
		//background-color: @color_qidigo_bleu;
		background: none;

		#screen-sm-min({
			display: none;
		});

		.bars {
			display: block;
			border-radius: 3/14rem;
			background: @color_qidigo_bleu;
			height: 9%;
			width: 80%;
			margin: auto;
			position: absolute;
			left: 0;
			right: 0;

			transition:
			background 0.3s linear,
			transform  0.3s linear,
			;

			// Partent toutes du centre.
			top: 0;
			bottom: 0;

			// Offset à partir du centre
			// (100% = own height)
			// (200% being spacing between == to own height)
			@_adj: 200%;
			&:first-of-type {
				// Celle du haut
				//top: 25%;
				transform: translateY(-@_adj);
			}
			&:not(:first-of-type):not(:last-of-type) {
				// Rien de spécial pour celle du milieu
			}
			&:last-of-type {
				// Celle du bas
				transform: translateY(@_adj);
				//bottom: 25%;
			}
		}
	}

	// X du mode mobile ouvert.
	nav.side-nav.is-not-collapsed > .side-nav--toggler {
		.bars {
			background-color: @color_blanc;
			
			&:not(:first-of-type):not(:last-of-type) {
				background-color: transparent;
			}

			&:first-of-type {
				transform: translateY(0) rotate( 45deg);
			}
			&:last-of-type {
				transform: translateY(0) rotate(-45deg);
			}
		}
	}

	nav.side-nav .side-nav--header {
		#no-select();
		height: @_toggler_size;
		padding: @gutter @_toggler_width;
		background: @color_qidigo_bleu;
		font-size: @size_categorie1;
		#font-secondary;
		color: @color_blanc;
		text-align: center;

		#screen-sm-min({
			display: none;
		});
	}

	nav.side-nav + * {
		#screen-xs-max({
			& h1:first-child {
				text-align: center;
				// Affecte les deux côtés pour conserver le texte centré à l'écran.
				padding-left: @_toggler_width ;
				padding-right: @_toggler_width ;
			}
		});
	}

	nav.side-nav .badge {
		@_s: 1.9em;
		border-radius: 100%;
		margin-left: 1ex;
		display: inline-block;
		padding: 0;
		text-align: center;
		line-height: @_s;
		width:       @_s;
		height:      @_s;
	}
}
