.app--view>.page-qidioups {
	.anticontainer();
	.layout.fill-vertical();
	background-image: url("~design/assets/backgrounds/qidioups/background.jpg");
	background-position: left center;

	&>* {
		.container;
	}
}
