.input--percent-of {
	justify-content: flex-start;
	align-items: center;
	display: flex;

	.input, .select {
		width: auto;
		margin: 0;
	}

	.input {
		flex-grow: 1;
	}

	.percent-of--mode {
		width: @size_input_height * 2;
		.input--input, .input-select--fake {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-left: 0;
		}
	}
	.percent-of--value {
		width: @size_input_height * 4;
		.input--input {
			text-align: right;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	// waaaaat
	&, & > * {
		vertical-align: middle;
	}

	& > * {
		flex-grow: 0;
		margin: 0;
	}

	.percent-of--equivalent {
		display: block;
		font-style: italic;
		height: @size_input_height;
		line-height: @size_input_height;
		margin-left: @gutter;
		// Nudge it up; visual alignment isn't matching with boxes.
		margin-top: -5/14rem;
	}
}
